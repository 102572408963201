import React, { useEffect, useRef, useState } from 'react';
import './Layout.scss';

import { useParams, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from 'ReduxTypes';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CookiesProvider, useCookies } from 'react-cookie';
import { NotificationMsg, Footer } from '../components';
import Header from '../components/Header/Header';
import * as AuthActions from '../store/auth/duck/actions';
import * as UserActions from '../store/user/duck/actions';
import * as CartActions from '../store/cart/duck/actions';
import { selectAuthError, selectAuthUser, selectToken } from '../store/auth/duck/selectors';
import { AuthUser } from '../models/AuthUser';
import { selectUserCart } from '../store/cart/duck/selectors';
import { UserCart } from '../models/CartItem';
import { clearCart, removeItem } from '../api/CartService';
import i18n from '../i18n/i18n';
import en from '../i18n/localization/en';
import zht from '../i18n/localization/zht';

interface WithHOCProps {
	authError?: string;
	authUser: AuthUser | null;
	token: string | null;
	userCart: UserCart | null;

	login: (username: string, password: string) => void;
	loginSuccess: (token: string, authUser: AuthUser) => void;
	fetchUser: () => void;
	fetchPaymentInfo: () => void;
	fetchCart: () => void;
}

// Extend from interface so so we can do typing without having to pass HOC props down
interface Props extends WithHOCProps {
	children: JSX.Element[] | JSX.Element;
}

const Layout = ({
	children, authError, login, authUser, loginSuccess,
	fetchUser, token, userCart, fetchCart,
}: Props) => {
	const params = useParams<any>();
	const { t } = useTranslation();

	// Language Handling
	const history = useHistory();
	const [cookies, setCookie] = useCookies();
	useEffect(() => {
		const langPath: any = {
			en: 'en',
			zht: 'zh_HK',
			zh_HK: 'zht',
		};
		const lang = params.locale;
		const currentLang = lang || 'en';
		const pathName = `${window.location.pathname}`;
		const cleanPathName = pathName.replace(`/${lang}`, '');
		if (lang) {
			const targetLang: 'en' | 'zht' = Object.keys(langPath)?.find((key: string) => langPath[key] === lang) as 'en' | 'zht' || 'en';
			i18n.switchLanguage(targetLang);
		} else {
			const targetLang = langPath[currentLang];
			i18n.switchLanguage(targetLang);
			history.replace(`/${targetLang}${cleanPathName}`);
		}
	}, [cookies, history]);

	useEffect(() => {
		if (token) {
			fetchUser();
			fetchCart();
		}
		if (window.resetPageTitle) {
			window.resetPageTitle();
		}
	}, [fetchCart, fetchUser, token]);

	const onClearCart = async () => {
		await clearCart();
		fetchCart();
	};
	const onClearCartOne = async (index: number) => {
		await removeItem(index);
		fetchCart();
	};

	// Handle fixed menu
	const [headerFixed, setHeaderFixed] = useState<boolean>(false);
	const notiRef = useRef<any>(null);
	useEffect(() => {
		const onScroll = () => {
			const scrollCheck = window.scrollY;
			// console.log('scroll', scrollCheck, notiRef.current.clientHeight);
			if (scrollCheck > notiRef.current.clientHeight) {
				setHeaderFixed(true);
			} else {
				setHeaderFixed(false);
			}
		};

		// setting the event handler from web API
		document.addEventListener('scroll', onScroll);

		// cleaning up from the web API
		return () => {
			document.removeEventListener('scroll', onScroll);
		};
	}, []);

	return (
		<>
			<div
				className={`site-layout-notification ${headerFixed ? 'mod-fixed' : ''}`}
				ref={notiRef}
			>
				<NotificationMsg />
			</div>
			<div
				className={`site-layout-header ${headerFixed ? 'mod-fixed' : ''}`}
			>
				<Header
					authError={authError}
					login={login}
					loginSuccess={loginSuccess}
					authUser={authUser}
					userCart={userCart}
					onClearCart={onClearCart}
					onClearCartOne={(index: number) => onClearCartOne(index)}
				/>
			</div>
			{children}
			<Footer />
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	authError: selectAuthError(state),
	authUser: selectAuthUser(state),

	token: selectToken(state),
	userCart: selectUserCart(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	login: (username: string, password: string) => dispatch(AuthActions.login(username, password)),
	loginSuccess: (
		token: string, authUser: AuthUser,
	) => dispatch(AuthActions.loginSuccess({ token, authUser })),
	fetchUser: () => dispatch(UserActions.fetchUser()),
	fetchPaymentInfo: () => dispatch(UserActions.fetchPaymentInfo()),
	fetchCart: () => dispatch(CartActions.fetchCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
