import React, { useState, ChangeEvent } from 'react';
import Modal from 'react-modal';
import './PopupModal.scss';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { FacebookIcon } from 'react-share';
import FacebookLogin from 'react-facebook-login';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import * as AuthActions from '../../store/auth/duck/actions';
import ForgotPasswordContents from './components/ForgotPasswordContents';
import AccessService from '../../api/AccessService';
import { selectToken } from '../../store/auth/duck/selectors';

interface PopupModalProps {
	token: string | null;
	fbLogin: (name: string, email: string, id: string, accessToken: string) => void;
}

interface Props extends PopupModalProps {
	onLogin: () => void;
	onSignUp: (data: any) => void;
	onSignInFormChanged: (e: ChangeEvent<HTMLInputElement>) => void;
	visible: boolean;
	onModalClose: () => void;
}

type ModalState = 'LOGIN' | 'SIGNUP' | 'FORGOTPASSWORD';

export const PopupModal = ({
	onLogin, onSignUp, onSignInFormChanged, onModalClose, visible,
	fbLogin,
}: Props) => {
	const { t } = useTranslation();
	const [modalState, setModalState] = useState<ModalState>('LOGIN');
	const [resetPWSent, setResetPWSent] = useState<boolean>(false);

	const onResetPassword = (email: string) => {
		AccessService.resetPassword(email)
			.then(() => {
				setResetPWSent(true);
			});
	};

	const showLogin = () => setModalState('LOGIN');
	const showSignUp = () => setModalState('SIGNUP');
	const showForgotPw = () => setModalState('FORGOTPASSWORD');

	const fbLoginResponse = (response: any) => {
		const {
			name, email, id, accessToken,
		} = response;
		fbLogin(name, email, id, accessToken);
	};

	const validatePhone = (tel: any) => {
		return validator.isMobilePhone(tel) && /^\d+$/.test(tel);
	};

	const {
		register, handleSubmit, errors, triggerValidation, watch,
	} = useForm();

	if (modalState === 'FORGOTPASSWORD') {
		return (
			<Modal
				isOpen={visible}
				onRequestClose={onModalClose}
				className="Modal"
				ariaHideApp={false}
			>
				<ForgotPasswordContents
					onLoginClicked={showLogin}
					onSignUpClicked={showSignUp}
					onResetPasswordClicked={onResetPassword}
					resetEmailSent={resetPWSent}
				/>
			</Modal>
		);
	}

	if (modalState === 'SIGNUP') {
		return (
			<Modal
				isOpen={visible}
				onRequestClose={onModalClose}
				className="Modal"
				ariaHideApp={false}
			>
				<div className="login-page">
					<div className="form">
						<form
							className="login-form"
							onSubmit={handleSubmit(onSignUp)}
						>
							<label htmlFor="email-input">
								<span className="login-form-label">{t('layout.emailAddressReq')}</span>
								<input
									id="email-input"
									name="email"
									type="email"
									placeholder={t('layout.emailAddressReq')}
									onChange={() => triggerValidation('email')}
									ref={register({
										validate: {
											isEmail: (value) => validator.isEmail(value) || t('form.invalidEmail').toString(),
										},
									})}
								/>
								{errors.email && <p className="error">{errors.email.message}</p>}
							</label>

							<label className="login-form-hidden-field" htmlFor="username">
								<span className="login-form-label">{t('layout.usernameReq')}</span>
								<input placeholder={t('layout.usernameReq')} id="username" name="username" type="text" ref={register({ required: true })} value="username" />
							</label>

							<label htmlFor="name">
								<span className="login-form-label">{t('layout.nameReq')}</span>
								<input placeholder={t('layout.nameReq')} id="name" name="name" type="text" ref={register({ required: true })} />
							</label>

							<label htmlFor="phone-input">
								<span className="login-form-label">{t('layout.phoneNumberReq')}</span>
								<input
									id="phone-input"
									type="tel"
									name="phone"
									placeholder={t('layout.phoneNumberReq')}
									onChange={() => triggerValidation('phone')}
									ref={register(
										{
											validate: {
												isPhoneNumber: (value) => validatePhone(value) || t('form.invalidPhoneNumber').toString(),
											},
										},
									)}
								/>
								{
									errors.phone && <p className="error">{errors.phone.message}</p>
								}
							</label>


							<label htmlFor="password-input">
								<span className="login-form-label">{t('layout.passwordReq')}</span>
								<input
									id="password-input"
									name="password"
									placeholder={t('layout.passwordReq')}
									onChange={() => triggerValidation(['password', 'password_repeat'])}
									type="password"
									ref={register({ required: t('form.passwordRequired').toString(), minLength: { value: 8, message: t('form.invalidPWLength') } })}
								/>
								{errors.password && <p className="error">{errors.password.message}</p>}
							</label>

							<label htmlFor="confirm-password-input">
								<span className="login-form-label">{t('layout.confirmPasswordReq')}</span>
								<input
									id="confirm-password-input"
									name="password_repeat"
									placeholder={t('layout.confirmPasswordReq')}
									onChange={() => triggerValidation('password_repeat')}
									type="password"
									ref={register({ validate: (value) => value === watch('password') || t('form.pwNotMatching').toString() })}
								/>
								{errors.password_repeat && <p className="error">{errors.password_repeat.message}</p>}
							</label>

							<div className="checkbox-item">
								<input
									id="newsletter-subscribe-checkbox"
									type="checkbox"
									name="subscribeNewsletter"
									ref={register}
									defaultChecked
								/>
								<label
									htmlFor="newsletter-subscribe-checkbox"
								>
									<span>{t('layout.newsletterSubscribeCheckbox')}</span>
								</label>
							</div>

							<button className="popup-button" type="submit">{t('layout.signUp')}</button>
							<p className="message">
								{t('layout.alreadyRegistered')}
							</p>
							<button
								className="clickable-text margin"
								type="button"
								onClick={showLogin}
							>
								{t('layout.signIn')}
							</button>
						</form>
					</div>
				</div>
			</Modal>
		);
	}

	return (
		<Modal
			isOpen={visible}
			onRequestClose={onModalClose}
			className="Modal"
			ariaHideApp={false}
		>
			<div className="login-page">
				<div className="form">

					<FacebookLogin
						disableMobileRedirect
						appId={1074067606294463}
						fields="name,email,picture"
						cssClass="fb-login-btn-wrap"
						textButton=""
						icon={(
							<div className="facebook-login-btn">
								<FacebookIcon
									path=""
									size={30}
								/>
								{t('loginWithFb')}
							</div>
						)}
						callback={fbLoginResponse}
					/>
					<div className="login-form-or">{t('layout.or')}</div>
					<form className="login-form">
						<label htmlFor="username-input">
							<span className="login-form-label">{t('layout.email')}</span>
							<input id="username-input" type="text" placeholder={t('layout.email')} onChange={onSignInFormChanged} />
						</label>
						<label htmlFor="password-input">
							<span className="login-form-label">{t('layout.password')}</span>
							<input autoComplete="on" id="password-input" type="password" placeholder={t('layout.password')} onChange={onSignInFormChanged} />
						</label>

						{/* eslint-disable-next-line max-len */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
						<div className="login-form-forgot-pw">
							<button
								type="button"
								onClick={showForgotPw}
								className="clickable-text left"
							>
								{t('forgotPw.forgotPassword')}
							</button>
						</div>

						<button className="popup-button" type="button" onClick={onLogin}>
							{t('layout.login')}
						</button>
						<p className="message">
							{t('layout.notRegistered')}
						</p>
						<button
							type="button"
							onClick={showSignUp}
							className="signup-btn"
						>
							<p className="clickable-text-center">
								{t('layout.createAnAccount')}
							</p>
						</button>
					</form>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state: RootState) => ({
	token: selectToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fbLogin: (
		name: string, email: string, id: string, accessToken: string,
	) => dispatch(AuthActions.fbLogin(name, email, id, accessToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupModal);
