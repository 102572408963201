import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './localization/en';
import zht from './localization/zht';

i18n
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: { ...en },
			},
			zht: {
				translation: { ...zht },
			},
		},
		lng: 'en',
		fallbackLng: 'en',

		interpolation: {
			escapeValue: false,
		},
	});

const formatString = (base: string, replacements: string[]) => {
	let newString = base;

	replacements.forEach((
		replacement, index,
	) => { newString = newString.replace(`{${index}}`, replacement); });

	return newString;
};

const switchLanguage = (lang: 'zht' | 'en') => {
	i18n.changeLanguage(lang)
		.then(() => {
			console.log('Language changed to ', lang);
		});
};

const getLang = () => i18n.language;

export default {
	formatString,
	switchLanguage,
	getLang,
};
