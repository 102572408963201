import { ActionType } from 'typesafe-actions';
import * as BrandsActions from './action';
import { Brands } from '../../../models';

export interface State {
	data: Brands | null;
	error?: string;
}

const initialState: State = {
	data: null,
};

type BrandsActions = ActionType<typeof BrandsActions>;

export default function reducer(state: State = initialState, action: BrandsActions): State {
	switch (action.type) {
		case BrandsActions.FETCH_BRANDS
			:
			return {
				...state,
				data: null,
			};
		case BrandsActions.FETCH_BRANDS_SUCCESS:
		case BrandsActions.FETCH_BRANDS_FAIL:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
