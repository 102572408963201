import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
	onSubmit: (data: any) => void;
	mapDefaultValue: (type: 'address'| 'district' | 'building') => string;
	onCancel: () => void;
}

const AddressForm = ({ onSubmit, mapDefaultValue, onCancel }: Props) => {
	const { t } = useTranslation();

	const {
		register, handleSubmit, errors, triggerValidation,
	} = useForm();

	const districts = [
		t('myAccount.districts.newTerritories'),
		t('myAccount.districts.kowloon'),
		t('myAccount.districts.hkIsland'),
	];

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="content-container">
				<p>
					{`${t('myAccount.building')}`}
					<input
						type="text"
						defaultValue={mapDefaultValue('building') || ''}
						name="building"
						ref={register}
					/>
				</p>
				<p>
					{`${t('myAccount.address')}`}
					<input
						type="text"
						name="address"
						defaultValue={mapDefaultValue('address') || ''}
						onChange={() => triggerValidation('address')}
						ref={register({
							required: t('myAccount.form.addressRequired').toString(),
						})}
					/>
				</p>
				{errors.address && <p className="error-text">{errors.address.message}</p>}
				<p>
					{`${t('myAccount.district')}`}
					<select
						name="district"
						ref={register}
						defaultValue={mapDefaultValue('district') || ''}
					>
						{
							districts.map((value) => (
								<option key={value} value={value}>{value}</option>
							))
						}
					</select>
				</p>

			</div>
			<div className="details-buttons-container">
				<button
					type="button"
					onClick={onCancel}
				>
					{t('myAccount.cancel')}
				</button>
				<button
					type="submit"
				>
					{t('myAccount.save')}
				</button>
			</div>
		</form>
	);
};

export default AddressForm;
