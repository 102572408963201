import React, {
	useState, useEffect, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import './MyAccount.scss';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import moment from 'moment';
import {
	useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement,
} from '@stripe/react-stripe-js';
import { FacebookShareButton } from 'react-share';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AxiosResponse } from 'axios';
import Modal from 'react-modal';
import * as UserActions from '../../store/user/duck/actions';
import * as OrderActions from '../../store/order/duck/actions';
import Layout from '../../layouts/Layout';
import { selectNoshUser, selectLastPaymentInfo } from '../../store/user/duck/selectors';
import { NoshUser } from '../../models/NoshUser';
import { selectOrders } from '../../store/order/duck/selectors';
import { Order } from '../../models/Order';
import { selectToken } from '../../store/auth/duck/selectors';
import { PaymentInfo } from '../../models/PaymentInfo';
import AddressForm from './components/AddressForm';
import ChangePasswordForm from './components/ChangePasswordForm';
import UserService from '../../api/UserService';
import { fetchAddOns } from '../../api';

type Props = {
	noshUser: NoshUser | null;
	paymentInfo: PaymentInfo | null;
	orders: Order[] | null;
	token: string | null;
	fetchPaymentInfo: () => void;
	fetchOrders: () => void;
	fetchUserSuccess: (user: NoshUser) => void;
};

type FormEditState = 'show' | 'edit' | 'add';

const MOMENT_TIME_FORMAT = 'MMM DD YYYY';

const MyAccount = ({
	noshUser, paymentInfo, orders, token, fetchOrders,
	fetchPaymentInfo, fetchUserSuccess,
}: Props) => {
	const { t } = useTranslation();
	const stripe = useStripe();
	const elements = useElements();

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// useEffect(() => {
	// 	console.log('orders', orders);
	// }, [orders]);

	useEffect(() => {
		if (token) {
			fetchPaymentInfo();
			fetchOrders();
		}
	}, [fetchOrders, fetchPaymentInfo, token]);

	useEffect(() => {
		if (paymentInfo) {
			setCcFormState('show');
			return;
		}
		setCcFormState('add');
	}, [paymentInfo]);

	const [addressFormState, setAddressFormState] = useState<FormEditState>('show');
	const [ccFormState, setCcFormState] = useState<FormEditState>(!paymentInfo ? 'add' : 'show');
	const [showSecondAddress, setShowSecondAddress] = useState<boolean>(false);
	const [changePassword, setChangePassword] = useState<boolean>(false);
	const [itemDetail, setItemDetail] = useState<any>(null);
	const [addOnDetail, setAddOnDetail] = useState<any>(null);
	const [addOnList, setAddOnList] = useState<any>(null);

	const [cookies, setCookie, removeCookie] = useCookies();

	useEffect(() => {
		console.log('itemDetail', itemDetail);
	}, [itemDetail]);

	useEffect(() => {
		if (!addOnList) {
			fetchAddOns(true)
				.then(((res: any) => {
					const dataAddOns = res.data.addons;
					setAddOnList(dataAddOns);
				}));
		}
	}, [fetchAddOns, addOnList]);

	const onChangePassword = () => setChangePassword(true);
	const onCancelChangePassword = () => setChangePassword(false);
	const onChangePasswordSubmit = (data: any) => {
		const { newPassword } = data;

		UserService.changePassword(newPassword)
			.then((response: AxiosResponse) => {
				if (response.status === 200) {
					window.alert(t('myAccount.passwordChanged'));
					setChangePassword(false);
				}
			});
	};

	const onSaveCC = useCallback(async () => {
		if (!stripe || !elements) return;

		const cardNumber = elements.getElement('cardNumber');
		const cardExpiry = elements.getElement('cardExpiry');
		const cardCvc = elements.getElement('cardCvc');

		if (!cardNumber) {
			window.alert(t('errInitStripe'));
			return;
		}
		await UserService.removeAllPaymentMethod();
		stripe.createToken(cardNumber).then((response) => {
			const { error, token: stripeToken } = response;
			if (error) {
				window.alert(error.message);
			}
			if (stripeToken) {
				if (cardNumber) cardNumber.clear();
				if (cardExpiry) cardExpiry.clear();
				if (cardCvc) cardCvc.clear();
				UserService.addPaymentMethod(stripeToken.id)
					.then(() => {
						fetchPaymentInfo();
					});
			}
		});
	}, [elements, fetchPaymentInfo, stripe, t]);

	if (!stripe || !elements) {
		return <div />;
	}

	const stripeElementStyle = {
		base: {
			textAlign: 'center',
			fontSize: '14px',
			lineHeight: '24px',
			fontFamily: 'Josefin Sans',
			width: '100%',
			height: '2rem',
		},
	};

	const onShowSecondAddress = () => setShowSecondAddress(!showSecondAddress);

	const syncBuildingFormat = (dataBuilding: any) => {
		let adjustedBuilding = dataBuilding;
		// console.log('adjustedBuilding', adjustedBuilding);
		if (typeof adjustedBuilding === 'object') {
			adjustedBuilding = adjustedBuilding?.building;
		}
		return adjustedBuilding;
	};

	const onAddressSubmit = (data: any) => {
		const {
			building, address, district,
		} = data;

		if (!noshUser) {
			window.alert(t('errUpdatingAddress'));
			return;
		}

		if (addressFormState === 'add' || showSecondAddress) {
			UserService.updateUser({
				building: syncBuildingFormat(noshUser?.building),
				address: noshUser.address,
				assignedZone: {},
				district: noshUser.district,
				region: noshUser.region || '',
				memberName: noshUser.memberName,
				gender: noshUser.gender || '',
				secondAddressBuilding: syncBuildingFormat(building),
				secondAddressFullAddress: address,
				secondAddressDistrict: district,
			})
				.then((response) => {
					const { data: { user } } = response;
					fetchUserSuccess(user);
				});
		} else if (addressFormState === 'edit') {
			if (noshUser.secondAddressBuilding && noshUser.secondAddressFullAddress) {
				UserService.updateUser({
					building: syncBuildingFormat(building),
					address,
					assignedZone: {},
					district,
					region: noshUser.region || '',
					memberName: noshUser.memberName,
					gender: noshUser.gender || '',
					secondAddressBuilding: syncBuildingFormat(noshUser.secondAddressBuilding) || null,
					secondAddressFullAddress: noshUser.secondAddressFullAddress || null,
					secondAddressDistrict: noshUser.secondAddressDistrict || null,
				})
					.then((response) => {
						const { data: { user } } = response;
						fetchUserSuccess(user);
					});
			} else {
				UserService.updateUser({
					building: syncBuildingFormat(building),
					address,
					assignedZone: {},
					district,
					region: noshUser.region || '',
					memberName: noshUser.memberName,
					gender: noshUser.gender || '',
				})
					.then((response) => {
						const { data: { user } } = response;
						fetchUserSuccess(user);
					});
			}
		}

		setAddressFormState('show');
	};

	const onAddressEdit = () => {
		setAddressFormState('edit');
	};

	const onAddressAdd = () => {
		setAddressFormState('add');
	};

	const onCancel = () => {
		setAddressFormState('show');
	};

	const onDeleteCC = async () => {
		await UserService.removeAllPaymentMethod();
		fetchPaymentInfo();
	};

	const onChangeCC = () => {
		setCcFormState('add');
	};

	const mapDefaultValue = (type: 'address' | 'district' | 'building'): string => {
		if (addressFormState === 'add' || !noshUser) return '';

		try {
			if (type === 'address') {
				if (!noshUser.secondAddressFullAddress) return '';
				return showSecondAddress ? noshUser.secondAddressFullAddress : noshUser.address;
			}
			if (type === 'district') {
				return showSecondAddress ? noshUser.district : noshUser.district;
			}
			if (type === 'building') {
				if (!noshUser.secondAddressBuilding) return '';
				let defaultBuilding = noshUser?.building;
				if (typeof defaultBuilding === 'object') {
					defaultBuilding = defaultBuilding?.building;
				}
				let defaultSecondAddressBuilding = noshUser?.secondAddressBuilding;
				if (typeof defaultSecondAddressBuilding === 'object') {
					defaultSecondAddressBuilding = defaultSecondAddressBuilding?.building;
				}
				return showSecondAddress
					? defaultSecondAddressBuilding
					: defaultBuilding;
			}
		} catch {
			return '';
		}
		return '';
	};
	console.log('noshUser', noshUser);

	// eslint-disable-next-line consistent-return
	return (
		<Layout>
			<div className={`account-container ${noshUser?.isVIP ? 'account-vip' : ''}`}>
				<div className="signed-in-as-container">
					{/* <img
						alt="profile"
						className="profile-icon"
						src="./img/User.png"
					/> */}
					<div className="profile-icon" />
					<div className="profile-id-container">
						<p className="bold-text">{t('myAccount.signedInAs')}</p>
						<p>{noshUser?.memberName}</p>
						<p>{noshUser?.email}</p>
						<div className="account-vip-tag">
							{t('vip.memberOfNoshFamilyAndFriends')}
						</div>
					</div>
				</div>

				<div className="sharing-is-caring-container">
					<img alt="promo" src="/img/promo-img.png" />
					<div className="sharing-is-caring-right-container">

						<div className="sharing-is-caring-text">
							{t('myAccount.sharingIsCaring')}
						</div>

						<div className="sharing-media-container">
							<p>Give 250, Get 250</p>
							<FacebookShareButton
								url={`${window.location.host}/mealplans?ref=${noshUser?.referralCode}`}
							>
								<img alt="fb" src="/img/facebook.png" />
							</FacebookShareButton>
							<CopyToClipboard
								text={`${window.location.host}/mealplans?ref=${noshUser?.referralCode}`}
								onCopy={() => window.alert(`${window.location.host}/mealplans?ref=${noshUser?.referralCode} ${t('myAccount.copiedToClipboard')}`)}
							>
								<img className="link-copy-button" alt="link" src="/img/link.png" />
							</CopyToClipboard>
						</div>

						<div className="referral-link-container">
							<p>{t('myAccount.personalReferralCode')}</p>
							<p className="highlight">{noshUser?.referralCode}</p>
						</div>

						{/* <div className="referral-link">{`/mealplans?ref=${noshUser?.referralCode}`}</div> */}
					</div>
				</div>

				<div className="details-address-book-container">
					<div className="details-container">
						<div className="header-container">
							<div className="header-bottom-border" />
							<div className="header-text">{t('myAccount.details')}</div>
						</div>

						{
							changePassword
								? (
									<ChangePasswordForm
										onSubmit={onChangePasswordSubmit}
										onCancel={onCancelChangePassword}
										user={noshUser}
									/>
								)
								: (
									<>
										<div className="content-container">
											<div className="account-user-info">
												<div className="account-user-info-body">
													<p>{`${t('myAccount.name')} ${noshUser?.memberName}`}</p>
													<p>{`${t('myAccount.email')} ${noshUser?.email}`}</p>
												</div>
												<div className="account-vip-logo" />
											</div>
										</div>

										<div className="details-buttons-container">
											{
												!changePassword ? (
													<>
														<button
															type="button"
															onClick={onChangePassword}
														>
															{
																t('myAccount.changePassword')
															}
														</button>
													</>

												)
													: null
											}
										</div>
									</>
								)
						}
					</div>

					<div className="details-container">
						<div className="header-container">
							<div className="header-bottom-border" />
							<div className="header-text">{t('myAccount.addressBook')}</div>
						</div>
						{
							addressFormState === 'edit' || addressFormState === 'add'
								? (
									<AddressForm
										onSubmit={onAddressSubmit}
										onCancel={onCancel}
										mapDefaultValue={mapDefaultValue}
									/>
								)
								: (
									<>
										<div className="content-container">
											{
												showSecondAddress
													? (
														<>
															<p>{`${t('myAccount.building')} ${noshUser?.secondAddressBuilding ? (typeof noshUser?.secondAddressBuilding === 'object') ? (noshUser?.secondAddressBuilding).building : (noshUser?.secondAddressBuilding) : ''}`}</p>
															<p>{`${t('myAccount.address')} ${noshUser?.secondAddressFullAddress}`}</p>
															<p>{`${t('myAccount.district')} ${noshUser?.secondAddressDistrict}`}</p>
														</>
													)
													: (
														<>
															<p>{`${t('myAccount.building')} ${noshUser?.building ? (typeof noshUser?.building === 'object') ? noshUser?.building.building : noshUser?.building : ''}`}</p>
															<p>{`${t('myAccount.address')} ${noshUser?.address}`}</p>
															<p>{`${t('myAccount.district')} ${noshUser?.district}`}</p>
														</>
													)
											}
										</div>
										<div className="details-buttons-container">
											{
												noshUser?.secondAddressBuilding && noshUser?.secondAddressFullAddress
													? (
														<button type="button" onClick={onShowSecondAddress}>
															{
																showSecondAddress ? t('myAccount.showAddress1')
																	: t('myAccount.showAddress2')
															}
														</button>
													)
													: (
														<button type="button" onClick={onAddressAdd}>{t('myAccount.createSecondAddress')}</button>
													)
											}
											<button type="button" onClick={onAddressEdit}>
												{
													t('myAccount.edit')
												}
											</button>
										</div>
									</>
								)
						}
					</div>
				</div>

				<div className="full-width-details-container">
					<div className="header-container">
						<div className="header-bottom-border" />
						<div className="header-text">{t('myAccount.payment')}</div>
					</div>
					<div className="content-container">
						<img alt="payment_methods" src="https://www.merchantequip.com/image/?logos=v|m|a&height=32" />
						<div
							className="cc-form"
						>
							<div
								className={`cc-form ${ccFormState !== 'add' ? 'hidden-element' : ''}`}
							>
								<p>{`${t('myAccount.creditCardNo')}`}</p>
								{/* <div */}
								{/*	className="stripe-element-container" */}
								{/*	id="card-number-element" */}
								{/* /> */}
								<div
									className="stripe-element-container"
								>
									<CardNumberElement
										options={{
											style: stripeElementStyle,
										}}
									/>
								</div>

								<p>{`${t('myAccount.expiryDate')}`}</p>
								<div
									className="stripe-element-container"
								>
									<CardExpiryElement
										options={{
											style: stripeElementStyle,
										}}
									/>
								</div>
								<p>{`${t('myAccount.cvc')}`}</p>
								<div
									className="stripe-element-container"
								>
									<CardCvcElement
										options={{
											style: stripeElementStyle,
										}}
									/>
								</div>
							</div>
							<div
								className={`cc-form ${ccFormState === 'add' ? 'hidden-element' : ''}`}
							>
								<p>{`${t('myAccount.creditCardNo')}`}</p>
								<p>{paymentInfo && 'xxxx-xxxx-xxxx-{0}'.replace('{0}', paymentInfo.source.last4)}</p>
							</div>
						</div>
					</div>

					{
						ccFormState === 'add'
							? (
								<div className="full-width-details-buttons-container">
									<button type="button" onClick={() => setCcFormState('show')}>{`${t('Cancel')}`}</button>
									<button type="button" onClick={onSaveCC}>{`${t('myAccount.save')}`}</button>
								</div>
							)
							: (
								<div className="full-width-details-buttons-container">
									<button type="button" onClick={onDeleteCC}>{`${t('myAccount.deleteCard')}`}</button>
									<button type="button" onClick={onChangeCC}>{`${t('myAccount.changeCard')}`}</button>
								</div>
							)

					}
				</div>

				<div className="full-width-details-container">
					<div className="header-container">
						<div className="header-bottom-border" />
						<div className="header-text">{t('myAccount.recentOrders')}</div>
					</div>

					<div className="acc-orders-holder">
						<table className="acc-orders">
							<tbody>
								<tr>
									<th className="acc-orders-id">{t('myAccount.orderNo')}</th>
									<th className="acc-orders-date">{t('myAccount.orderDate')}</th>
									{/* <th>{t('myAccount.orderEndDate')}</th> */}
									<th className="acc-orders-products">{t('myAccount.products2')}</th>
									<th>{t('myAccount.orderAmount2')}</th>
									{/* <th>{t('myAccount.status')}</th> */}
								</tr>
								{
									orders && orders.map((_order) => {
										if (!!_order.isDeleted !== true) {
											return (
												<>
													<tr key={_order.order_serial}>
														<td className="acc-orders-id">{_order.order_serial}</td>
														<td className="acc-orders-date">{moment(_order.createdAt).format(MOMENT_TIME_FORMAT)}</td>
														{/* <td>
											{
												moment(
												_order?.items[0]?.startDate,
												).add(
													_order?.items[0].noOfDays,
													'day',
												).format(MOMENT_TIME_FORMAT)
											}
										</td> */}
														<td className="acc-orders-products">
															{_order.items.map((item, index: number) => (
																<>
																	{`${(index > 0) ? '\n' : ''}`}
																	<button
																		className="acc-orders-products-item"
																		type="button"
																		onClick={() => setItemDetail({
																			...item,
																			orderId: _order.order_serial,
																		})}
																	>
																		{`${item?.goalId?.cuisineId?.name} ${item?.goalId?.name}`}
																	</button>
																</>
															))}
															<br />
															{_order.addOns && (
																<>
																	<button
																		className="acc-orders-products-item"
																		type="button"
																		onClick={() => setAddOnDetail({
																			addOns: _order.addOns,
																			orderId: _order.order_serial,
																		})}
																	>
																		Add-Ons
																	</button>
																</>
															)}
														</td>
														{/* <td>{_order.items.length}</td>
										<td>{_order.status}</td> */}

														<td className="acc-orders-amount">
															{`HKD ${_order.total_amt}`}
														</td>
														{/* <td>{_order.status}</td> */}
													</tr>
												</>
											);
										}
										return null;
									})

								}
							</tbody>
						</table>
					</div>
				</div>
				<div className="full-width-details-container">
					<div className="header-container">
						<div className="header-bottom-border" />
						<div className="header-text">{t('myAccount.noshCredits')}</div>
					</div>

					<div className="content-container balance-container">
						<p>{t('myAccount.balance')}</p>
						<div className="available-store-credit">
							{t('myAccount.youHave')}
							<p>
								{noshUser?.credits ? ` ${noshUser?.credits} HKD `
									: ' 0 HKD '}
							</p>
							{t('myAccount.availableStoreCredit')}
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={!!itemDetail}
				onRequestClose={() => setItemDetail(null)}
				className="Modal"
				ariaHideApp={false}
			>
				<div className="item-detail-popup login-page order-detail-popup">
					{itemDetail && (
						<div className="form">
							<h2>
								{t('langKey2') === 'en' ? (
									<div>
										Order #
										{itemDetail.orderId}
										<br />
										{' '}
										{itemDetail?.goalId?.cuisineId?.name}
										{' '}
										{itemDetail?.goalId?.name}
									</div>
								)
									: (
										<div>
											訂單 #
											{itemDetail.orderId}
											{' '}
											<br />
											{itemDetail?.goalId?.cuisineId?.name}
											{' '}
											{itemDetail?.goalId?.name}
											{' '}
										</div>
									)}
							</h2>
							<table>
								<tr>
									<td>{t('checkout.startDate')}</td>
									<td>{moment(itemDetail.startDate).format(MOMENT_TIME_FORMAT)}</td>
								</tr>
								<tr>
									<td>{t('checkout.endDate')}</td>
									<td>{moment(itemDetail.endDate).format(MOMENT_TIME_FORMAT)}</td>
								</tr>
								<tr>
									<td>{t('myAccount.numberOfDay')}</td>
									<td>{`${itemDetail.noOfDays} days`}</td>
								</tr>
								<tr>
									<td>{t('checkout.deliveryDates')}</td>
									<td><div className="item-detail-delivery-date">{Array.from(new Set(itemDetail.mealPlans.map((meal: any) => moment(meal.date).format(MOMENT_TIME_FORMAT))).values()).sort().join('\n')}</div></td>
								</tr>
								<tr>
									<td>{t('mealPlan.specialRequest')}</td>
									<td><div className="item-detail-align-left">{`${itemDetail?.extras?.filter((item: any) => (item.name !== '###breakfast_discount###')).map((item: any) => (item.name))?.join(', ') || '--'}`}</div></td>
								</tr>
							</table>
						</div>

					)}
					<button type="button" onClick={() => setItemDetail(null)}><img className="close-button" src="/img/assets/close.svg" alt="close" /></button>
				</div>
			</Modal>
			<Modal
				isOpen={!!addOnDetail}
				onRequestClose={() => setAddOnDetail(null)}
				className="Modal"
				ariaHideApp={false}
			>
				<div className="item-detail-popup login-page order-detail-popup">
					{addOnDetail && (
						<div className="form">
							<h2>
								{t('langKey2') === 'en' ? (
									<div>
										Order #
										{addOnDetail.orderId}
										<br />
										{t('mealPlan.addAddOn')}
									</div>
								)
									: (
										<div>
											訂單 #
											{addOnDetail.orderId}
											{' '}
											<br />
											{t('mealPlan.addAddOn')}
										</div>
									)}
							</h2>
							<table>
								<tr>
									<th>{t('checkout.date')}</th>
									<th>{t('mealPlan.addAddOn')}</th>
								</tr>
								{Object.keys(addOnDetail.addOns).map((date) => {
									const quantity = addOnDetail.addOns[date].reduce((acc: { [key: string]: number; }, cur: string) => {
										const count: { [key: string]: number; } = {};
										acc[cur] = (acc[cur] || 0) + 1;
										return acc;
									}, {});
									return (
										<tr>
											<td>{date}</td>
											<td>
												{Object.keys(quantity).map((item) => {
													const addOnName = addOnList?.find((listItem: any) => (listItem._id === item))?.name;
													return (
														<>
															{addOnName
																? `${addOnName}
															x 
															${quantity[item]}
															` : t('error.errAddOn')}
															<br />
														</>
													);
												})}
											</td>
										</tr>
									);
								})}
							</table>
						</div>
					)}
				</div>
				<button type="button" onClick={() => setAddOnDetail(null)}><img className="close-button" src="/img/assets/close.svg" alt="close" /></button>
			</Modal>
		</Layout>
	);
};

const mapStateToProps = (state: RootState) => ({
	noshUser: selectNoshUser(state),
	paymentInfo: selectLastPaymentInfo(state),
	orders: selectOrders(state),
	token: selectToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchOrders: () => dispatch(OrderActions.fetchOrders()),
	fetchPaymentInfo: () => dispatch(UserActions.fetchPaymentInfo()),
	fetchUserSuccess: (user: NoshUser) => dispatch(UserActions.fetchUserSuccess(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
