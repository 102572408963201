import React from 'react';
import { useCookies } from 'react-cookie';
import { Route, Redirect } from 'react-router-dom';
import { NOSH_AUTH_DATA_KEY } from '../config/Keys';

const ProtectedRoute = ({
	component,
	redirectPath,
	...rest
}: any) => {
	const [cookies] = useCookies();

	const routeComponent = (props: any) => {
		const { location } = props;

		if (cookies[NOSH_AUTH_DATA_KEY]) {
			return (React.createElement(component, props));
		}
		return <Redirect to={{ pathname: redirectPath || '/', state: { from: location } }} />;
	};

	return <Route {...rest} render={routeComponent} />;
};

export default ProtectedRoute;
