import { createSelector } from 'reselect';
import { RootState } from 'ReduxTypes';

export const selectAuth = (state: RootState) => state.auth;

export const selectAuthError = createSelector(
	selectAuth,
	(auth) => auth.error,
);

export const selectAuthUser = createSelector(
	selectAuth,
	(auth) => auth.authUser,
);

export const selectToken = createSelector(
	selectAuth,
	(auth) => auth.token,
);
