import { action } from 'typesafe-actions';
import { UserCart } from '../../../models/CartItem';

export const FETCH_CART = 'cart/FETCH_CART';
export const FETCH_CART_SUCCESS = 'cart/FETCH_CART_SUCCESS';
export const FETCH_CART_FAIL = 'cart/FETCH_CART_FAIL';

export const fetchCart = () => action(FETCH_CART);
export const fetchCartSuccess = (
	userCart: UserCart,
) => action(FETCH_CART_SUCCESS, { userCart });
export const fetchCartFail = (
	error: string,
) => action(FETCH_CART_FAIL, { error });
