import { action } from 'typesafe-actions';
import { Order } from '../../../models/Order';

export const FETCH_ORDERS = 'orders/FETCH_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'orders/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'orders/FETCH_ORDERS_FAIL';

export const fetchOrders = () => action(FETCH_ORDERS);
export const fetchOrdersSuccess = (
	orders: Order[],
) => action(FETCH_ORDERS_SUCCESS, { orders });
export const fetchOrdersFail = (error: string) => action(FETCH_ORDERS_FAIL, { error });
