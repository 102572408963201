import axios from './axios';

export const login = (email: string, password: string, promo?: string) => axios.post('v1/access/login', {
	email,
	password,
	promo,
});

export const signup = (userName: string, name: string, gender: string, email: string, password: string, phone: number) => axios.post('v1/access/signup', {
	username: userName,
	name,
	gender,
	email,
	password,
	phone: Number(phone),
});

export const resetPassword = (email: string) => axios.post('v1/access/_reset_password', {
	email,
});

export const fbLogin = (email: string, memberName: string, id: string, accessToken: string) => axios.post('v1/access/facebook_login', {
	email,
	facebookId: id,
	accessToken,
	memberName,
});

export const subscribePromo = (email: {email: string}) => axios.post('v1/discount_code/onSignUp', email);

export default {
	login,
	signup,
	resetPassword,
	subscribePromo,
};
