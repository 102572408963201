import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ForgotPasswordContents.scss';

type Props = {
	resetEmailSent: boolean;

	onLoginClicked: () => void;
	onSignUpClicked: () => void;
	onResetPasswordClicked: (email: string) => void;
}

const ForgotPasswordContents = ({
	resetEmailSent, onLoginClicked, onSignUpClicked, onResetPasswordClicked,
}: Props) => {
	const { t } = useTranslation();
	const [email, setEmail] = useState('');

	return (
		<div
			className="forgot-password-container"
		>
			<h1>{t('forgotPw.title')}</h1>
			<p>{t('forgotPw.desc')}</p>
			<label htmlFor="forgotpw-email">
				{t('email')}
			</label>
			<input
				id="forgotpw-email"
				type="email"
				onChange={(e) => setEmail(e.target.value)}
			/>

			{
				resetEmailSent
					? <p className="resetpw-further-instructions">{t('forgotPw.furtherInstructions')}</p>
					: null
			}

			<button
				type="button"
				className="reset-pw-button"
				disabled={resetEmailSent}
				onClick={() => onResetPasswordClicked(email)}
			>
				{t('forgotPw.button')}
			</button>

			<div
				className="popup-options-container"
			>
				<button
					type="button"
					onClick={onLoginClicked}
				>
					{t('log_in')}
				</button>
				<p>|</p>
				<button
					type="button"
					onClick={onSignUpClicked}
				>
					{t('sign_up')}
				</button>
			</div>

		</div>
	);
};

export default ForgotPasswordContents;
