import React, { useEffect, useState, useRef } from 'react';
import './Submenu.scss';

interface Props{
	title: string;
	children: JSX.Element[] | JSX.Element;
}

const Layout = ({
	title,
	children,
}: Props) => {
	const wrapperRef = useRef<any>(null);
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	// useEffect(() => {
	// 	if (token) {
	// 		fetchUser();
	// 		fetchCart();
	// 	}
	// }, [fetchCart, fetchUser, token]);

	// dismiss cart popup when clicking outside of popup area
	useEffect(() => {
		function handleClickOutside(event: any) {
			const ref = wrapperRef.current;
			if (ref && !ref.contains(event.target)) {
				setTimeout(() => {
					if (showDropdown) setShowDropdown(false);
				}, 150);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchstart', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchstart', handleClickOutside);
		};
	}, [wrapperRef, showDropdown]);

	const toggleDropDown = () => {
		setShowDropdown(!showDropdown);
	};

	return (
		<div
			className={`submenu ${showDropdown ? 'mod-open' : ''}`}
			ref={wrapperRef}
		>
			<button className="submenu-toggle" type="button" onClick={() => { toggleDropDown(); }}>{title}</button>
			<div className="submenu-items">
				{children}
			</div>
		</div>
	);
};

export default Layout;
