import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'swiper/css/swiper.css';
import './HomeAmbassadors.scss';

import Swiper from 'swiper';

const dataAmbassadors = [
	{
		id: 'sandra',
		img: '/img/assets/home-ambassadors/sandra.png',
		desc: {
			eng: '"All the portions are perfect for what I want to achieve in life: still have fun, eat good food, but without any danger of overeating."',
			zh: '"All the portions are perfect for what I want to achieve in life: still have fun, eat good food, but without any danger of overeating."',
		},
		title: {
			eng: 'SANDRA',
			zh: 'SANDRA',
		},
	},
	{
		id: 'trixie',
		img: '/img/assets/home-ambassadors/trixie.png',
		desc: {
			eng: '"A big part of why I love NOSH is because of the convenience! Their team completely takes care of planning my meals, calculating macros, and delivering it to me."',
			zh: '"A big part of why I love NOSH is because of the convenience! Their team completely takes care of planning my meals, calculating macros, and delivering it to me."',
		},
		title: {
			eng: 'TRIXIE',
			zh: 'TRIXIE',
		},
	},
	{
		id: 'ocean',
		img: '/img/assets/home-ambassadors/ocean.png',
		desc: {
			eng: '"Being a foodie, it\'s never easy to balance nutrition and flavor, but NOSH achieves that perfectly. On top of that, I\'m energized for a full day of work!"',
			zh: '"Being a foodie, it\'s never easy to balance nutrition and flavor, but NOSH achieves that perfectly. On top of that, I\'m energized for a full day of work!"',
		},
		title: {
			eng: 'OCEAN',
			zh: 'OCEAN',
		},
	},
	// {
	// 	id: 'mayara',
	// 	img: '/img/assets/home-ambassadors/mayara.png',
	// 	desc: {
	// 		eng: '"The food was hot, flavour was delicious, and packaging was not excessively wasteful."',
	// 		zh: '"The food was hot, flavour was delicious, and packaging was not excessively wasteful."',
	// 	},
	// 	title: {
	// 		eng: 'MAYARA',
	// 		zh: 'MAYARA',
	// 	},
	// },

];

const HomeAmbassadors = () => {
	const { t } = useTranslation();
	const langKey = t('langKey');
	useEffect(() => {
		const swiper = new Swiper('.home-ambassadors-slider .swiper-container', {
			// pagination: {
			// 	el: '.goal-container .swiper-pagination',
			// 	type: 'bullets',
			// 	clickable: true,
			// },
			// navigation: {
			// 	nextEl: '.goal-container .swiper-button-next',
			// 	prevEl: '.goal-container .swiper-button-prev',
			// },
			slidesPerView: 2,
			spaceBetween: 20,
			watchOverflow: true,
			centerInsufficientSlides: true,
			breakpoints: {
				640: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
			},
		});
	}, []);
	return (
		<section className="home-ambassadors">
			<div className="home-ambassadors-inner">
				<h2 className="home-ambassadors-title">{t('layout.ambassadors')}</h2>
				<div className="home-ambassadors-slider">
					<div className="swiper-container">
						<div className="swiper-wrapper">
							{dataAmbassadors.map((item: any, index: number) => (
								<div key={`${item.title[langKey]}-${index + 1}`} className="home-ambassadors-slider-item swiper-slide">
									<div className="home-ambassadors-slider-img" style={{ backgroundImage: `url(${item.img})` }} />
									<div className="home-ambassadors-slider-item-content">
										<h2 className="home-ambassadors-slider-item-title">{item.title[langKey]}</h2>
										<p className="home-ambassadors-slider-item-desc">{item.desc[langKey]}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeAmbassadors;
