import { combineReducers } from 'redux';
import { BrandsReducer } from '../pages/MealPlans';
import AuthReducer from './auth/duck/reducer';
import { UserReducer } from './user';
import { OrderReducer } from './order';
import { SettingReducer } from './settings';
import { CartReducer } from './cart';

export default combineReducers({
	brands: BrandsReducer,
	auth: AuthReducer,
	user: UserReducer,
	order: OrderReducer,
	settings: SettingReducer,
	cart: CartReducer,
});
