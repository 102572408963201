import React, { useEffect } from "react";
import "./weightMgmt.scss";
import { Link } from "react-router-dom";
import { RootState } from "ReduxTypes";
import { connect } from "react-redux";
import { selectToken } from "../../../store/auth/duck/selectors";
import { selectNoshUser } from "../../../store/user/duck/selectors";
import Layout from "../../../layouts/Layout";

const WeightMgmt = () => {
  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
        <iframe src="https://wm.nosh.hk/index.html" className="displayArea"></iframe>
    </Layout>
  )
};

const mapStateToProps = (state: RootState) => ({
  token: selectToken(state),
  noshUser: selectNoshUser(state),
});

export default connect(mapStateToProps)(WeightMgmt);
