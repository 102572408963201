import { all } from 'redux-saga/effects';
import { watchFetchOrders } from './duck/saga';
import OrderReducer from './duck/reducer';

function* OrderSaga() {
	yield all([
		watchFetchOrders(),
	]);
}

export {
	OrderSaga,
	OrderReducer,
};
