import axios from 'axios';
import AppConfig from '../config/AppConfig';

const instance = axios.create({
	// baseURL: 'https://api-uat-v1.nosh.hk/',
	baseURL: AppConfig.API_URL,
	timeout: 30000,
	headers: { 'Content-Type': 'application/json' },
});

export const setToken = (token: string) => {
	instance.defaults.headers.common['x-access-token'] = token;
	// console.log('token set');
};

export const getToken = () => instance.defaults.headers.common['x-access-token'];

export const setLang = (lang: string) => {
	instance.defaults.headers.common['x-language'] = lang;
};

export default instance;
