const en = {
  save: "Save",
  tnc: {
    title: "TERMS AND CONDITIONS FOR ONLINE SALE OF GOODS",
  },
  faq: {
    title: "Meal plan FAQ",
    footer1: "Can't find what you are looking for?",
    footer2: "Give us a call",
  },
  privacyPolicy: {
    title: "PRIVACY POLICY",
  },
  weekdays: {
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
  },
  layout: {
    ourOfferings: "Our Offerings",
    mealPlans: "Meal Plans",
    ourMenu: "Our Menu",
    checkoutOurMenu: "CHECK OUT OUR MEALS",
    corporateLunch: "Corporate Lunch",
    needHelp: "Need Help?",
    faq: "FAQ",
    deliveryFaq: "Meal plan FAQ",
    aboutNosh: "About Nosh",
    blog: "Blog",
    getInTouch: "Get in Touch",
    joinUs: "Join Us",
    company: "Company",
    tnc: "Terms & Conditions",
    privatePolicy: "Privacy Policy",
    addr: "6 Sun Yip Street, Chai Wan",
    foodFactoryLicense: "Food Factory License: 2911209215",
    ourSuppliers: "Our Suppliers",
    cateringJunks: "Catering / Junks",
    login: "Login",
    signOut: "SIGNOUT",
    checkOut: "Checkout",
    continueToCheckout: "CONTINUE TO CHECKOUT",
    clearCart: "Clear Cart",
    items: "items",
    item: "item",
    cart: "Cart",
    email: "E-mail",
    password: "Password",
    notRegistered: "Not Registered?",
    createAnAccount: "Create an account",
    signUp: "Sign up",
    emailAddressReq: "Email Address*",
    usernameReq: "Username*",
    nameReq: "Name*",
    phoneNumberReq: "Phone Number*",
    confirmPasswordReq: "Confirm Password*",
    alreadyRegistered: "Already registered?",
    passwordReq: "Password*",
    signIn: "Sign In",
    previousMessage: "Previous Message",
    nextMessage: "Next Message",
    newsletterSubscribeCheckbox: "Subscribe to our newsletter",
    shoppingBag: "Shopping Bag",
    expiryMsg: "This item has expired. Remove to checkout.",
    or: "OR",
    giftCards: "Gift Cards",
    featuredMenuPlans: "FEATURED MEAL PLANS",
    checkOutOurMeals: "CHECK OUT OUR MEALS",
    ambassadors: "AMBASSADORS",
    ourBrandPartners: "OUR BRAND PARTNERS",
    checkoutBrandPartners: "Still want more? Check out our brand partners",
  },
  forgotPw: {
    title: "Reset Your Password",
    desc: "Type in your email address below and we'll send you an email with instructions on how to create a new password",
    button: "Reset Password",
    forgotPassword: "Forgot Password?",
    furtherInstructions:
      "We have sent you an email so please click on the link in your email and we’ll get your password changed in a jiffy.",
  },
  myAccount: {
    passwordChanged: "Password changed successfully",
    signedInAs: "SIGNED IN AS",
    sharingIsCaring: "Sharing is Caring",
    personalReferralCode: "Your Personal Referral Code",
    details: "DETAILS",
    name: "Name:",
    email: "Email:",
    building: "Building / Apartment / Unit:",
    address: "Address:",
    district: "District:",
    addressBook: "ADDRESS BOOK",
    createSecondAddress: "Create Second Address",
    edit: "Edit",
    changePassword: "Change Password",
    save: "Save",
    payment: "PAYMENT",
    creditCardNo: "Credit Card Number",
    expiryDate: "Expiry Date",
    cvc: "CVC",
    recentOrders: "RECENT ORDERS",
    orderNo: "Order No.",
    orderDate: "Purchase Date",
    orderEndDate: "Order End Date",
    numberOfDay: "Number Of Day",
    products: "Products",
    orderAmount: "Order Amount",
    products2: "Purchase Items",
    orderAmount2: "Total Amount",
    status: "Status",
    noshCredits: "NOSH CREDITS",
    balance: "BALANCE",
    youHave: "You have",
    availableStoreCredit: "available in store credit",
    cancel: "Cancel",
    copiedToClipboard: "copied to clipboard",
    deleteCard: "Delete Card",
    changeCard: "Change Card",
    districts: {
      newTerritories: "New Territories",
      kowloon: "Kowloon",
      hkIsland: "HK Island",
    },
    form: {
      addressRequired: "Address required.",
    },
    showAddress1: "Switch to Address 1",
    showAddress2: "Switch to Address 2",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
  },
  corpMeals: {
    title: "CATERING FOR LARGER EVENTS",
    desc: "Healthy food for everyone. That’s our mantra when it comes to our event catering service. NOSH offers HK catering services for every kind of corporate event, including conference, meeting, and large corporate event. We offer specialty menu choice and pricing for group of 30 up to event of 500.",
    startHere: "Start Here",
    bentoBox: "Bento Box",
    sharingPlatter: "Sharing Platter / Buffet",
    lunchBox: "Lunch Box",
    title2: "SEE OUR CATERING MENU",
    desc2: "Feed your crowd with chef-curated recipes.",
    corpLunchBox: "CORPORATE CATERING BY SPOONFUL",
    noshCateringMenu: "NOSH CATERING MENU",
    junkMenu: "JUNK MENU",
    festiveMenu: "Festive Menu",
    corpJunkMenu: "Corporate & Junk Menu",
    title3: "OUR BRANDS",
    desc3:
      "NOSH owns and operates two brands for its omni channel strategy where we sell to corporates, direct to consumer, and through retail outlets. Our mission is to design food for delivery and help Hong Kong eat smarter.",
    retailInfo: "RETAIL INFO",
    holidayBuffet: "HOLIDAY BUFFET MENU",
  },
  menu: {
    title: "Our Menu",
    desc: "Your favorite meal delivery service in Hong Kong. Choose between NOSH signature, NOSH Veggie, NOSH Keto or Comfort Food meal plans to help you stay fit and achieve your nutritional goals. You can see a sample of our weekly menu below. We deliver 3 - 4 wholesome meals per day each morning. These meals are designed by our in-house chef team and prepared fresh daily. Photos are for reference only.",
    step1: "1. Choose your Brand",
    step2: "2. Choose your Goal",
    step3: "3. Choose your Date",
    orderNow: "Order Now",
  },
  mealPlan: {
    title: "NOSH MEAL PLANS",
    desc: "Fresh. Healthy. Delicious",
    brand: "Brand",
    goal: "Goal",
    startNow: "Start Now",
    orderNow: "Order Now",
    title2: "MEAL PLAN FEATURES",
    desc2:
      "NOSH is your go-to source for fresh, healthy, delicious meal plans in Hong Kong.  Achieve your nutritional goals with NOSH, whether you are trying to lose weight, build muscle, or simply stay fit! Our meal plan delivery service defines what good, convenient food is all about.\n#EATWELL #LIVEBETTER",
    feature1: "Order before 9am Saturday",
    feature2: "Chef-made Recipes",
    feature3: "Delivered between 7-9am Daily",
    feature4: "Ready to eat - Full Macro",
    title3: "WHAT SETS US APART",
    desc3:
      "We believe in healthy, convenient, and smart food choices. Nosh prides itself on sourcing the best ingredients so that our customers enjoy a delicious meal in the comfort of their home or office",
    bioPackaging: "Biodegradable Packaging",
    title4: "WANT TO HAVE NOSH AS YOUR LUNCH? ORDER THROUGH SPOONFUL",
    desc4:
      "Spoonful is now available to download on iOS and Android! New features with loyalty and referral program.",
    weeklyMenu: "Preview Weekly Menu",
    breakfast: "Breakfast",
    lunch: "Lunch",
    dinner: "Dinner",
    snack: "Snack",
    extra_meal: "Extra Meal",
    extra_lunch: "Extra Lunch",
    extra_dinner: "Extra Dinner",
    breakfastDiscount1: "-HK$",
    breakfastDiscount2: "PER BREAKFAST",
    breakfastReminder: "ORDER A FULL PLAN TO GET $20 OFF BREAKFAST",
    daily: "DAILY",
    avgMacroGram: "AVG MACRO",
    toggleMeal: "Toggle Meal",
    calories: "Calories",
    protein: "Protein",
    carbs: "Carbs",
    fats: "Fats",
    detailPopupDesc1: "Fuel your Day",
    detailPopupDesc2: "Balanced Macronutrients",
    detailPopupDesc3: "Daily antioxidants",
    detailPopupDesc4: "For exceptional results add a protein shake",
    specialRequest: "Special Request",
    specialDetailsFooter1:
      "*We cannot accommodate gluten allergies and any other further special requests.",
    specialDetailsFooter2:
      "All is prepared in a food factory that handles milk, eggs, fish (e.g. bass, flounder, cod), tree nuts (e.g. almonds, walnuts, pecans), peanuts, wheat, and soybeans.",
    totalExtraCharge: "Total Extra Charge / Breakfast Savings:",
    orderCutOff: "ORDER HAVE ALREADY BEEN CUT OFF",
    thankYou: "THANK YOU!",
    orderCutOffIn: "ORDER CUT OFF TIME",
    gram: "GRAM",
    step1Title: "Choose your cuisine",
    select: "Select",
    brandContent:
      "All New Vegetarian Recipes\nDelicious Super Food and Imported Ingredients\nSustainable Packaging",
    step2Title: "Step 2",
    step2Desc: "Choose your goal",
    nextSlide: "Next Slide",
    prevSlide: "Previous Slide",
    chooseYourPackage: "Choose your package",
    noChange: "No Change",
    noBeef: "No Beef",
    noFish: "No fish",
    onlyFish: "Only Fish (Pescatarian)",
    noPork: "No Pork",
    noSalmon: "No Salmon",
    noTuna: "No Tuna",
    perDay: "per day",
    moreAboutThe: "More about",
    startDate: "Start Date",
    endDate: "End Date",
    monWedOnlyAvailable: "(Monday - Wednesday only available)",
    choosePackage: "Choose Package",
    howManyPeople: "How many people",
    addSpecialRequest: "Add Special Request",
    addAddOn: "Extra Add-On Items",
    howManyMealsPerDay: "How many meals per day?",
    freeSnackIncluded: "FREE SNACK INCLUDED",
    snackIncluded: "SNACK INCLUDED",
    selectAtLeast2Meals: "Please select at least two meals",
    confirmMealPlan: "CONFIRM MEAL PLAN",
    noWeeklyMenuForThisWeek: "No Weekly Menu for this week.",
    selectDays: "SELECT DAYS",
    packageDesc:
      "These are schedules that you can use anytime over a 30 day period. Think of us as a gym package for your belly!",
    mealTableMobHints:
      "Click on date to see our menu. Extra Meals are not included. We do not deliver on Weekends or Public Holidays. (If you choose to skip a day, it will be added to the end of your plan) Photos are for reference only.",
    packageLabel1: "From ",
    packageLabel2: " HKD / Day",
    specialRequestTitle1: "Ingredient Exclusion",
    specialRequestNote1: "",
    specialRequestTitle2: "Protein Change",
    specialRequestNote2: "No Extra Charge",
    step1: "Select Cuisine",
    step2: "Select Goal",
    step3: "Select Plan",
    step4: "Preview",
    step5: "Payment",
    menuPreview: "Menu Preview",
    step4Title: "Preview your order",
  },
  supplier: {
    title: "Meet Our Suppliers",
    subtitle: "Our Promise",
    desc: "At NOSH, we focus on every step of our ingredients' journeys. From field to cutting board, our sourcing teams work tirelessly to ensure that both premium and sustainable ingredients make it into every box.",
    desc2:
      "Our executive chef team has spent years developing both delicious and nutritious recipes. NOSH's meals are specifically designed for delivery and we are proud to use biodegradable packaging in all of our products.",
    desc3:
      "We know plastic waste is a serious problem worldwide and we want to do our part. Our packaging is fully designed out of both sugarcane and PLA plastics so that our customers can feel proud that the Nosh packaging decomposes in 51 days in a landfill. Eating better is also about leaving our planet better than how we found it.",
    feature: "Grade A Grass Fed Beef",
    feature2: "Hormone and GMO free Chicken",
    feature3: "Organic Peruvian Quinoa",
    feature4: "Sustainable Norwegian Salmon",
    videoDesc:
      "NOSH is proud to be a portfolio business with the Alibaba Entrepreneur Fund. Please see our Founder's message below regarding the business and the partnership.",
  },
  error: {
    errInitStripe: "Error initializing Stripe",
    errUpdatingAddress: "Error updating address: Could not retrieve user info",
    errCartAdd: "Error Adding item to cart",
    errValidUserAndPass: "Please enter a valid username and password",
    errSignUp: "Error signing up. Please try again",
    errAddOn: "This AddOn is no longer available",
  },
  form: {
    invalidEmail: "Should be valid email",
    invalidPhoneNumber: "Should be a valid phone number",
    oldPasswordRequired: "Old password required",
    newPasswordRequired: "New password required",
    confirmPasswordRequired: "New password required",
    passwordRequired: "Password required",
    invalidPWLength: "Password should be at least 8 characters.",
    pwNotMatching: "Passwords do not match",
  },
  checkout: {
    useNewAddr: "Use New Address",
    addrReq: "Address Required",
    addr4Char: "Address should be at least 4 characters long",
    addrNotRec:
      "Your address cannot be recognized by our service, please input a valid address",
    phoneReq: "Contact Number Required",
    phoneInvalid: "Please input a valid contact number",
    myCart: "My Cart",
    date: "Date",
    startDate: "Start Date",
    endDate: "End Date",
    package: "Package",
    mealsPerDay: "Meals per day",
    remove: "Remove",
    yourInfo: "YOUR INFORMATION",
    deliveryInfo: "DELIVERY INFO",
    deliveryDates: "Delivery Dates",
    contactNum: "Contact Number",
    sendMsg:
      "We will send messages to the above email address. Please ensure the email address is accessible and up-to-date.",
    pickAddr: "Pick a delivery address:",
    specialNotes: "Any special notes to our driver",
    chooseDeliveryTime:
      "Would you like to choose a specific morning delivery time?",
    chooseDeliveryTimeCharge:
      "Charge: HK$25 per day. Typical Deliveries fall between 7 and 9am. Choose your exact delivery time if necessary:",
    proceedToCheckout: "PROCEED TO CHECKOUT",
    continueToCheckout: "CONTINUE TO CHECKOUT",
    payment: "PAYMENT",
    subtotal: "Subtotal",
    extraCharge: "Extra Charge",
    breakfastSavings: "Breakfast Savings",
    discountCode: "Referral / Discount Code: ",
    noshCredits: "Use NOSH Credits",
    youHaveTotal: "",
    credits: "credits in wallet",
    total: "Total",
    cardNotes:
      "We store your credit card details through our Stripe API for future transactions",
    confirmAndPay: "CONFIRM & PAY",
    selectDays: "SELECT DAYS",
    deliveryDesc1: "Your delivery zone: ",
    deliveryDesc2: "Delivery Charge: ",
    deliveryDesc3: "per day",
    deliveryFree: "Free Delivery for this delivery zone",
    deliveryPrice: "Delivery Charge: ",
    youCanSave: "You can save ",
    forThisDiscount: " for this referral/discount code.",
    noDiscount: "Invalid discount code.",
    shoppingBag: "Shopping Bag",
    breakfastDiscount1: "",
    breakfastDiscount2: "HKD OFF for Breakfast",
    specialRequest: "Special Request / Breakfast Savings",
    quantity: "Quantity",
    reminderEmail: "Send me a reminder email before my meal plan ends!",
    donateFeedingHKTitle: "Donate to Feeding HK",
    donateFeedingHKDesc: "Help reduce food waste and feed those in need",
    donateFeedingRoundUp: "Round up",
    donateFeedingGiveMore: "Give more",
    moneyBackTitle: "Our money back guarantee:",
    moneyBackDesc:
      "If you are unsatisfied after your first day of meals, contact us and we'll make it right.",
  },
  checkoutSuccess: {
    title: "Payment Success",
    desc: "We will send you an email to confirm your order shortly",
    youHave: "You now have",
    creditsForFuture: "Nosh Credits for your next order.",
    backToHome: "BACK TO HOME",
    enjoy: "Enjoy your NOSH and #EATSMARTER",
    thanksAgain: "Thanks again for your support, and #EATSMARTER",
    vipGetInTouch: "If you have any questions, please get in touch!",
    vipTel:
      "Reach us at our Family & Friends only Whatsapp number 6771 0004 (8 am - 6:30 pm, Mon - Fri)",
    vipEmail: "or email us at VIP@nosh.hk",
    vipNotes:
      "(We will do our best to get back to you asap after hours, or during public holidays!)",
    referralCode: "Your referral code",
    referralMsg:
      "Remember to share your referral code for 100 HKD NOSH credits!",
  },
  newsletterWidget: {
    title:
      "Join to receive 15% OFF your first order and monthly perks. #EATSMARTER",
    button: "Join the community",
  },
  promoWidget: {
    title: "Join to receive $250HKD",
    button: "Join the community",
  },
  promo: {
    signup: {
      title1: "Subscribe to save",
      title2: "$250",
      title3: "in your first order",
      description:
        "*By entering your email address you are subscribing to our periodic emails",
    },
    code: {
      title1: "here's your",
      title2: "$250 promo code",
      description: "*new customer and 3-20 days plan only",
    },
    vitasoy: {
      title: "【Vitasoy Calci-Plus x NOSH offer】",
      subtitle:
        "Buy any two packs of Vitasoy Calci-Plus and get $250 OFF NOSH order & free dietitian consultation (Valued at $1000) 😍💥",
      guideTitle: "🎁 Here's how you can participate and win big:",
      step1: "👉 Step 1: ",
      step1Desc:
        "Purchase any two packs of 6 or 9 Vitasoy Calci-Plus Hi-Calcium Plant Milk (any flavour). 🛍️",
    },
  },
  vip: {
    memberOfNoshFamilyAndFriends: "MEMBER OF NOSH FAMILY & FRIENDS",
  },
  close: "Close",
  about: "About",
  confirm: "Confirm",
  days: "Days",
  day: "Day",
  hours: "Hours",
  mins: "Mins",
  secs: "Secs",
  next: "Next",
  week: "Week",
  total: "Total",
  lunch: "Lunch",
  breakfast: "Breakfast",
  dinner: "Dinner",
  snack: "Snack",
  extra_meal: "Extra Meal",
  extra_lunch: "Extra Lunch",
  extra_dinner: "Extra Dinner",
  hi: "Hi",
  welcome: "Welcome back",
  email: "Email",
  log_in: "Log In",
  sign_up: "Sign Up",
  cancel: "Cancel",
  langKey: "eng",
  langKey2: "en",
  langPath: "/en",
  loginWithFb: "Log in with Facebook",
};

export default en;
