import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Layout from '../../layouts/Layout';
import './OurSuppliers.scss';

const OurSuppliers = () => {
	const { t } = useTranslation();

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<Helmet>
				<title>Our Suppliers | NOSH</title>
				<meta
					name="description"
					content="NOSH gets the best ingredients from trusted suppliers, so you don’t have to worry about meal planning. Leave it all to us. Choose from our menu today."
				/>
			</Helmet>
			<main>
				<section className="our-suppliers-header">
					<div className="content">
						<h1>{t('supplier.title')}</h1>
					</div>
				</section>
				<section className="our-suppliers-our-promise">
					<div className="content">
						<h2>
							<hr />
							<span>{t('supplier.subtitle')}</span>
							<hr />
						</h2>
						<p>
							{t('supplier.desc')}
						</p>
						<p>
							{t('supplier.desc2')}
						</p>
						<p>
							{t('supplier.desc3')}
						</p>
						<div className="features">
							<div className="feature">
								<img alt="Order-before" src="/img/Beef.png" />
								<p>
									{t('supplier.feature')}
								</p>
							</div>
							<div className="feature">
								<img alt="Chef-made" src="/img/Chicken.png" />
								<p>
									{t('supplier.feature2')}
								</p>
							</div>
							<div className="feature">
								<img alt="Quinoa" src="/img/Quinoa.png" />
								<p>
									{t('supplier.feature3')}
								</p>
							</div>
							<div className="feature">
								<img alt="Salmon" src="/img/Salmon.png" />
								<p>
									{t('supplier.feature4')}
								</p>
							</div>
							<div className="feature">
								<img alt="bio" src="/img/bio.png" />
								<p>
									{t('mealPlan.bioPackaging')}
								</p>
							</div>
						</div>
						<div className="video-container">
							<p>
								{t('supplier.videoDesc')}
							</p>
							<ReactPlayer
								className="video-player"
								url="https://www.youtube.com/watch?v=fIxOoVwsPqU"
							/>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default OurSuppliers;
