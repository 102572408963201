import { createStore, applyMiddleware, compose } from 'redux';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
import { BrandsSaga } from '../pages/MealPlans';
import { AuthSaga } from './auth';
import { UserSaga } from './user';
import { OrderSaga } from './order';
import { SettingSaga } from './settings';
import { CartSaga } from './cart';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducer,
	composeEnhancers(applyMiddleware(sagaMiddleware)),
);

function* rootSaga() {
	yield all([
		BrandsSaga(),
		AuthSaga(),
		UserSaga(),
		OrderSaga(),
		SettingSaga(),
		CartSaga(),
	]);
}

sagaMiddleware.run(rootSaga);

export default store;
