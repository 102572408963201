import React, {
	useEffect, useState, createRef, useRef,
} from 'react';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { useTranslation } from 'react-i18next';
import Layout from '../../layouts/Layout';
import { fetchCuisines, fetchCuisineById } from '../../api';
import './Step3.scss';
import { Steps, SliderSelector } from '../../components';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { Goal } from '../../models';
import PlanDetailsPopup from './components/PlanDetailsPopup/PlanDetailsPopup';

interface Props {
	brandName: string;
	goalName: string;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const Step3 = ({ brandName, goalName }: Props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [goals, setGoals] = useState<Array<Goal> | null>(null);
	const [goal, setGoal] = useState<Goal | null>(null);
	const [days, setDays] = useState<number>(10);
	const [selectedPackage, setSelectedPackge] = useState<number | string>(10);
	const [enableNext, setEnableNext] = useState<boolean>(false);
	const choosePackage = createRef<any>();
	const query = useQuery();

	// Popup Plan Detail
	const [showPlanDetailsPopup, setShowPlanDetailsPopup] = useState<boolean>(false);
	const [popupGoal, setPopupGoal] = useState<Goal | null>(null);

	const closePlanDetailsPopupConfirm = () => {
		setPopupGoal(null);
	};
	const openPlanDetailsPopup = () => setShowPlanDetailsPopup(true);

	const onClickOpenGoalPopup = (x: Goal) => {
		setPopupGoal(x);
	};

	useEffect(() => {
		if (popupGoal) {
			openPlanDetailsPopup();
		} else {
			setShowPlanDetailsPopup(false);
		}
	}, [popupGoal]);

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		// BrandID translate: Brand Name to Brand Id
		fetchCuisines()
			.then(((res) => {
				const cuisines = res;
				const targetCuisine = cuisines.filter((el: any) => ((el.name).replace(/[^a-zA-Z0-9-_]/g, '') === brandName));
				// console.log('fetchCuisines', brandName, cuisines);
				if (targetCuisine.length < 1) {
					window.alert('Brand not found');
					history.push('/MealPlans/1');
				} else {
					const targetCuisineId = targetCuisine[0]._id;
					fetchCuisineById(targetCuisineId)
						.then(((res) => {
							setGoals(res.data.result);


							const goalList = res.data.result;
							const targetGoal = goalList.filter((el: any) => ((el.name).replace(/[^a-zA-Z0-9-_]/g, '') === goalName));
							if (targetGoal.length < 1) {
								window.alert('Goal not found');
								history.push(`/MealPlans/${brandName}`);
							} else {
								// const targetGoalId = targetGoal[0]._id;
								setGoal(targetGoal[0]);
							}

							const swiper = new Swiper('.goal-container .swiper-container', {
								pagination: {
									el: '.goal-container .swiper-pagination',
									type: 'bullets',
									clickable: true,
								},
								navigation: {
									nextEl: '.goal-container .swiper-button-next',
									prevEl: '.goal-container .swiper-button-prev',
								},
								slidesPerView: 1,
								spaceBetween: 30,
								watchOverflow: true,
								centerInsufficientSlides: true,
								breakpoints: {
									768: {
										slidesPerView: 2,
										spaceBetween: 30,
									},
									1200: {
										slidesPerView: 3,
										spaceBetween: 30,
									},
								},
							});
						}));
				}
			}));
	}, [brandName]);

	useEffect(() => {
		if (goal) {
			window.scrollTo({
				top: choosePackage.current.offsetTop - 30,
				left: 0,
				behavior: 'smooth',
			});
		}
	}, [goal]);

	const handlePackage = (chosenDays: any, customDays: number) => {
		setSelectedPackge(chosenDays);
		let selectedDays = 0;
		if (chosenDays === 'select') {
			selectedDays = customDays;
		} else {
			selectedDays = chosenDays;
		}


		if (selectedDays) {
			history.push(`/MealPlans/details/${brandName}/${goalName}/${selectedDays}`);
		} else {
			window.alert('Please select days first');
		}
	};



	useEffect(() => {
		if (!goals) return;
		const selectedGoal = query.get('goal');
		if (selectedGoal) {
			const tarGoal = goals.filter((goal: any) => goal._id === selectedGoal);
			setGoal(tarGoal[0]);
			const about = query.get('about');
			if (about) {
				onClickOpenGoalPopup(tarGoal[0]);
			}
		}
	}, [goals]);

	return (
		<Layout>
			<PlanDetailsPopup
				visible={showPlanDetailsPopup}
				onClosePopup={closePlanDetailsPopupConfirm}
				goal={popupGoal}
			/>
			<main className={`steps-container step2-goal lang-${t('langKey')}`}>
				<Steps step={3} links={['/MealPlans/1', `/MealPlans/${brandName}`, '', '', '']} />
				{!goals ? <LoadingScreen /> : ''}
				{goal && (
					<>
						<div className="package-container">
							<h1 ref={choosePackage} id="things" className="step3-package-title">{t('mealPlan.chooseYourPackage')}</h1>
							<p
								className="package-container-desc"
							>
								{brandName != "NOSHLife" ? (
									<div>{t('mealPlan.packageDesc')}</div>
								) : (
									<div></div>
								)}
								
							</p>
							<SliderSelector
								goal={goal}
								handlePackage={handlePackage}
								selectedPackage={selectedPackage}
							/>
						</div>
					</>
				)}
			</main>
		</Layout>
	);
};


const mapStateToProps = (state: RootState, props: any) => {
	const { match: { params: { brand, goal } } } = props;
	return ({
		brandName: brand,
		goalName: goal,
	});
};

export default connect(mapStateToProps)(Step3);
