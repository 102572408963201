import React from 'react';
import './ChangePasswordForm.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { NoshUser } from '../../../models/NoshUser';

type Props = {
	onSubmit: (data: any) => void;
	onCancel: () => void;
	user: NoshUser | null;
}

const ChangePasswordForm = ({ onSubmit, onCancel, user }: Props) => {
	const {
		register, handleSubmit, errors, triggerValidation, watch,
	} = useForm();
	const { t } = useTranslation();

	if (!user) return <div />;

	return (

		<form
			className="change-password-form-container"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="content-container">

				{/* <div> */}
				{/*	<label htmlFor="old-pw-input"> */}
				{/*		{t('myAccount.oldPassword')} */}
				{/*		: */}
				{/*	</label> */}
				{/*	<input */}
				{/*		id="old-pw-input" */}
				{/*		type="text" */}
				{/*		name="oldPassword" */}
				{/*		onChange={() => triggerValidation('oldPassword')} */}
				{/*		ref={register({		required: t('form.oldPasswordRequired').toString(), minLength: { value: 8, message: t('form.invalidPWLength') } })} */}
				{/*	/> */}
				{/* </div> */}
				{/* {errors.oldPassword && <p className="error-text">{errors.oldPassword.message}</p>} */}

				<div>
					<label htmlFor="new-pw-input">
						{t('myAccount.newPassword')}
						:
					</label>
					<input
						id="new-pw-input"
						type="password"
						name="newPassword"
						onChange={() => triggerValidation('newPassword')}
						ref={register({		required: t('form.newPasswordRequired').toString(), minLength: { value: 8, message: t('form.invalidPWLength') } })}
					/>
				</div>
				{errors.newPassword && <p className="error-text">{errors.newPassword.message}</p>}

				<div>
					<label htmlFor="confirm-pw-input">
						{t('myAccount.confirmPassword')}
						:
					</label>
					<input
						id="confirm-pw-input"
						type="password"
						name="confirmPassword"
						onChange={() => triggerValidation('confirmPassword')}
						ref={register({
							validate: (value) => (value === watch('newPassword')) || t('form.pwNotMatching').toString(),
							minLength: { value: 8, message: t('form.invalidPWLength') },
						})}
					/>
				</div>
				{errors.confirmPassword && <p className="error-text">{errors.confirmPassword.message}</p>}
			</div>
			<div className="details-buttons-container">
				<button
					type="button"
					onClick={onCancel}
				>
					{t('myAccount.cancel')}
				</button>
				<button
					type="submit"
				>
					{t('myAccount.save')}
				</button>
			</div>
		</form>
	);
};

export default ChangePasswordForm;
