import { createSelector } from 'reselect';
import { RootState } from 'ReduxTypes';

export const selectUser = (state: RootState) => state.user;

export const selectUserError = createSelector(
	selectUser,
	(user) => user.error,
);

export const selectNoshUser = createSelector(
	selectUser,
	(user) => user.noshUser,
);

export const selectLastPaymentInfo = createSelector(
	selectUser,
	(user) => user.paymentInfo && user.paymentInfo[user.paymentInfo.length-1],
);
