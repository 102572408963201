import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./Faq.scss";
import Layout from "../../layouts/Layout";
import i18n from "../../i18n/i18n";
import QaComponent from "./components/QaComponent";

const qna: {
  [index: string]: {
    question: string;
    answer: string;
    img?: string;
    content?: any;
  }[];
} = {
  en: [
    {
      question: "How do I get started?",
      answer:
        "Simply follow through the steps; choose meal plan, select package days, confirm the order and proceed to payment. We stop taking orders at 9AM on Saturdays for Monday delivery. Alternatively, you can order before Monday at 9AM to start on Tuesday.  The cut-off for ordering meal plan for the same week is at 9AM Tuesday.",
    },
    {
      question: "Can I change my meals in my meal plan?",
      answer:
        "Absolutely! Our meal plan is super flexible, meaning you can select your meals based on your preferences. You are welcome to contact our customer service agents to proceed with the meal plan change. If there is an outstanding amount, we would request payment. If there is an overpaid amount, we would deposit them as NOSH credit. You are welcome to use it when placing orders with us!",
    },
    {
      question: "How much does it cost?",
      answer: "Our meal plan can be as low as $80 per meal!",
      // img: '/img/assets/img-delivery-map-2021-july.png',
    },
    {
      question: "What is your delivery area?",
      answer:
        "We currently deliver to all of HK Island, Kowloon area and New Territories.  (except the Shek O and restricted areas e.g. Ma Wan).  It is now free delivery for all areas until further notice.",
    },
    {
      question: "How do I receive my delivery?",
      answer:
        "Meals are delivered every Monday – Friday (no delivery on public holidays). Dropped off at your doorstep between 6:30AM - 9:00AM. We will not interrupt your morning ritual, you can just open your door and begin your day when you're ready. Upon successful delivery, customers shall receive a whatsapp message regarding the delivery.",
    },
    {
      question: "Can I request special delivery time?",
      answer:
        "Yes, you can! You can leave us a customer note for a preferred delivery time frame. Our drivers will try their best to fulfill. If you required delivery in the exact time frame (6:30AM - 7:30AM/ 7:30AM – 8:30AM and 8:30AM – 9:30AM), we would require adding $25/day. You will be able to choose on the checkout page.",
    },
    {
      question:
        "I'm not sure which meal plan is right for me! Do you offer nutrition consultations?",
      answer:
        "Our meal plans are designed for the general public to achieve their meal goals. If you have questions about any of our other plans or would like to arrange a nutrition consultation with one of our partner nutritionists, please get in touch with our team at hello@nosh.hk.",
    },
    {
      question:
        "What is “NOSH Basics”? What’s the difference from other NOSH meal plans?",
      answer: "",
      content: [
        {
          subTitle: "What is NOSH Basics",
          answer: `The "NOSH Basics" meal plan is designed as a budget friendly option for those looking for healthy meal delivery without the additional frills that come with more premium / flexible plans such as our best selling NOSH Signature series. 
			
			    This no-frills plan is similar to the services offered by budget airlines where customers receive the basic service at a lower cost, with additional fees for extras and changes.`,
        },
        {
          subTitle: "Key Features",
          answer: `- Fixed Menu: Unlike the premium plans, the NOSH Basics has a fixed menu with limited options. This helps us reduce operational costs and pass on the savings to our NOSH Fam, who we care deeply about and go out of our way to make things work.
          - No Add-on Services: Services like skipping delivery days or customizing meals with specific ingredient exclusions or additions are not available in this plan.
          - Charges for Amendments: Any changes to the order, including cancellations and address change will incur a fee of $200. 
    
          For more information about the NOSH Basics meal plan, see >>>  https://basics.nosh.hk/
          Feel free to contact our CS hotline 2388 1999 or email <a href="mailto:hello@nosh.hk">hello@nosh.hk</a>`,
        },
      ],
    },
    {
      question: "How long does my meal plan purchase stay valid for?",
      answer: ` `,
      content: [
        {
          subTitle: ``,
          answer: `Meal plan start date must be within 60 days of purchase date.
        In the event that you need to suspend or skip a day within an ongoing meal plan, please refer to the following guidelines on different packages:
        - 15-day or under - Consumption of all meal plan schedules has to be within a 30-day period
        - Over 15 days but under 30 days - Consumption of all meal plan schedules has to be within a 60-day period
        - 30 days or longer - Consumption of all meal plan schedules has to be within 90-day period`,
        },
      ],
    },
    {
      question: "Can I pause or cancel my meal plan?",
      answer: `For order suspensions, cancellations, and other meal plan related changes, the cut-off time is 11AM for the following business day's meals. For delivery address related changes, the cut-off time is 4 pm for delivery the following business day. Please reach us at <a href="mailto:mealplan@nosh.hk">mealplan@nosh.hk</a> for the above changes. `,
    },
    {
      question: "Can I cancel a delivery after the order cut-off time?",
      answer:
        "After our 11AM cut off time, we place all our orders for fresh produce to bring you your tasty nutritious meals. We also prepare all the admin associated with your order which includes printing, arranging the labels for your meals and planning routes for the drivers to ensure timely delivery. Therefore, we are unable to cancel your order after the cut off times due to these costs associated. We hope you will understand that.",
    },
    {
      question:
        "How closely do you stick to your stated calories and nutrient info?",
      answer: `We always try to match with website advertised figures. However, you may expect a 10% variance for calories and carbs.`,
    },
    {
      question: "What if I want a snack that's not on the meal plan?",
      answer:
        "We recommend you balance what you eat with your health goals. If you are looking to eat more than what is in the meal plan or are just feeling snacky, we recommend something healthy like edamame, almonds, grapes, an apple, yogurt, hummus & veggie sticks, or dried fruits.",
    },
    {
      question: "How do I reheat my meals?",
      answer:
        "All our meals are delivered cold. Please follow the instructions on the outer sleeve / packaging. Generally, most main course reheating time are between 2 - 3 minutes on a 1000W microwave oven setting.",
    },
    {
      question:
        "Can I substitute dishes or items for allergy or preference reasons?",
      answer:
        'Yes, we are able to exclude some ingredients for you. Please make sure to click on the "special requests" section. Exclusions are not applicable for Keto, Lean & Green and Lean & Green Pro meal plans due to the complexity of the cuisines.',
    },
    {
      question: "How will I know what each meal contains?",
      answer:
        "All our items on the menu have a full macro and ingredients breakdown listed on the menu page. You may also find this information on the food tag on top of the meal box.",
    },
    {
      question: "How long do the meals last?",
      answer:
        "We make the meals fresh without usage of artificial additives and preservatives, and would recommend consumption within 36 hours.",
    },
    {
      question: "What if there are issues with my meals?",
      answer: `We aim to provide a 100% satisfaction guarantee. If there are any issues with your meals, please contact us at <a href="mailto:hello@nosh.hk">hello@nosh.hk</a> immediately explaining the issue to us and we will try our best to resolve this for you. A photo of the meal boxes in questions will be very helpful to us.`,
    },
    {
      question: "How does my food stay cool?",
      answer:
        "We take food production as the utmost importance of our business. All meals are packed in a temperature-controlled room then placed in insulating bags for delivery.",
    },
    {
      question: "Can I recycle my packaging?",
      answer:
        "We are committed to sustainability throughout our business and in our packaging. You can recycle and reuse our insulating bags. If you would like to return to us please send us an email at hello@nosh.hk. Our customer service agents will arrange a pick up accordingly for you.",
    },
    {
      question: "What are the food containers made of?",
      answer:
        "Most of our food containers are either made of straw and sugarcane. It is biodegradable and can be decomposed in landfills.",
    },
    {
      question:
        "What happens if there's typhoon no.8/Black Rain warning by the Hong Kong Observatory?",
      answer: `Situation 1: Black Rain/Typhoon 8 signal lowered or canceled at or before 7AM -Delivery service remains the same.
			Situation 2: Black Rain/Typhoon 8 continues post 7AM - No meal plan delivery on that day. Affected meal plan will be transferred into credits and deposited into your NOSH account.
			`,
    },
  ],
  zht: [
    {
      question: "我該如何開始?",
      answer:
        "只需按照以下步驟操作即可-<br>1. 選擇膳食計劃<br>2. 選擇套餐天數<br>3. 確認訂單並繼續付款。<br>我們在周六上午9 點停止接受星期一訂單。您可以在星期一上午9點之前訂購，以便星期二開始。當週訂餐計劃的截止時間為星期二上午9 點。星期二上午9點後的訂單最快將下星期一開始。",
    },
    {
      question: "請問可以轉膳食計劃嗎？",
      answer:
        "當然可以! 我們的膳食計劃非常靈活，這意味著您可以根據自己的喜好選擇膳食。歡迎您聯絡我們的客戶服務專員以進行膳食計劃變更。如膳食計劃改變產生費用，我們將收取。如有多付金額，我們會將其存入NOSH戶口。歡迎您在向我們下訂單時使用！",
    },
    {
      question: "請問平均一餐價格是多少？",
      answer: "我們的膳食計劃每餐可低至HKD $80 元！",
      // img: '/img/assets/img-delivery-map-2021-july.png',
    },
    {
      question: "請問您的送貨區域是？",
      answer:
        "目前我們的送貨服務涵蓋港島、九龍及大部分新界(石澳及馬灣等禁區除外)。現在訂單均可免費送貨，直至另行通知。",
    },
    {
      question: "我如何收到我的訂餐?",
      answer:
        "我們星期一至星期五送餐（公眾假期及週末不送餐）。上午6點30分至9 點送至您家門口。香港島的送貨地址將收到有關送貨的whatsapp訊息，九龍和新界則會收到簡訊通知。",
    },
    {
      question: "我可以要求特定的送餐時間嗎?",
      answer:
        "當然可以！ 您可以在客戶備註留下要求的送貨時間，我們的司機會盡力滿足。如果您需要在特定的時間範圍內（上午6點30分-上午7點30分 / 上午7點30分-上午8點30分 和 上午8點30分- 上午9點30分）送貨，請在下單頁面選擇。此項服務需收HKD $25/每日。",
    },
    {
      question: "我不確定哪種膳食計劃適合我，你們有提供營養諮詢嗎？",
      answer:
        "我們的膳食計劃是為公眾實現其膳食目標而設計的。如果您對我們的膳食計劃有疑問或想安排與我們的合作營養師之一的營養諮詢，請透過hello@nosh.hk 與我們的團隊聯繫。",
    },
    {
      question: "NOSH Basics 是什麼？與其他 NOSH 彈性膳食計畫有什麼不同？",
      answer: ``,
      content: [
        {
          subTitle: `NOSH Basics 簡介：經濟實惠的健康餐飲選擇`,
          answer: ``,
        },
        {
          subTitle: "NOSH Basics 是甚麼？",
          answer: `「NOSH Basics」膳食計劃專為追求健康，期望控制飲食熱量的人士而設，價格實惠。與其他NOSH彈性膳食計畫相比，NOSH Basics 是你步入健康飲食的「簡約入門小幫手」。
			
          訂購NOSH Basics膳食計畫後，你可以實惠價錢享用基本服務，而附加服務例如訂單更改則需收取額外費用。`,
        },
        {
          subTitle: "NOSH Basics 特點",
          answer: `- 固定餐單：與NOSH彈性膳食計劃不同，我們的專業團隊為正步入健康飲食的你準備好一系列幫助控制熱量的固定餐單。
			
			- 不含附加服務：NOSH Basics 計劃恕不提供任何送餐日子更改或特定食材刪減/添加等服務。
			
			- 訂單更改服務費：任何訂單更改，包括取消訂單或送餐地址更改，均收取服務費港幣$200乙次 。
			
			想了解更多關於 NOSH Basics 膳食計劃資訊，請瀏覽 >>> https://basics.nosh.hk/
			如有任何其他疑問，歡迎致電客戶服務熱線 2388 1999 或電郵至 hello@nosh.hk`,
        },
      ],
    },
    {
      question: "我購買的膳食計劃的有效期限是多久？",
      answer:
        "如果您需要暫停或跳過用餐計劃的送貨天數，15天或以下的套餐可以在30天內使用，超過15天的套餐可以在60天內使用，超過30天的套餐天可以在90 天內使用。",
    },
    {
      question: "我可以暫停或取消我的膳食計劃嗎?",
      answer: `如需為膳食計劃暫停或取消，截止時間為送餐前1天上午11 點。送貨地址相關的變更，截止時間為送餐前1天下午4 點。請透過mealplan@nosh.hk聯絡與我們的團隊聯繫。`,
    },
    {
      question: "請問過了截止時間後我可以取消送貨嗎？",
      answer: `上午11 點的截止時間後，我們會訂購所有新鮮農產品，為您帶來
			美味的營養餐。我們還準備與您的訂單相關的所有文書工作，包括列
			印標籤、安排餐點以及為司機規劃路線，以確保及時交貨。
			由於相關費用，我們無法在截止時間後取消您的訂單。我們希望您能
			理解這一點。`,
    },
    {
      question: "請問你如何確保卡路里和營養攝取與網上相符？",
      answer: `我們盡量嘗試與網站資料相符。然而，您可能會預期卡路里和碳
			水化合物會有10% 的差異.`,
    },
    {
      question: "如何加熱餐點？",
      answer: `我們的餐點都是先煮好然後急速冷凍。請遵循包裝上的說明加熱
			餐點。一般而言，在1000W 微波爐設定下，大多數主菜重新加熱時
			間為2 - 3 分鐘（沙拉除外）。`,
    },
    {
      question: "由於過敏或偏好原因，我可以更改餐點中的用料嗎？",
      answer: `是的，我們可以為您更改餐點中的用料。請務必點選「特別飲食要
			求」部分。由於菜餚的複雜性，更改餐點中的用料不適用於Keto、
			Ocean、Lean & Green、NOSH Basics 和Comfort Food 膳食計劃。`,
    },
    {
      question: "我如何知道每餐包含什麼？",
      answer: `我們菜單上的菜餚都在菜單頁面(https://nosh.hk/en/our-menu/)
			上列出了完整的營養資訊。您也可在餐盒上的食品標籤找到資訊。`,
    },
    {
      question: "請問餐點可以放置多久？",
      answer: `我們的膳食餐點不使用人工添加劑和防腐劑，我們建議在36小時內食用。`,
    },
    {
      question: "如果我的餐點出現問題怎麼辦？",
      answer: `我們的目標是提供100% 滿意度。如果您的餐點有任何問題，請透過hello@nosh.hk聯絡我們，我們將盡力為您解決。如果能提供相片作為佐證更好！`,
    },
    {
      question: "請問我的膳食餐點如何保持溫度？",
      answer: `我們視食品生產為我們業務的重點。所有餐點均在溫控室包裝，然後放入保溫袋中運送。`,
    },
    {
      question: "保溫袋可以回收嗎？",
      answer: `當然可以！我們致力帶領整個業務和包裝都是可持續發展。您可以重用我們的保溫袋。如需要給我們回收重用，請發送電子郵件至hello@nosh.hk。我們的客戶服務代理將為您安排相應的上門收取服務。`,
    },
    {
      question: "食物盒是由什麼製成的？",
      answer: `我們大多數的食品盒都是由稻草和甘蔗製成的。都是生物降解的用料，可以在垃圾場中分解。`,
    },
    {
      question: "如果香港天文台發出八號颱風/黑雨警告會怎樣？",
      answer: `情況1：黑雨/颱風8號訊號於上午7或之前減弱或取消- 送貨服務維持不變。
			情境2：黑雨/8 號颱風在上午7 點後持續- 當天沒有膳食餐點配送。受影響的膳食餐點計劃將轉入積分並存入您的NOSH 帳戶。`,
    },
  ],
};

const Faq = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const { t } = useTranslation();

  const [lang, setLang] = useState(i18n.getLang());

  useEffect(() => {
    let currentLang = i18n.getLang() != "en" ? "zht" : "en";
    setLang(currentLang);
    console.log(currentLang);
  }, []);

  return (
    <Layout>
      <h1 className="a11y-hidden">Meal plan FAQ</h1>
      <Helmet>
        <title>Delivery FAQ’s | NOSH</title>
        <meta
          name="description"
          content="Questions about our meal plan delivery service? See how our process works and start ordering healthy meals from NOSH today!"
        />
      </Helmet>
      <div className="faq-page">
        <div className="faq-header">
          <p className="faq-header-title">{t("faq.title")}</p>
        </div>
        {qna[i18n.getLang()].map(
          (_qna: {
            question: string;
            answer: string;
            img?: string;
            content?: any;
          }) => (
            <QaComponent
              question={_qna.question}
              answer={_qna.answer}
              img={_qna.img}
              content={_qna?.content}
            />
          )
        )}
        <div className="faq-footer">
          <p>{t("faq.footer1")}</p>
          <p className="line2">{t("faq.footer2")}</p>
          <p className="tele">2388 1999</p>
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
