const AppConfig = {
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  API_URL: process.env.REACT_APP_API_URL,
  NEWSLETTER_LIST_ID: process.env.REACT_APP_MAILCHIMP_NEWSLETTER_ID,
  SHARE_URL: process.env.REACT_APP_SHARE_URL,
  FB_APP_KEY: process.env.REACT_APP_FB_APP_KEY,
  GA_ID: process.env.REACT_APP_GA_ID,
};

export default AppConfig;
