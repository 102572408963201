import { ActionType } from 'typesafe-actions';
import * as OrderActions from './actions';
import * as AuthActions from '../../auth/duck/actions';
import { Order } from '../../../models/Order';

export interface State{
	orders: Order[] | null;
	error?: string;
}

const initialState: State = {
	orders: null,
};

type OrderActions = ActionType<typeof OrderActions>;
type AuthActions = ActionType<typeof AuthActions>;

export default function reducer(
	state: State = initialState, action: OrderActions | AuthActions,
): State {
	switch (action.type) {
		case OrderActions.FETCH_ORDERS:
			return {
				...state,
				error: undefined,
			};
		case OrderActions.FETCH_ORDERS_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case OrderActions.FETCH_ORDERS_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case AuthActions.LOGOUT:
			return initialState;
		default:
			return state;
	}
}
