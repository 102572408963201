import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'swiper/css/swiper.css';
import './HomeOurMeals.scss';

import Swiper from 'swiper';
import { Helmet } from 'react-helmet';

const HomeOurMeals = () => {
	const { t } = useTranslation();
	// TEST
	useEffect(() => {
		const swiper = new Swiper('.home-our-meals-slider .swiper-container', {
			// pagination: {
			// 	el: '.goal-container .swiper-pagination',
			// 	type: 'bullets',
			// 	clickable: true,
			// },
			navigation: {
				nextEl: '.home-our-meals-slider .swiper-button-next',
				prevEl: '.home-our-meals-slider .swiper-button-prev',
			},
			slidesPerView: 1,
			spaceBetween: 20,
			watchOverflow: true,
			centerInsufficientSlides: true,
			breakpoints: {
				481: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				640: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 6,
					spaceBetween: 20,
				},
			},
		});
	}, []);
	return (
		<>
			<section className="home-our-meals">
				<div className="home-our-meals-inner">
					<h2 className="home-our-meals-title">{t('layout.checkOutOurMeals')}</h2>
					<div className="home-our-meals-slider">
						<div className="swiper-control">
							<button type="button" className="swiper-button-prev">{t('mealPlan.prevSlide')}</button>
							<button type="button" className="swiper-button-next">{t('mealPlan.nextSlide')}</button>
						</div>
						<div className="swiper-container">
							<div className="swiper-wrapper">
								{[1, 2, 3, 4, 5, 6, 7].map((x: number, index: number) => (
									<div key={`${x}-${index + 1}`} className="home-our-meals-slider-item swiper-slide">
										<Link to="/our-menu/">
											<div className="home-our-meals-slider-img" style={{ backgroundImage: `url(/img/assets/home-checkout-meals/our-meals-${x}.jpg)` }} />
										</Link>
									</div>
								))}
							</div>
						</div>
					</div>
					<Link to="/our-menu/" className="home-our-meals-btn">{t('layout.ourMenu')}</Link>
				</div>
			</section>
		</>
	);
};

export default HomeOurMeals;
