import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'swiper/css/swiper.css';
import './PartnersLogos.scss';

import Swiper from 'swiper';

type Props = {
	page: string;
}

const PartnersLogos = ({ page }: Props) => {
	const { t } = useTranslation();
	// TEST
	// useEffect(() => {
	// 	const swiper = new Swiper('.partners-logos-slider .swiper-container', {
	// 		// pagination: {
	// 		// 	el: '.goal-container .swiper-pagination',
	// 		// 	type: 'bullets',
	// 		// 	clickable: true,
	// 		// },
	// 		navigation: {
	// 			nextEl: '.partners-logos-slider .swiper-button-next',
	// 			prevEl: '.partners-logos-slider .swiper-button-prev',
	// 		},
	// 		slidesPerView: 1,
	// 		spaceBetween: 20,
	// 		watchOverflow: true,
	// 		centerInsufficientSlides: true,
	// 		breakpoints: {
	// 			481: {
	// 				slidesPerView: 2,
	// 				spaceBetween: 20,
	// 			},
	// 			640: {
	// 				slidesPerView: 3,
	// 				spaceBetween: 20,
	// 			},
	// 			768: {
	// 				slidesPerView: 4,
	// 				spaceBetween: 20,
	// 			},
	// 			1024: {
	// 				slidesPerView: 6,
	// 				spaceBetween: 20,
	// 			},
	// 		},
	// 	});
	// }, []);
	return (
		<section className="partners-logos">
			<div className="partners-logos-inner">
				<h2 className="partners-logos-title">{t('layout.ourBrandPartners')}</h2>
				<h3 className="partners-logos-checkout-title">{t('layout.checkoutBrandPartners')}</h3>
				<div className="partners-logos-items">
					<a
						className="partners-logos-item"
						href={`http://www.spoonfulmeals.com/home?utm_source=NOSH&utm_medium=referral&utm_campaign=NOSH_${page}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<div
							className="partners-logos-item-img"
							style={{ backgroundImage: 'url(/img/assets/partners-logos/logo-spoonful.png)' }}
						/>
					</a>
					<a
						className="partners-logos-item"
						href={`https://www.southstreammarket.com/?utm_source=NOSH&utm_medium=Referral&utm_campaign=NOSH_${page}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<div
							className="partners-logos-item-img"
							style={{ backgroundImage: 'url(/img/assets/partners-logos/logo-south-stream.png)' }}
						/>
					</a>
				</div>
				{/* <div className="partners-logos-slider">
					<div className="swiper-control">
						<button type="button" className="swiper-button-prev">{t('mealPlan.prevSlide')}</button>
						<button type="button" className="swiper-button-next">{t('mealPlan.nextSlide')}</button>
					</div>
					<div className="swiper-container">
						<div className="swiper-wrapper">
							{[1, 2, 3, 4, 5, 6, 7].map((x: number, index: number) => (
								<div key={`${x}-${index + 1}`} className="partners-logos-slider-item swiper-slide">
									<Link to="/our-menu/">
										<div className="partners-logos-slider-img" style={{ backgroundImage: `url(/img/assets/home-checkout-meals/our-meals-${x}.jpg)` }} />
									</Link>
								</div>
							))}
						</div>
					</div>
				</div> */}
			</div>
		</section>
	);
};

export default PartnersLogos;
