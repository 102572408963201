import axios from './axios';

export const fetchCuisines = () => axios.get('v1/cuisine/all/active').then((res) => res.data.cuisines );

export const fetchCuisineById = (id: string) => axios.get(`v1/cuisine/${id}`);

export const fetchGoalAndMeals = (id: string, weekOpt: string|null) => axios.get(`v1/goals/${id}?${(weekOpt) ? `start_date=${weekOpt}` : ''}`);

export const fetchAllGoals = () => axios.get('v1/goals/');

export const fetchCart = () => axios.get('v1/cart');

export const fetchUserDetail = () => axios.get('v1/user');

export const getNotificationMsg = () => axios.get('v1/notifications');

export const fetchExtra = () => axios.get('v1/extras');

export const fetchStartDates = () => axios.get('v1/goals/_get_available_date');

export const fetchCutOffTime = () => axios.get('v1/goals/_get_cutoff');

export const fetchHolidays = () => axios.get('v1/holidays');

export const findLogisticZone = (lat: string, lng: string) => axios.post(
	'/v1/logistic_zone/_find',
	{
		lat,
		lng,
	},
);

export const findDiscount = (code: string, amount: number|null) => axios.get(`v1/discount_code?code=${code}&amount=${amount}`);

export const fetchAddOns = (all: boolean) => axios.get(`v1/add_on?all=${all}`);

export const submitAddOns = (data: any) => axios.post(
	'v1/cart/set_add_on',
	{
		addOns: data,
	},
);

export const submitFirstOrderLead = (lead: string) => axios.post(
	'/v1/user/first_order_lead',
	{
		data: lead,
	},
);
