/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { RootState } from "MyTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

// @ts-ignore
import Geocode from "react-geocode";
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";

import Select from "react-select";
import moment from "moment";
import { isSafari } from "react-device-detect";
import AddOnPopup from "./components/AddOnPopup/AddOnPopup";
import { Steps } from "../../components";
import * as CartService from "../../api/CartService";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import * as UserService from "../../api/UserService";
import * as OrderService from "../../api/OrderService";
import { CartItem, MealTypeIndex } from "../../models/CartItem";
import {
  fetchUserDetail,
  fetchCart,
  findLogisticZone,
  findDiscount,
  fetchExtra,
  fetchAddOns,
  submitAddOns,
} from "../../api";
import Layout from "../../layouts/Layout";
import "./Checkout.scss";
import { selectToken } from "../../store/auth/duck/selectors";
import AppConfig from "../../config/AppConfig";

// Set API Key for Geocode
Geocode.setApiKey("AIzaSyDYbVGkfbKs-HvzxjeZ63iGRGsz8c0sB2o");

// Function to handle scroll (e.g. Proceed to Checkout scrolling to payment)
const scrollToRef = (ref: any, offset: number) => {
  if (ref.current) {
    window.scrollTo({
      top: ref.current.offsetTop - 100 + offset,
      left: 0,
      behavior: "smooth",
    });
  }
};
const scrollToRefIntoView = (ref: any) => {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

// For GA Ecommerce
declare global {
  interface Window {
    ga: any;
    gtag: any;
    fbq: any;
    HTMLElement: any;
    safari: any;
  }
}

const useQuery = () => new URLSearchParams(useLocation().search);

const hasApplePaySupport = () =>
  // const query = new URLSearchParams(window.location.search);
  // const mode = query.get('mode');
  isSafari;
const dummyAddons = {
  addOns: {
    // '21-09-2020': [
    // 	'5f606ee6e969d6022e2e94d0',
    // 	'5f606ee6e969d6022e2e94d0',
    // 	'5f6338ffd657ee0e32923d7e',
    // ],
    // '22-09-2020': [
    // 	'5f606ee6e969d6022e2e94d0',
    // ],
  },
};

const dateFormat = "DD-MM-YYYY";
const labelFormat = "MMMM, D, YYYY (ddd)";

type Props = {
  token: string | null;
};

const Checkout = ({ token }: Props) => {
  const { t } = useTranslation();
  // const [cuisines, setCusines] = useState<string | null>(null);
  const [cart, setCart] = useState<any | null>({});
  const [cartItems, setCartItems] = useState<Array<any> | null>([]);
  const [latestCartItemInfo, setLatestCartItemInfo] = useState<any>(null);
  const [extras, setExtras] = useState<any>(null);
  const [proceedToPayment, setProceedToPayment] = useState<boolean>(false);
  const [checkoutState, setCheckoutState] = useState<string>("loading");
  const [user, setUser] = useState<any>({});
  const [userDistrict, setUserDistrict] = useState<any>();
  const [userAddress, setUserAddress] = useState<string>("");
  const [userBuilding, setUserBuilding] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [addressOpts, setAddressOpts] = useState<any>(null);
  const [addressOpt, setAddressOpt] = useState<any>(null);
  const [deliveryOpt, setDeliveryOpt] = useState<any>(null);
  const [deliveryRemarks, setDeliveryRemarks] = useState<string>("");
  const [paymentMethodOpts, setPaymentMethodOpts] = useState<any>(null);
  const [useNewPayment, setUseNewPayment] = useState<boolean>(true);
  const [paymentMethodId, setPaymentMethodId] = useState<string>("");
  const [addrCoords, setAddrCoords] = useState<Array<string>>([]);
  const [userReminderEmail, setUserReminderEmail] = useState<boolean>(true);
  // const [stripeReady, setStripeReady] = useState<boolean>(false);

  // Prices
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0);
  const [addOnPrice, setAddOnPrice] = useState<number>(0);
  const [extraPrice, setExtraPrice] = useState<number>(0);
  const [deliverPrice, setDeliverPrice] = useState<number>(0);
  const [useCredits, setUseCredits] = useState<number>(0);
  const [useDiscount, setUseDiscount] = useState<string>("");
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPriceBeforeDiscount, setTotalBeforeDiscount] =
    useState<number>(0);
  const [feedingHKRoundup, setFeedingHKRoundup] = useState<boolean>(false);
  const [feedingHKRoundupAmount, setFeedingHKRoundupAmount] =
    useState<number>(0);
  const [feedingHKMoreAmount, setFeedingMoreHKAmount] = useState<number>(0);

  // Validation
  const [isValidAddr, setIsValidAddr] = useState<boolean>(true);
  const [inValidMsgAddr, setInValidMsgAddr] = useState<string>("");

  const [isValidPhone, setIsValidPhone] = useState<boolean>(true);
  const [inValidMsgPhone, setInValidMsgPhone] = useState<string>("");

  const [deliverZone, setDeliverZone] = useState<any>(null);
  const [discount, setDiscount] = useState<any>(null);
  const [discountMsg, setDiscountMsg] = useState<string>("");
  // const [user, setUser] = useState<any>({});

  // Add On Popup
  const [AddOnPopupVisible, setAddOnPopupVisible] = useState<boolean>(false);
  const [cartAddOns, setCartAddOns] = useState<any>(null);
  const [adjustedCartAddOns, setAdjustedCartAddOns] = useState<any>(null);
  const [addOnDates, setAddOnDates] = useState<string>(""); // display in cart
  const [addOns, setAddOns] = useState<any>(null);
  const [addOnDatesList, setAddOnDatesList] = useState<any>(null); // for edit options


  // Apple pay
  const [useApplePay, setUseApplePay] = useState<boolean>(false);

  const history = useHistory();

  const districtOpts = [
    {
      value: "HK Island",
      label: t("HK Island"),
    },
    {
      value: "Kowloon",
      label: t("Kowloon"),
    },
    {
      value: "New Territories",
      label: t("New Territories"),
    },
  ];

  const deliveryOpts = [
    {
      value: "delivery7_9",
      label: "6:30am - 9:00am (Free Delivery)",
    },
    {
      value: "delivery7_8",
      label: "6:30am - 7:30am (+HK$25)",
    },
    {
      value: "delivery8_9",
      label: "7:30am - 8:30am (+HK$25)",
    },
    {
      value: "delivery9_10",
      label: "8:30am - 9:30am (+HK$25)",
    },
  ];

  const checkAddrValid = (addr: string) => {
    const str = addr
      .replace(/\s/g, "")
      .replace(/[&\/\\#,+()$~%.'":*?<>{}@!]/g, "");
    if (str.length < 4) {
      return false;
    }
    return true;
  };

  const syncBuildingFormat = (dataBuilding: any) => {
    let adjustedBuilding = dataBuilding;
    if (typeof adjustedBuilding === "object") {
      adjustedBuilding = adjustedBuilding?.building;
    }
    return adjustedBuilding;
  };

  // Back to top
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchExtra().then((res) => {
      if (res.data.extras) {
        setExtras(res.data.extras);
      }
    });
  }, []);
  // useEffect(() => {
  // 	setTimeout(() => {
  // 		if (!token ) history.push('/MealPlans/1');
  // 	}, 3000);
  // }, []);

  useEffect(() => {
    console.log("Apple pay", useApplePay);
  }, [useApplePay]);

  useEffect(() => {
    // setTimeout(() => {
    // 	if (!token) history.push('/MealPlans/1');
    // }, 3000);
    if (token && !cart) {
      // history.push('/MealPlans/1');
      submitAddOns({})
        .then((res: any) => {
          history.push("/MealPlans/1");
        })
        .catch((err: any) => {
          let errMSg = "";
          if (err.response) {
            if (err.response.data) {
              if (err.response.data.error) {
                errMSg = err.response.data.error.message;
              }
            }
          }
          window.alert(`There is an error removing add ons: ${errMSg}`);
        })
        .then(() => {
          history.push("/MealPlans/1");
        });
    }
  }, [token, cart]);

  useEffect(() => {
    if (!token) return;

    fetchCart()
      .then((res) => {
        const dataCart = res.data.cart;
        // console.log('cart', res);
        if (dataCart.items && dataCart.items.length > 0) {
          setCart({
            ...dataCart,
            extra_amount: res.data.extra_amount,
            amount: res.data.amount,
            add_on_amount: res.data?.add_on_amount || 0,
          });
        } else {
          setCart(null);
        }
        setCheckoutState("");
      })
      .catch((error) => {
        setCart(null);
      });

    fetchUserDetail()
      .then((res) => {
        let dataUser = { ...res.data.user };
        if (res.data.user.secondAddressBuilding) {
          dataUser = { ...res.data.user, addressObject: "" };
        }
        setUser(dataUser);
        if (dataUser.district) {
          setUserDistrict({
            value: dataUser.district,
            label: dataUser.district,
          });
        } else {
          setUserDistrict({
            value: "HK Island",
            label: "HK Island",
          });
        }
        setDeliveryOpt({
          value: "delivery7_9",
          label: "6:30am - 9:00am (Free Delivery)",
        });
        // initPaymentMethod();
        setProceedToPayment(false);
      })
      .catch((error) => {
        setCart(null);
      });

    UserService.getPaymentInfo().then((res) => {
      if (res.data.payment_methods && res.data.payment_methods.length > 0) {
        setPaymentMethodOpts(res.data.payment_methods);
        setUseNewPayment(false);
      }
    });
  }, [token]);

  useEffect(() => {
    if (!cart) return;

    if (typeof cart.amount !== "undefined" && cart.amount !== null) {
      let thisSubTotalPrice = Math.ceil(cart.amount);
      if (
        typeof cart.add_on_amount !== "undefined" &&
        cart.add_on_amount !== null
      ) {
        thisSubTotalPrice += Math.ceil(cart.add_on_amount);
      }
      setSubTotalPrice(thisSubTotalPrice);
    }

    if (
      typeof cart.extra_amount !== "undefined" &&
      cart.extra_amount !== null
    ) {
      setExtraPrice(cart.extra_amount);
    }

    if (cart.items) {
      const cartItemsData = cart.items;
      const thisCartItems = cart.items.map((item: CartItem) => ({
        ...item,
        _price: calculatePrice(item),
      }));
      setCartItems(thisCartItems);
      // console.log('thisCartItems', thisCartItems);
      // Get latest item
      // const mostRecentDate = new Date(Math.max.apply(null, cartItemsData.map((e: any) => {
      // 	return new Date(e.updatedAt);
      // })));
      // const mostRecentObject = cartItemsData.filter((e: any) => {
      // 	const d = new Date(e.updatedAt);
      // 	return d.getTime() === mostRecentDate.getTime();
      // })[0];
      if (cartItemsData.length > 0) {
        const mostRecentObject = thisCartItems.slice(-1)[0];
        setLatestCartItemInfo({
          brandName: mostRecentObject?.goalId?.cuisineId?.name?.replace(
            /[^a-zA-Z0-9-_]/g,
            ""
          ),
          goalName: mostRecentObject?.goalId?.name?.replace(
            /[^a-zA-Z0-9-_]/g,
            ""
          ),
          noOfDays: mostRecentObject.noOfDays,
        });
      }
    }
  }, [cart]);

  const checkPhone = () => {
    setIsValidPhone(true);
    if (!userPhone) {
      setIsValidPhone(false);
      setInValidMsgPhone(t("checkout.phoneReq"));
      return false;
    }
    const intRegex = /^\d+$/;
    const formatCorrect = userPhone.length === 8 && intRegex.test(userPhone);
    if (!formatCorrect) {
      setIsValidPhone(false);
      setInValidMsgPhone(t("checkout.phoneInvalid"));
      return false;
    }
    return true;
  };

  // Get User Info
  useEffect(() => {
    let initUserAddressOpt = {
      value: 0,
      label: t("checkout.useNewAddr"),
    };
    const userAddressOpts = [];
    let user1stAddr;
    let user2ndAddr;
    if (user.address) {
      user1stAddr = {
        value: 1,
        label: user.address,
      };
      userAddressOpts.push(user1stAddr);
      initUserAddressOpt = user1stAddr;
    }
    if (user.secondAddressFullAddress) {
      user2ndAddr = {
        value: 2,
        label: user.secondAddressFullAddress,
      };
      userAddressOpts.push(user2ndAddr);
      if (!user.address) {
        initUserAddressOpt = user2ndAddr;
      }
    }
    userAddressOpts.push({
      value: "",
      label: t("checkout.useNewAddr"),
    });
    setAddressOpts(userAddressOpts);
    setAddressOpt(initUserAddressOpt);
  }, [user]);

  useEffect(() => {
    let currUserAddr = "";
    let currUserBld = "";
    if (addressOpt && addressOpt.value && user) {
      if (addressOpt.value === 1) {
        currUserAddr = user.address;
        currUserBld = syncBuildingFormat(user.building);
      } else if (addressOpt.value === 2) {
        currUserAddr = user.secondAddressFullAddress;
        if (user.secondAddressBuilding) {
          currUserBld = syncBuildingFormat(user.secondAddressBuilding);
        }
      }
    }
    setUserAddress(currUserAddr);
    setUserBuilding(currUserBld);
    setUserAddress(currUserAddr);

    setUserName(user.memberName);
    setUserEmail(user.email);
    setUserPhone(user.phone);
  }, [addressOpt, user]);

  useEffect(() => {
    if (!useNewPayment) {
      const latestPaymentMethod = getLatestPayment(paymentMethodOpts);
      setPaymentMethodId(latestPaymentMethod._id);
    }
  }, [useNewPayment, paymentMethodOpts]);

  const getLatestPayment = (paymentArray: any) => {
    const enabledPaymentArray = paymentArray.filter((el: any) => !el.isDeleted);
    return enabledPaymentArray[enabledPaymentArray.length - 1];
  };

  // Initialize User

  useEffect(() => {
    if (useDiscount) {
      const totalVal = subTotalPrice + extraPrice;
      // if (deliverZone && deliverZone.price) {
      // 	totalVal += deliverZone.price;
      // }
      findDiscount(useDiscount, totalVal)
        .then((res) => {
          // console.log('Logistic', res);
          if (res.data) {
            setDiscount(res.data);
          }
        })
        .catch((error) => {
          setDiscount("wrong");
          const errMsg = error?.response?.data?.error?.message;
          setDiscountMsg(errMsg || t("checkout.noDiscount"));
        });
    } else {
      setDiscount(null);
      setDiscountMsg("");
    }
  }, [useDiscount, subTotalPrice, extraPrice, deliverZone]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (useCredits === 0) return;
    if (isNaN(useCredits)) {
      setUseCredits(0);
    } else if (useCredits > user.credits) {
      setUseCredits(user.credits);
    } else if (useCredits < 0) {
      setUseCredits(0);
    }
    let maxAmount = subTotalPrice + extraPrice;
    if (discount && discount !== "wrong") {
      maxAmount -= discount.discount_amount;
    }
    maxAmount = Math.ceil(maxAmount);
    if (useCredits > maxAmount) {
      setUseCredits(maxAmount);
    }
  }, [extraPrice, subTotalPrice, useCredits, user.credits, discount]);

  // Get Round up Price
  useEffect(() => {
    let totalVal = subTotalPrice + extraPrice - useCredits;
    if (deliverPrice) {
      totalVal += deliverPrice;
    }
    if (discount && discount !== "wrong") {
      totalVal -= discount.discount_amount;
    }
    const total = Math.ceil(totalVal);
    const roundUp = Math.ceil(total / 10) * 10 - total;
    setFeedingHKRoundupAmount(roundUp);
  }, [useCredits, subTotalPrice, extraPrice, deliverPrice, discount]);

  // Total Price
  useEffect(() => {
    let totalVal = subTotalPrice + extraPrice - useCredits;
    if (deliverPrice) {
      totalVal += deliverPrice;
    }
    setTotalBeforeDiscount(Math.ceil(totalVal));
    if (discount && discount !== "wrong") {
      totalVal -= discount.discount_amount;
    }
    totalVal = Math.ceil(totalVal);

    if (feedingHKRoundup) {
      totalVal += feedingHKRoundupAmount;
    }
    totalVal += feedingHKMoreAmount;

    setTotalPrice(totalVal);
  }, [
    useCredits,
    subTotalPrice,
    extraPrice,
    deliverPrice,
    discount,
    feedingHKRoundupAmount,
    feedingHKRoundup,
    feedingHKMoreAmount,
  ]);

  // Address to Coordinates
  useEffect(() => {
    if (userAddress && checkAddrValid(userAddress)) {
      Geocode.fromAddress(`Hong Kong${userAddress}`).then(
        (response: any) => {
          const { lat, lng } = response.results[0].geometry.location;
          setAddrCoords([lat, lng]);
        },
        (error: any) => {
          setAddrCoords([]);
        }
      );
    } else {
      setAddrCoords([]);
    }
  }, [userAddress]);

  useEffect(() => {
    if (userAddress && addrCoords.length < 2) {
      setIsValidAddr(false);
      setInValidMsgAddr(t("checkout.addrNotRec"));
    } else {
      setIsValidAddr(true);
      if (addrCoords[0] && addrCoords[1]) {
        findLogisticZone(addrCoords[0], addrCoords[1])
          .then((res) => {
            if (res.data.logisticZone) {
              setDeliverZone(res.data.logisticZone);
            } else {
              setDeliverZone(null);
              setIsValidAddr(false);
              setInValidMsgAddr(t("checkout.addrNotRec"));
            }
          })
          .catch((error) => {
            setDeliverZone(null);
            setIsValidAddr(false);
            setInValidMsgAddr(t("checkout.addrNotRec"));
          });
      }
    }
  }, [addrCoords]);

  useEffect(() => {
    let thisDeliverPrice = 0;
    let maxCartDays = 0;
    if (cartItems) {
      maxCartDays = Math.max.apply(
        Math,
        cartItems.map((o) => o.noOfDays)
      );
    }
    if (deliverZone && deliverZone !== "free") {
      if (deliverZone.price) {
        thisDeliverPrice += deliverZone.price * maxCartDays;
      }
    }
    if (deliveryOpt && deliveryOpt.value !== "delivery7_9") {
      thisDeliverPrice += 25 * maxCartDays;
    }
    setDeliverPrice(thisDeliverPrice);
  }, [deliverZone, deliveryOpt]);

  // Set Ref for info form for scroll
  const infoRef = useRef(null);
  // Set Ref for payment form for scroll
  const paymentRef = useRef(null);
  const onProceed = () => {
    // window.alert('Proceed');
    if (formIsValid()) {
      setProceedToPayment(true);
      scrollToRef(paymentRef, 225);

      window.gtag("event", "gtm.formSubmit", {
        event_category: "page-payment",
        event_label: "proceed-to-checkout-button",
      });
    }
  };

  const updateDistrict = (e: any) => {
    if (e.value) {
      setUserDistrict({
        value: e.value,
        label: e.value,
      });
    } else {
      setUserDistrict({
        value: e,
        label: e,
      });
    }
  };
  const updateAddressOpt = (e: any) => {
    if (e) {
      setAddressOpt({
        value: e.value,
        label: e.label,
      });
    } else {
      setAddressOpt({
        value: e,
        label: e,
      });
    }
  };
  const updateDeliveryOpt = (e: any) => {
    if (e) {
      setDeliveryOpt({
        value: e.value,
        label: e.label,
      });
    } else {
      setDeliveryOpt({
        value: e,
        label: e,
      });
    }
  };
  const updateDeliveryRemarks = (e: any) => {
    let val = "";
    if (e.target.value) {
      val = e.target.value;
    } else {
      val = e;
    }
    if (typeof val !== "string") {
      val = "";
    }
    setDeliveryRemarks(val);
  };
  const updateApplePay = (value: boolean) => {
    setUseApplePay(value);
  };

  const updateUserReminderEmail = (option: boolean) => {
    setUserReminderEmail(option);
  };
  const updatePaymentMethod = (useNew: boolean) => {
    updateApplePay(false);
    setPaymentRequest(null);
    setUseNewPayment(useNew);
  };

  const updateUserBuilding = (e: any) => {
    setUserBuilding(e.target.value);
  };
  const updateUserName = (e: any) => {
    setUserName(e.target.value);
  };
  const updateUserEmail = (e: any) => {
    setUserEmail(e.target.value);
  };
  const updateUserPhone = (e: any) => {
    const phoneVal = e.target.value;
    setUserPhone(phoneVal);
    setIsValidPhone(true);
    // if (!phoneVal) {
    // 	setIsValidPhone(false);
    // 	setInValidMsgPhone(t('checkout.phoneReq'));
    // }
  };
  const updateUserAddress = (e: any) => {
    setUserAddress(e.target.value);
  };

  const formIsValid = () => {
    let isValid = true;
    setIsValidAddr(true);
    setIsValidPhone(true);
    if (!userAddress) {
      isValid = false;
      setIsValidAddr(false);
      setInValidMsgAddr(t("checkout.addrReq"));
    } else if (!checkAddrValid(userAddress)) {
      isValid = false;
      setIsValidAddr(false);
      setInValidMsgAddr(t("checkout.addr4Char"));
    } else if (addrCoords.length < 2 || !deliverZone) {
      isValid = false;
      setIsValidAddr(false);
      setInValidMsgAddr(t("checkout.addrNotRec"));
    }
    if (!checkPhone()) {
      isValid = false;
    }
    // if (!userPhone) {
    // 	isValid = false;
    // 	setIsValidPhone(false);
    // 	setInValidMsgPhone(t('checkout.phoneReq'));
    // }
    if (!isValid) {
      scrollToRefIntoView(infoRef);
    }
    return isValid;
  };

  // CC
  const stripe = useStripe();
  const elements = useElements();
  let cardNumber: StripeCardNumberElement | null = null;
  let cardExpiry: StripeCardExpiryElement | null = null;
  let cardCvc: StripeCardCvcElement | null = null;

  const stripeElementStyle = {
    base: {
      textAlign: "left",
      fontSize: "14px",
      lineHeight: "24px",
      fontFamily: "Josefin Sans, sans-serif",
    },
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!formIsValid()) {
      return;
    }

    if (discount === "wrong") {
      window.alert(discountMsg);
      return;
    }

    setCheckoutState("loading");

    console.log("handleSubmit useApplePay", useApplePay);
    // Use Apple Pay
    if (useApplePay) {
      placeOrder(null, "apple_pay");
      setCheckoutState("");
      return;
    }

    if (!useNewPayment) {
      placeOrder(null);
      return;
    }

    if (!stripe || !elements) return;

    // Use Card
    cardNumber = elements.getElement(CardNumberElement);
    cardExpiry = elements.getElement(CardExpiryElement);
    cardCvc = elements.getElement(CardCvcElement);

    if (!cardNumber) {
      // setCheckoutState('success'); // work around to by pass the random error of credit card
      window.alert("Error initializing Stripe");
      setCheckoutState("");
      return;
    }
    stripe.createToken(cardNumber).then((response) => {
      const { error, token: stripeToken } = response;
      if (error) {
        window.alert(error.message);
        setCheckoutState("");
      }
      if (stripeToken) {
        UserService.addPaymentMethod(stripeToken.id)
          .then((res) => {
            setPaymentMethodId(res.data.payment_method._id);
            placeOrder(res.data.payment_method._id);
          })
          .catch((addCCError) => {
            // window.alert(
            //   `There is an error, please try again later. ${addCCError}`
            // );
            setCheckoutState("");
          });
      }
    });
  };

  // Handle Order Success
  const handleOrderSuccess = (orderInfo: any, firstOrder?: boolean) => {
    if (orderInfo && cartItems) {
      const items: any = [];
      cartItems.forEach((item) => {
        const itemGoal = item.goalId;
        const itemGoalName = itemGoal.name;
        const itemBrandName = itemGoal.cuisineId.name;
        const itemName = `${itemBrandName}-${itemGoalName}`;
        const itemQuantity = item.quantity;
        const itemPrice = item._price;
        const itemExtras = item.extras;
        let itemExtraPrice = 0;
        if (extras && itemExtras && itemExtras.length > 0) {
          itemExtras.forEach((extraId: string) => {
            const extraEl = extras.filter((res: any) => res._id === extraId);
            const extraCartPrice = extraEl.map((ele: any) => ele.amount);
            itemExtraPrice += extraCartPrice * item.noOfDays;
          });
        }
        const itemTotalPrice = Math.ceil(itemPrice + itemExtraPrice);
        items.push({
          id: item._id,
          name: itemName.replace(/ /g, "").toLowerCase(),
          brand: itemGoalName,
          category: itemBrandName,
          quantity: itemQuantity,
          price: itemTotalPrice,
        });
      });
      const purchaseInfo = {
        transaction_id: orderInfo?._id,
        affiliation: "NOSH",
        value: orderInfo?.total_amt,
        currency: "HKD",
        items,
      };
      window.gtag("event", "purchase", purchaseInfo);
      window.fbq("track", "Purchase", {
        currency: "HKD",
        value: orderInfo?.total_amt,
      });
    }

    UserService.mailchimpCart(cartItems);

    history.push(`/Checkout/Success${firstOrder ? "?firstOrder=true" : ""}`);
  };

  // Place Order or Get Order Info for Apple pay
  const placeOrder = (id?: any, mode?: any, paymentIntent?: any) => {
    console.log("placeOrder", mode);
    let deliverZoneId = "";
    if (deliverZone && deliverZone !== "free") {
      deliverZoneId = deliverZone._id;
    }

    // Check Discount
    let discount_code = useDiscount;
    let referral_code = "";
    if (discount && discount !== "wrong") {
      if (discount?.referredUser) {
        discount_code = "";
        referral_code = useDiscount;
      }
    }
    if (referral_code === null) {
      referral_code = "";
    }
    if (discount_code === null) {
      discount_code = "";
    }

    let data;
    const donation =
      feedingHKMoreAmount + (feedingHKRoundup ? feedingHKRoundupAmount : 0);

    if (mode && mode === "apple_pay") {
      // Submit Order Apple Pay
      data = {
        memberName: userName,
        email: userEmail,
        phone: userPhone,
        discount_code,
        referral_code,
        address: userAddress,
        district: userDistrict.value,
        delivery_remarks: deliveryRemarks,
        building: userBuilding,
        deliveryTimeSelected: [deliveryOpt.value],
        credits: useCredits,
        logistic_zone_id: deliverZoneId,
        coordinates: {
          lat: addrCoords[0],
          lng: addrCoords[1],
        },
        apple_pay: true,
        reminder: userReminderEmail,
        donation,
      };
      console.log(mode, data);
      // createApplePayOrder
      OrderService.createApplePayOrder(data)
        .then((res) => {
          console.log("createApplePayOrder", res.data);
          setApplePayInfo(res.data);
        })
        .catch((addCCError) => {
          // let errMSg = `There is an error, please try again later. ${addCCError}`;
          // if (addCCError.response) {
          //   if (addCCError.response.data) {
          //     if (addCCError.response.data.error) {
          //       errMSg = addCCError.response.data.error.message;
          //     }
          //   }
          // }
          // window.alert(errMSg);
          setCheckoutState("");
        });
      return false;
    }

    // Submit Order Using Card
    data = {
      memberName: userName,
      email: userEmail,
      phone: userPhone,
      discount_code,
      referral_code,
      payment_method_id: id || paymentMethodId,
      address: userAddress,
      district: userDistrict.value,
      delivery_remarks: deliveryRemarks,
      building: userBuilding,
      deliveryTimeSelected: [deliveryOpt.value],
      credits: useCredits,
      logistic_zone_id: deliverZoneId,
      coordinates: {
        lat: addrCoords[0],
        lng: addrCoords[1],
      },
      apple_pay: false,
      reminder: userReminderEmail,
      donation,
    };

    if (!referral_code || referral_code === "") {
      delete data.referral_code;
    }
    OrderService.placeOrder(data)
      .then((res) => {
        const orderInfo = res.data.order;
        const firstOrder = res?.data?.firstOrder;
        handleOrderSuccess(orderInfo, firstOrder);
      })
      .catch((addCCError) => {
        // let errMSg = `There is an error, please try again later. ${addCCError}`;
        // if (addCCError.response) {
        //   if (addCCError.response.data) {
        //     if (addCCError.response.data.error) {
        //       errMSg = addCCError.response.data.error.message;
        //     }
        //   }
        // }
        // window.alert(errMSg);
        setCheckoutState("");
      });
  };

  // Remove single cart
  const removeCart = (id: number) => {
    setCheckoutState("loading");
    CartService.removeItem(id)
      .then((res) => {
        // let dataCart = res.data.cart;
        // Extra call to get the cart info as res of removeItem don't include the goal info
        // Should simplify once api add back goal info
        reloadCart();
        setCheckoutState("");
      })
      .catch((error) => {
        window.alert(error);
      });
  };

  const reloadCart = () => {
    fetchCart()
      .then((fetchCartRes) => {
        const dataCart = fetchCartRes.data.cart;
        if (dataCart.items && dataCart.items.length > 0) {
          setCart({
            ...dataCart,
            extra_amount: fetchCartRes.data.extra_amount,
            amount: fetchCartRes.data.amount,
            add_on_amount: fetchCartRes.data?.add_on_amount || 0,
          });
        } else {
          setCart(null);
        }
        setCheckoutState("");
      })
      .catch((error) => {
        setCart(null);
      });
  };

  // Prices
  // Subtoal
  const calculatePrice = (meal: CartItem) => {
    const {
      breakfast,
      lunch,
      dinner,
      noOfDays,
      goalId: { price_breakfast, price_dinner, price_lunch },
    } = meal;

    const processedNoDays = noOfDays.toString(10) as MealTypeIndex;

    let price = 0;

    if (breakfast) price += price_breakfast[processedNoDays];
    if (lunch) price += price_lunch[processedNoDays];
    if (dinner) price += price_dinner[processedNoDays];
    return price;
  };

  // Feeding HK
  const addFeedingHKMoreAmount = (e: number) => {
    let thisVal = feedingHKMoreAmount + e;
    if (thisVal < 0 || typeof thisVal === "object") {
      thisVal = 0;
    }
    setFeedingMoreHKAmount(thisVal);
  };
  const updateFeedingHKMoreAmount = (e: any) => {
    let thisVal = e;
    if (e.target.value) {
      thisVal = e.target.value;
    } else {
      thisVal = 0;
    }
    thisVal = Number(String(thisVal).replace(/^0+/, ""));
    if (thisVal < 0) {
      thisVal = 0;
    }
    setFeedingMoreHKAmount(thisVal);
  };

  // Nosh Credit
  const updateUseCredits = (e: any) => {
    let thisVal = e;
    if (e.target.value) {
      thisVal = e.target.value;
    }
    thisVal = String(thisVal).replace(/^0+/, "");
    setUseCredits(thisVal);
  };

  // Use Discount
  const updateUseDiscount = (e: any) => {
    let val = "";
    if (e.target.value) {
      val = e.target.value;
    } else {
      val = e;
    }
    if (typeof val !== "string") {
      val = "";
    }
    setUseDiscount(val);
  };

  // ### applepay
  // For testing
  // Apple pay is temporarily hidden for refactoring
  const [testMode, setTestMode] = useState<boolean>(false);
  const query = useQuery();
  useEffect(() => {
    const mode = query.get("mode");
    if (
      mode === "testapplepay" &&
      AppConfig.API_URL &&
      AppConfig?.API_URL?.indexOf("uat") > -1
    ) {
      setTestMode(true);
    }
  }, [query]);

  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [applePayInfo, setApplePayInfo] = useState<any>(null);

  // Don't create paymentIntent as Apple pay is temporarily hidden
  // useEffect(() => {
  // 	if (!totalPrice || !proceedToPayment) return;
  // 	const orderInfo = placeOrder(null, 'apple_pay_get_orderinfo');
  // 	console.log('testMode', testMode);
  // 	console.log('orderInfo', orderInfo);
  // 	OrderService.createPaymentIntent(orderInfo).then((res) => {
  // 		console.log('createPaymentIntent', res);
  // 		// const thisScretKey = res.data.secret_key;
  // 		// const thisPaymentIntent = res.data.paymentIntent;
  // 		setApplePayInfo(res.data);
  // 	}).catch((paymentIntentErr) => {
  // 		const errMSg = `There is an error creating payment Intent. ${paymentIntentErr}`;
  // 		window.alert(errMSg);
  // 	});
  // }, [totalPrice, proceedToPayment]);

  useEffect(() => {
    if (stripe && applePayInfo) {
      setPaymentRequest(null);
      setCheckoutState("loading");

      if (applePayInfo?.order?.total_amt) {
        const totalAmount = parseInt(applePayInfo.order.total_amt, 10) * 100;
        const testAmount = parseInt(String(totalAmount / 100), 10);
        const pr = stripe.paymentRequest({
          country: "HK",
          currency: "hkd",
          total: {
            label: "Nosh Meal Plan",
            amount: testMode ? testAmount : totalAmount,
          },
        });

        // Check the availability of the Payment Request API.
        console.log("createing pr");
        pr.canMakePayment().then((result) => {
          if (result) {
            console.log("canMakePayment");
            setPaymentRequest(pr);
          } else {
            window.alert(
              "Sorry your device doesn't support Apple Pay. Please try other payment method."
            );
          }
          setCheckoutState("");
        });
      } else {
        window.alert(
          "Sorry your device doesn't support Apple Pay. Please try other payment method."
        );
      }
    }
  }, [stripe, applePayInfo]);

  useEffect(() => {
    console.log("paymentRequest", paymentRequest);
  }, [paymentRequest]);

  useEffect(() => {
    if (!paymentRequest || !stripe || !applePayInfo) {
      return;
    }
    paymentRequest.on("paymentmethod", async (ev: any) => {
      setCheckoutState("loading");
      // Confirm the PaymentIntent without handling potential next actions (yet).

      const clientSecret = applePayInfo.secret_key;
      // console.log('clientSecret', clientSecret);
      const { error: confirmError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete("fail");
        // console.log('paymentRequest#fail');
        window.alert(
          "There is an error in processing the Apple Pay. Please try again or use other payment method."
        );
        setCheckoutState("");
        updatePaymentMethod(true);
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        // console.log('paymentRequest #success test', paymentIntent);
        ev.complete("success");
        // placeOrder(null, 'apple_pay', paymentIntent);

        handleOrderSuccess(applePayInfo.order);
        setCheckoutState("");
        // // Let Stripe.js handle the rest of the payment flow.
        // const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret);
        // if (error) {
        // // The payment failed -- ask your customer for a new payment method.
        // 	console.log('paymentRequest#error', error);
        // 	console.log('paymentRequest#error paymentIntent', paymentIntent);
        // } else {
        // // The payment has succeeded.
        // 	console.log('paymentRequest #success');
        // }
      }
    });
  }, [paymentRequest, applePayInfo]);

  // Get list of Add ons
  const loadAddOns = (dummy?: any) => {
    fetchAddOns(false)
      .then((res: any) => {
        const dataAddOns = res.data.addons;
        setAddOns(dataAddOns);
      })
      .catch((err: any) => {
        let errMSg = "";
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.error) {
              errMSg = err.response.data.error.message;
            }
          }
        }
        window.alert(`There is an error loading add ons: ${errMSg}`);
      });
    if (dummy) {
      setCartAddOns(dummy);
    }
  };

  useEffect(() => {
    if (!token) return;
    // Fetch ADD ONS
    setTimeout(() => {
      let addOnsData = dummyAddons;
      if (cart && cart.addOns) {
        addOnsData = {
          addOns: {
            ...cart.addOns,
          },
        };
      }
      loadAddOns(addOnsData);
    }, 150);
  }, [token, cart]);

  const editAddOns = () => {
    setAddOnPopupVisible(true);
  };

  const removeAddOns = () => {
    submitAddOns({})
      .then((res: any) => {
        reloadCart();
        setCheckoutState("");
      })
      .catch((err: any) => {
        let errMSg = "";
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.error) {
              errMSg = err.response.data.error.message;
            }
          }
        }
        window.alert(`There is an error removing add ons: ${errMSg}`);
      });
  };

  useEffect(() => {
    if (!cartAddOns || !cartAddOns.addOns || !addOns) return;
    let thisStartDate = "";
    let thisEndDate = "";
    const adjList: any = {};
    const thisDateList: any = [];
    Object.entries(cartAddOns.addOns).forEach(([key, value]: any) => {
      thisDateList.push(key);
      if (thisStartDate) {
        const isEarlier = moment(thisStartDate, dateFormat)
          .utcOffset(8)
          .isAfter(moment(key, dateFormat).utcOffset(8));
        if (isEarlier) thisStartDate = key;
      } else {
        thisStartDate = key;
      }
      if (thisEndDate) {
        const isLater = moment(key, dateFormat)
          .utcOffset(8)
          .isAfter(moment(thisEndDate, dateFormat).utcOffset(8));
        if (isLater) thisEndDate = key;
      } else {
        thisEndDate = key;
      }
      value.forEach((addOnId: any) => {
        const tarItemInfo = addOns.filter(
          (addOn: any) => addOn._id === addOnId
        );
        if (adjList[addOnId]) {
          adjList[addOnId] = {
            num: adjList[addOnId].num + 1,
            info: tarItemInfo[0],
          };
        } else {
          adjList[addOnId] = {
            num: 1,
            info: tarItemInfo[0],
          };
        }
      });
    });
    setAddOnDatesList(thisDateList);
    setAdjustedCartAddOns(adjList);
    if (thisStartDate && thisEndDate) {
      const formattedStartDate = moment(thisStartDate, dateFormat)
        .utcOffset(8)
        .format("MMMM, D, YYYY (ddd)");
      const formattedEndDate = moment(thisEndDate, dateFormat)
        .utcOffset(8)
        .format("MMMM, D, YYYY (ddd)");
      setAddOnDates(` ${formattedStartDate} - ${formattedEndDate}`);
    }
    // setAddOnDates
  }, [cartAddOns, addOns]);

  const pureDonation = () => {
    let donation = 0;
    if (feedingHKRoundup) {
      donation += feedingHKRoundupAmount;
    }
    donation += feedingHKMoreAmount;
    return donation;
  };

  if (!stripe || !elements) {
    return <div />;
  }

  return (
    <Layout>
      <AddOnPopup
        data={cartAddOns ? cartAddOns.addOns || {} : null}
        token={token}
        datesList={addOnDatesList}
        visible={AddOnPopupVisible}
        onClosePopup={() => {
          setAddOnPopupVisible(false);
        }}
        onFinishAddOn={() => {
          setAddOnPopupVisible(false);
          reloadCart();
          setCheckoutState("");
        }}
        isEdit
      />
      <div
        className={`checkout-layout ${paymentRequest && useApplePay ? "mod-apple-pay" : ""
          }`}
      >
        <>
          <div
            className={
              checkoutState === "loading"
                ? "checkout-layout-loading"
                : "checkout-layout-loading is-hidden"
            }
          >
            <LoadingScreen />
          </div>
          {latestCartItemInfo ? (
            <div className="checkout-infobox-steps">
              <Steps
                step={5}
                links={[
                  "/MealPlans/1",
                  `/MealPlans/${latestCartItemInfo.brandName}`,
                  `/MealPlans/package/${latestCartItemInfo.brandName}/${latestCartItemInfo.goalName}`,
                  `/MealPlans/details/${latestCartItemInfo.brandName}/${latestCartItemInfo.goalName}/${latestCartItemInfo.noOfDays}`,
                  "",
                ]}
              />
            </div>
          ) : null}
          <div className="checkout-infobox">
            <div className="checkout-infobox-head">
              <h2 className="checkout-head-title">{t("checkout.myCart")}</h2>
            </div>
            <div className="checkout-infobox-content">
              <div className="checkout-cart">
                {cartItems &&
                  cartItems.map((dateVal: any, index: number) => {
                    // console.log('dateVal', dateVal);
                    var consultationFee = 0;
                    if (dateVal.goalId.cuisineId.name == 'NOSH Life') {
                      if (dateVal.noOfDays == 20) {
                        consultationFee = 650;
                      } else if (dateVal.noOfDays == 30) {
                        consultationFee = 800;
                      } else {
                        consultationFee = 950;
                      }
                    }
                    let cartPrice = (dateVal._price + consultationFee) * dateVal.quantity;
                    let date = moment(dateVal.startDate)
                      .utcOffset(8)
                      .format("MMMM, D, YYYY (ddd)");
                    if (dateVal.endDate) {
                      date += ` - ${moment(dateVal.endDate)
                        .utcOffset(8)
                        .format("MMMM, D, YYYY (ddd)")}`;
                    }
                    return (
                      <div key={dateVal._id} className="checkout-cart-item">
                        <div className="checkout-cart-head">
                          <h3 className="checkout-title">
                            {dateVal.goalId.cuisineId.name} -{" "}
                            {dateVal.goalId.name}
                          </h3>
                        </div>
                        <div className="checkout-cart-info">
                          <h4 className="checkout-subtitle">
                            {t("checkout.date")}
                          </h4>
                          <p className="checkout-desc">{date}</p>
                        </div>
                        {/* <div className="checkout-cart-info">
												<h4 className="checkout-subtitle">{t('checkout.startDate')}</h4>
												<p className="checkout-desc">{moment(dateVal.startDate).format('MMMM, D, YYYY (ddd)')}</p>
											</div>
											{dateVal.endDate
												? (
													<div className="checkout-cart-info">
														<h4 className="checkout-subtitle">{t('checkout.endDate')}</h4>
														<p className="checkout-desc">{moment(dateVal.endDate).format('MMMM, D, YYYY (ddd)')}</p>
													</div>
												)
												: ''} */}
                        <div className="checkout-cart-infos">
                          <div className="checkout-cart-info">
                            <h4 className="checkout-subtitle">
                              {t("checkout.package")}
                            </h4>
                            <p className="checkout-desc">
                              {dateVal.noOfDays} {t("days")}
                            </p>
                          </div>
                          <div className="checkout-cart-info">
                            <h4 className="checkout-subtitle">
                              {t("checkout.quantity")}
                            </h4>
                            <p className="checkout-desc">{dateVal.quantity}</p>
                          </div>
                        </div>
                        <div className="checkout-cart-info">
                          <h4 className="checkout-subtitle">
                            {t("checkout.mealsPerDay")}
                          </h4>
                          <div className="checkout-checkshows">
                            <div className="checkout-checkshow">
                              {dateVal.breakfast ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="2x"
                                  className="checkout-checkshow-check"
                                />
                              ) : (
                                ""
                              )}
                              <span className="checkout-checkshow-label">
                                {t("breakfast")}
                              </span>
                            </div>

                            <div className="checkout-checkshow">
                              {dateVal.lunch ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="2x"
                                  className="checkout-checkshow-check"
                                />
                              ) : (
                                ""
                              )}
                              <span className="checkout-checkshow-label">
                                {t("lunch")}
                              </span>
                            </div>
                            <div className="checkout-checkshow">
                              {dateVal.dinner ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="2x"
                                  className="checkout-checkshow-check"
                                />
                              ) : (
                                ""
                              )}
                              <span className="checkout-checkshow-label">
                                {t("dinner")}
                              </span>
                            </div>
                            {dateVal.snacks && dateVal.goalId.hasSnack ? (
                              <div className="checkout-free-snacks">
                                {t("mealPlan.freeSnackIncluded")}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {extras &&
                          dateVal.extras &&
                          dateVal.extras.length > 0 ? (
                          <div className="checkout-cart-info">
                            <h4 className="checkout-subtitle">
                              {t("checkout.specialRequest")}
                            </h4>
                            <div className="checkout-extras">
                              {extras &&
                                dateVal.extras &&
                                dateVal.extras.map((extraId: any) => {
                                  const extraEl = extras.filter(
                                    (res: any) => res._id === extraId
                                  );
                                  const extraName = extraEl.map(
                                    (ele: any) => ele.name
                                  );
                                  const extraCartPrice = extraEl.map(
                                    (ele: any) => ele.amount
                                  );
                                  cartPrice +=
                                    extraCartPrice *
                                    dateVal.noOfDays *
                                    dateVal.quantity;
                                  return (
                                    <div
                                      key={extraId}
                                      className="checkout-extra"
                                    >
                                      {extraName.indexOf(
                                        "###breakfast_discount###"
                                      ) > -1 ? (
                                        <span>
                                          {t("checkout.breakfastDiscount1")}
                                          {Math.abs(extraCartPrice)}{" "}
                                          {t("checkout.breakfastDiscount2")}
                                        </span>
                                      ) : (
                                        extraName
                                      )}
                                      <span className="checkout-extra-comma">
                                        ,
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="checkout-cart-sum">
                          <div className="checkout-cart-price">
                            HK$
                            {Math.ceil(cartPrice)}
                          </div>
                          <div className="checkout-cart-remove">
                            <button
                              onClick={() => removeCart(index + 1)}
                              type="button"
                              className="checkout-cart-remove-btn"
                            >
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                size="2x"
                                className="checkout-cart-remove-icon"
                              />
                              <span className="checkout-cart-remove-label">
                                {t("checkout.remove")}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {cartAddOns &&
                  adjustedCartAddOns &&
                  Object.keys(adjustedCartAddOns).length > 0 &&
                  (() => (
                    <div className="checkout-cart-item">
                      <div className="checkout-cart-head">
                        <h3 className="checkout-title">Add-On Products</h3>
                      </div>
                      <div className="checkout-cart-info">
                        <h4 className="checkout-subtitle">
                          {t("checkout.date")}
                        </h4>
                        <p className="checkout-desc">{addOnDates}</p>
                      </div>
                      <div className="checkout-cart-addon">
                        <table className="checkout-cart-addon-table">
                          <tr>
                            <th>
                              <h4 className="checkout-subtitle">
                                Selected Items
                              </h4>
                            </th>
                            <th>
                              <h4 className="checkout-subtitle">Quantity</h4>
                            </th>
                          </tr>
                          {adjustedCartAddOns &&
                            Object.entries(adjustedCartAddOns).map(
                              ([key]: any) => {
                                const addOnVal = adjustedCartAddOns[key];
                                if (!addOnVal.info) return null;
                                return (
                                  <tr key={addOnVal.info._id}>
                                    <td>{addOnVal.info.name}</td>
                                    <td>{addOnVal.num}</td>
                                  </tr>
                                );
                              }
                            )}
                        </table>
                      </div>

                      <div className="checkout-cart-sum">
                        <div className="checkout-cart-price">
                          HK$
                          {Math.ceil(cart?.add_on_amount || 0)}
                        </div>
                        <div className="checkout-cart-addon-control">
                          <button
                            onClick={() => {
                              editAddOns();
                            }}
                            type="button"
                            className="checkout-cart-addon-btn"
                          >
                            <span className="checkout-cart-addon-btn-label">
                              Edit
                            </span>
                          </button>
                          <button
                            onClick={() => {
                              removeAddOns();
                            }}
                            type="button"
                            className="checkout-cart-remove-btn"
                          >
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              size="2x"
                              className="checkout-cart-remove-icon"
                            />
                            <span className="checkout-cart-remove-label">
                              {t("checkout.remove")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))()}
              </div>
            </div>
          </div>

          <div className="checkout-infobox">
            <div className="checkout-infobox-head">
              <h2 className="checkout-head-title">{t("checkout.yourInfo")}</h2>
            </div>
            <div className="checkout-infobox-content">
              <div ref={infoRef} className="checkout-form">
                <div className="checkout-form-row">
                  <div className="checkout-form-col">
                    <div className="checkout-form-label">
                      {t("myAccount.email")}
                    </div>
                    <div className="checkout-form-field">
                      <input
                        type="input"
                        defaultValue={user.email || ""}
                        className="checkout-form-input"
                        onChange={(e) => updateUserEmail(e)}
                      />
                    </div>
                    <div className="checkout-form-remark">
                      {t("checkout.sendMsg")}
                    </div>
                  </div>
                </div>
                <div className="checkout-form-row">
                  <div className="checkout-form-col">
                    <div className="checkout-form-label">
                      {t("myAccount.name")}
                    </div>
                    <div className="checkout-form-field">
                      <input
                        name="memberName"
                        type="input"
                        defaultValue={user.memberName || ""}
                        className="checkout-form-input"
                        onChange={(e) => updateUserName(e)}
                      />
                    </div>
                  </div>
                  <div className="checkout-form-col">
                    <div className="checkout-form-label">
                      {t("checkout.contactNum")}
                    </div>
                    <div className="checkout-form-field">
                      <input
                        name="phone"
                        type="number"
                        defaultValue={user.phone || ""}
                        className={`checkout-form-input ${isValidPhone ? "" : "is-err"
                          }`}
                        maxLength={9}
                        onChange={(e) => updateUserPhone(e)}
                        onKeyUp={(e) => updateUserPhone(e)}
                      />
                    </div>
                    {!isValidPhone ? (
                      <div className="checkout-form-errmsg">
                        {inValidMsgPhone}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="checkout-form-row">
                  <div className="checkout-form-col">
                    <div className="checkout-form-label">
                      {t("checkout.pickAddr")}
                    </div>
                    <div className="checkout-form-field">
                      <Select
                        className="checkout-form-select"
                        value={addressOpt}
                        options={addressOpts}
                        onChange={updateAddressOpt}
                      />
                    </div>
                  </div>
                </div>
                <div className="checkout-form-row checkout-addrrow">
                  <div className="checkout-form-col mod-full checkout-addrrow-building">
                    <div className="checkout-form-label">
                      {t("myAccount.building")}
                    </div>
                    <div className="checkout-form-field">
                      <input
                        name="userBuilding"
                        className="checkout-form-input"
                        value={userBuilding}
                        onChange={(e) => updateUserBuilding(e)}
                      />
                    </div>
                  </div>

                  <div className="checkout-form-col mod-full checkout-addrrow-addr">
                    <div className="checkout-form-label">
                      {t("myAccount.address")}
                    </div>
                    <div className="checkout-form-field">
                      <input
                        className={`checkout-form-input ${isValidAddr ? "" : "is-err"
                          }`}
                        name="userAddress"
                        value={userAddress}
                        onChange={(e) => updateUserAddress(e)}
                        onKeyUp={(e) => updateUserAddress(e)}
                      />
                    </div>
                    {!isValidAddr ? (
                      <div className="checkout-form-errmsg">
                        {inValidMsgAddr}
                      </div>
                    ) : (
                      ""
                    )}
                    {deliverZone && deliverZone !== "free" ? (
                      <div className="checkout-form-delivery-msg">
                        {t("checkout.deliveryDesc1")}{" "}
                        <strong>{deliverZone.name}</strong>{" "}
                        {t("checkout.deliveryDesc2")}{" "}
                        <strong>
                          HK$
                          {deliverZone.price}
                        </strong>{" "}
                        {t("checkout.deliveryDesc3")}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="checkout-form-col mod-full checkout-addrrow-district">
                    <div className="checkout-form-label">
                      {t("myAccount.district")}
                    </div>
                    <div className="checkout-form-field">
                      <Select
                        className="checkout-form-select"
                        value={userDistrict}
                        options={districtOpts}
                        onChange={updateDistrict}
                      />
                    </div>
                  </div>
                </div>
                <div className="checkout-form-row">
                  <div className="checkout-form-col">
                    <div className="checkout-form-label">
                      {t("checkout.specialNotes")}
                    </div>
                    <div className="checkout-form-field">
                      <textarea
                        name="deliveryRemarks"
                        className="checkout-form-textarea"
                        value={deliveryRemarks}
                        onChange={(e) => updateDeliveryRemarks(e)}
                      />
                    </div>
                  </div>
                  <div className="checkout-form-col">
                    <div className="checkout-form-label">
                      {t("checkout.chooseDeliveryTime")}
                    </div>
                    <div className="checkout-form-field">
                      <Select
                        className="checkout-form-select"
                        value={deliveryOpt}
                        options={deliveryOpts}
                        onChange={updateDeliveryOpt}
                      />
                    </div>
                    <div className="checkout-form-remark">
                      {t("checkout.chooseDeliveryTimeCharge")}
                    </div>

                    <div className="reminder-email">
                      <input
                        className="reminder-email-input"
                        id="checkout-reminder-email"
                        type="checkbox"
                        name="checkout-reminder-email"
                        value="1"
                        checked={userReminderEmail}
                        onChange={() =>
                          updateUserReminderEmail(!userReminderEmail)
                        }
                      />
                      <label
                        className="reminder-email-label"
                        htmlFor="checkout-reminder-email"
                      >
                        <span className="reminder-email-bar">
                          <span className="reminder-email-bar-pt" />
                        </span>
                        <span className="reminder-email-txt">
                          {t("checkout.reminderEmail")}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="checkout-money-back">
            <h3 className="checkout-money-back-title">
              {t("checkout.moneyBackTitle")}
            </h3>
            <p className="checkout-money-back-desc">
              {t("checkout.moneyBackDesc")}
            </p>
          </div>

          {!proceedToPayment ? (
            <div className="checkout-control">
              <button
                type="button"
                className="checkout-btn"
                onClick={onProceed}
              >
                {t("checkout.proceedToCheckout")}
              </button>
            </div>
          ) : (
            ""
          )}
        </>
        <div ref={paymentRef} />
        <form
          className={
            proceedToPayment ? "checkout-payment" : "checkout-payment is-hidden"
          }
        >
          <div className="checkout-infobox">
            <div className="checkout-infobox-head">
              <h2 className="checkout-head-title">{t("checkout.payment")}</h2>
            </div>
            <div className="checkout-infobox-content">
              <div className="checkout-payment">
                <div className="checkout-payment-summary">
                  <div className="checkout-payment-subtotal">
                    {t("checkout.subtotal")}:
                    <span className="checkout-payment-figure">
                      ${subTotalPrice}
                    </span>
                  </div>
                  <div className="checkout-payment-extra">
                    <span className="checkout-payment-label">
                      <span className="checkout-payment-label-fragment">
                        {t("checkout.extraCharge")}
                      </span>
                      {" / "}
                      <span className="checkout-payment-label-fragment">
                        {t("checkout.breakfastSavings")}:
                      </span>
                    </span>
                    <span className="checkout-payment-figure">
                      ${extraPrice}
                    </span>
                  </div>
                  <div className="checkout-payment-extra">
                    <span className="checkout-payment-label">
                      {t("checkout.deliveryPrice")}:
                    </span>
                    <span className="checkout-payment-figure">
                      ${deliverPrice}
                    </span>
                  </div>
                  <div className="checkout-payment-break" />
                  <div className="checkout-payment-credits">
                    <div className="checkout-payment-credits-field">
                      <div className="checkout-payment-credits-label">
                        {t("checkout.discountCode")}{" "}
                      </div>
                      <div className="checkout-payment-credits-input">
                        <input
                          className="checkout-form-input"
                          type="text"
                          value={useDiscount}
                          onChange={(e) => updateUseDiscount(e)}
                        />
                      </div>
                    </div>
                    {discount && discount !== "wrong" ? (
                      <div className="checkout-payment-credits-msg">
                        ({t("checkout.youCanSave")} HK${" "}
                        {Math.abs(discount.discount_amount) >
                          totalPriceBeforeDiscount
                          ? totalPriceBeforeDiscount
                          : Math.abs(discount.discount_amount)}{" "}
                        {t("checkout.forThisDiscount")})
                      </div>
                    ) : (
                      ""
                    )}
                    {useDiscount && discount && discount === "wrong" ? (
                      <div className="checkout-payment-credits-warning">
                        {discountMsg}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="checkout-payment-credits">
                    <div className="checkout-payment-credits-field">
                      <div className="checkout-payment-credits-label">
                        {t("checkout.noshCredits")}:{" "}
                        <div className="checkout-payment-credits-msg">
                          ({user.credits} {t("checkout.credits")})
                        </div>
                      </div>
                      <div className="checkout-payment-credits-input">
                        <input
                          className="checkout-form-input"
                          type="number"
                          value={useCredits}
                          onChange={(e) => updateUseCredits(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="checkout-payment-break" />
                  <div className="feeding-hk">
                    <div className="feeding-hk-intro">
                      <div className="feeding-hk-intro-pic">
                        <img src="/img/img-feeding-hk.png" alt="" />
                      </div>
                      <div className="feeding-hk-intro-text">
                        <h3 className="feeding-hk-title">
                          {t("checkout.donateFeedingHKTitle")}
                        </h3>
                        <p className="feeding-hk-desc">
                          {t("checkout.donateFeedingHKDesc")}
                        </p>
                      </div>
                    </div>
                    <div className="feeding-hk-row">
                      <div className="feeding-hk-label">
                        {t("checkout.donateFeedingRoundUp")}
                      </div>
                      <div className="feeding-hk-control">
                        $ {feedingHKRoundupAmount}
                        <div className="feeding-hk-roundup">
                          <input
                            className="feeding-hk-roundup-input"
                            id="checkout-feeding-hk-roundup"
                            type="checkbox"
                            name="checkout-feeding-hk-roundup"
                            value="1"
                            checked={feedingHKRoundup}
                            onChange={() =>
                              setFeedingHKRoundup(!feedingHKRoundup)
                            }
                          />
                          <label
                            className="feeding-hk-roundup-label"
                            htmlFor="checkout-feeding-hk-roundup"
                          >
                            <span className="feeding-hk-roundup-bar">
                              <span className="feeding-hk-roundup-bar-pt" />
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="feeding-hk-row">
                      <div className="feeding-hk-label">
                        {t("checkout.donateFeedingGiveMore")}
                      </div>
                      <div className="feeding-hk-control">
                        <div className="feeding-hk-more">
                          <button
                            type="button"
                            className="feeding-hk-more-minus"
                            onClick={() => addFeedingHKMoreAmount(-1)}
                          >
                            -
                          </button>
                          <div className="feeding-hk-more-control">
                            $
                            <input
                              type="number"
                              value={feedingHKMoreAmount}
                              onChange={(e) => updateFeedingHKMoreAmount(e)}
                              className="feeding-hk-more-input"
                            />
                          </div>
                          <button
                            type="button"
                            className="feeding-hk-more-plus"
                            onClick={() => addFeedingHKMoreAmount(1)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkout-payment-break" />
                  <div className="checkout-payment-total">
                    {t("checkout.total")}:
                    <span className="checkout-payment-figure">
                      ${totalPrice > 0 ? totalPrice : pureDonation()}
                    </span>
                  </div>
                </div>
                <div className="checkout-payment-form">
                  {paymentMethodOpts || hasApplePaySupport() ? (
                    <div className="checkout-form-row">
                      <div className="checkout-form-col">
                        {paymentMethodOpts ? (
                          <div className="checkout-form-radio">
                            <div className="radio-picker">
                              <input
                                id="cc-opts-1"
                                type="radio"
                                name="paymentMethod"
                                value="0"
                                checked={!useNewPayment && !useApplePay}
                                onChange={() => updatePaymentMethod(false)}
                              />
                              <label
                                className="radio-picker-label"
                                htmlFor="cc-opts-1"
                              >
                                <span className="radio-picker-txt">
                                  Use saved credit card (
                                  {paymentMethodOpts &&
                                    `xxxx-xxxx-xxxx-${getLatestPayment(paymentMethodOpts).source
                                      .last4
                                    }`}
                                  )
                                </span>
                              </label>
                            </div>
                          </div>
                        ) : null}
                        {hasApplePaySupport() ? (
                          <div className="checkout-form-radio">
                            <div className="radio-picker">
                              <input
                                id="cc-opts-applepay"
                                type="radio"
                                name="paymentMethod"
                                value="applepay"
                                checked={useApplePay}
                                onChange={() => updateApplePay(true)}
                              />
                              <label
                                className="radio-picker-label"
                                htmlFor="cc-opts-applepay"
                              >
                                <span className="radio-picker-txt">
                                  Use Apple Pay
                                </span>
                              </label>
                            </div>
                          </div>
                        ) : null}
                        <div className="checkout-form-radio">
                          <div className="radio-picker">
                            <input
                              id="cc-opts-2"
                              type="radio"
                              name="paymentMethod"
                              value="1"
                              checked={useNewPayment && !useApplePay}
                              onChange={() => updatePaymentMethod(true)}
                            />
                            <label
                              className="radio-picker-label"
                              htmlFor="cc-opts-2"
                            >
                              <span className="radio-picker-txt">
                                Use a new credit card
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className={`checkout-cc ${useNewPayment ? "" : "is-disabled"
                      } ${useApplePay ? "is-hidden" : ""}`}
                  >
                    <div className="checkout-cc-icons">
                      <img
                        className="checkout-cc-icon"
                        src="/img/icon-credit-card.png"
                        alt=""
                      />
                    </div>
                    <div className="checkout-form-row">
                      <div className="checkout-form-col">
                        <div className="checkout-form-label">
                          {t("myAccount.creditCardNo")}:
                        </div>
                        <div className="checkout-form-field">
                          <div className="checkout-cc-num">
                            <CardNumberElement
                              className="stripe-element-container"
                              id="card-number-element"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout-form-row">
                      <div className="checkout-form-col">
                        <div className="checkout-form-label">
                          {t("myAccount.expiryDate")}:
                        </div>
                        <div className="checkout-form-field">
                          <div className="checkout-cc-expiry">
                            <CardExpiryElement
                              className="stripe-element-container"
                              id="card-expiry-element"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout-form-row">
                      <div className="checkout-form-col">
                        <div className="checkout-form-label">
                          {t("myAccount.cvc")}:
                        </div>
                        <div className="checkout-form-field">
                          <div className="checkout-cc-cvc">
                            <CardCvcElement
                              className="stripe-element-container"
                              id="card-cvc-element"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkout-cc-desc">
                      {t("checkout.cardNotes")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {paymentRequest && useApplePay ? (
            <div className="apple-pay-modal">
              <div className="apple-pay-body">
                <div className="apple-pay-holder">
                  <p className="apple-pay-desc">
                    Please click the Apple Pay button below to proceed:
                  </p>
                  <div className="apple-pay-btn">
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="checkout-control">
              <button
                type="button"
                className="checkout-btn"
                onClick={handleSubmit}
                disabled={checkoutState === "loading"}
              >
                {t("checkout.confirmAndPay")}
              </button>
            </div>
          )}
        </form>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  token: selectToken(state),
});

export default connect(mapStateToProps)(Checkout);
