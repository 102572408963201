import { all } from 'redux-saga/effects';
import UserReducer from './duck/reducer';
import { watchFetchPaymentInfo, watchFetchUser } from './duck/saga';

function* UserSaga() {
	yield all([
		watchFetchUser(),
		watchFetchPaymentInfo(),
	]);
}

export {
	UserSaga,
	UserReducer,
};
