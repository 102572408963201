import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
	const { t } = useTranslation();
	return (
		<footer>
			<div className="content top">
				<div className="col logo">
					<img className="logo" alt="logo" src="/img/assets/logo.png" />
				</div>
				<div className="col logo">
					<a href="https://noshdirect.com/" target="_blank">
						<img className="nosh-direct-logo" alt="logo" src="/img/nosh_direct.jpg" />
					</a>
				</div>
				<div className="col sm">
					<div className="sm">
						<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/noshhk/"><img className="sm-logo" alt="sm-logo" src="/img/assets/fb-logo.png" /></a>
						<a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/nosh.hk/"><img className="sm-logo" alt="sm-logo" src="/img/assets/ig-logo.png" /></a>
					</div>
				</div>
				<div className="col">
					<span>{t('layout.ourOfferings')}</span>
					{/* <Link to="/">{t('layout.mealPlans')}</Link> */}
					<Link to={`${t('langPath')}/our-menu/`}>{t('layout.ourMenu')}</Link>
					<a target="_blank" rel="noopener noreferrer" href="http://spoonfulmeals.com/">Spoonful by NOSH</a>
				</div>
				<div className="col">
					<span>{t('layout.needHelp')}</span>
					<Link to={`${t('langPath')}/FAQ/`}>{t('layout.deliveryFaq')}</Link>
					<Link to={`${t('langPath')}/suppliers/`}>{t('layout.aboutNosh')}</Link>
					<Link to={`${t('langPath')}/Blog`}>{t('layout.blog')}</Link>
				</div>
				<div className="col">
					<span>{t('layout.getInTouch')}</span>
					<a rel="noopener noreferrer" href="tel:85223881999">+852 2388 1999</a>
					<a target="_blank" rel="noopener noreferrer" href="mailto:hello@nosh.hk">hello@nosh.hk</a>
					{/* <a target="_blank" rel="noopener noreferrer" href="http://join.nosh.hk/">{t('layout.joinUs')}</a> */}
				</div>
				<div className="col">
					<span>{t('layout.company')}</span>
					<Link to={`${t('langPath')}/TermsAndConditions`}>{t('layout.tnc')}</Link>
					<Link to={`${t('langPath')}/privacypolicy`}>{t('layout.privatePolicy')}</Link>
				</div>

			</div>
			<div className="content-sm">
				<img className="logo" alt="logo" src="/img/assets/logo.png" />
				<a href="https://noshdirect.com/" target="_blank">
					<img className="nosh-direct-logo" alt="logo" src="/img/nosh_direct.jpg" />
				</a>
				<div className="sm-logo">
					<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/noshhk/"><img alt="sm-logo" src="/img/assets/fb-logo.png" /></a>
					<a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/nosh.hk/"><img alt="sm-logo" src="/img/assets/ig-logo.png" /></a>
				</div>
			</div>
			<div className="content bottom">
				<img className="payment-icon" alt="payment-icon" src="/img/assets/payment-icon.png" />
				<p>{t('layout.addr')}</p>
				<p>{t('layout.foodFactoryLicense')}</p>
			</div>
		</footer>
	);
};

export default Footer;
