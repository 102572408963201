import React, { useState, useEffect } from 'react';
import './NotificationMsg.scss';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { useTranslation } from 'react-i18next';
import { getNotificationMsg } from '../../api';

export const NotificationMsg = () => {
	const [swiper, setSwiper] = useState<any>(null);
	const [notificationMsgs, setNotificationMsgs] = useState<any>(null);
	const { t } = useTranslation();

	useEffect(() => {
		getNotificationMsg()
			.then(((res) => {
				if (res.data.notifications) {
					setNotificationMsgs(res.data.notifications);
				}
			}));
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setSwiper(new Swiper('.notification-msg-slider-body', {
				navigation: {
					nextEl: '.notification-msg-next',
					prevEl: '.notification-msg-prev',
				},
				slidesPerView: 1,
				// spaceBetween: 30,
				// watchOverflow: true,
				loop: true,
				autoplay: {
					delay: 5000,
				},
			}));
		}, 1000);
	}, [notificationMsgs]);

	return (
		<div className={`notification-msg ${(notificationMsgs && notificationMsgs.length > 0) ? '' : 'is-hidden'}`}>
			<div className="notification-msg-wrapper">
				<div className="notification-msg-slider">

					{(notificationMsgs && notificationMsgs.length > 1) ? (
						<div className="notification-msg-control">
							<button type="button" className="notification-msg-prev swiper-button-prev">
								<span className="notification-msg-control-label">{t('layout.previousMessage')}</span>
							</button>
						</div>
					) : ''}

					<div className="notification-msg-slider-body">
						<div className="swiper-wrapper">
							{notificationMsgs && notificationMsgs.map((x: any) => (
								<div key={x.text} className="notification-msg-slide swiper-slide">
									<div className="notification-msg-msg">
										{x.text}
									</div>
								</div>
							))}
						</div>
					</div>

					{(notificationMsgs && notificationMsgs.length > 1) ? (
						<div className="notification-msg-control">
							<button type="button" className="notification-msg-next swiper-button-next">
								<span className="notification-msg-control-label">{t('layout.nextMessage')}</span>
							</button>
						</div>
					) : ''}
				</div>
			</div>
		</div>
	);
};

export default NotificationMsg;
