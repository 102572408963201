import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import IframeResizer from 'iframe-resizer-react';
import Iframe from 'react-iframe';
import Layout from '../../layouts/Layout';
import './Blog.scss';

const Blog = () => {
	const { t } = useTranslation();

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<main>
				<div className="blog-container">
					<Iframe
						url="//noshhk.wordpress.com"
						className="blog"
						position="relative"
						width="100%"
						height="100vh"
					/>
				</div>
			</main>
		</Layout>
	);
};

export default Blog;
