/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./MealPlanDetailFooter.scss";
import { Button, Tooltip, Fade } from "@material-ui/core";

type Props = {
  goal: any | null;
  mealList: Array<any> | null;
  holidays: Array<any> | null;
  skipDays: Array<string>;
  allMealDatesForCheckout: Array<string>;
  hasSnack: boolean;
  hasExtraMeal: boolean;
  hasExtraLunch: boolean;
  hasExtraDinner: boolean;
  updateSkipDays: (date: string) => void;
};
const daysName = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const MealPlanDetailFooter = ({
  goal,
  mealList,
  holidays,
  skipDays,
  hasSnack,
  hasExtraMeal,
  hasExtraLunch,
  hasExtraDinner,
  updateSkipDays,
  allMealDatesForCheckout,
}: Props) => {
  const [mealInfo, setMealInfo] = useState<any | null>([]);
  const { t } = useTranslation();

  const toggleMeal = (id: any) => {
    if (mealInfo) {
      const newMealInfo: any = { ...mealInfo };
      if (newMealInfo) {
        newMealInfo[id].open = !newMealInfo[id].open;
      }
      setMealInfo(newMealInfo);
    }
  };

  // replace with logic to calculate per day price
  useEffect(() => {
    console.log("This is goal", goal);
    const mealInfoListOrdered: any = {};
    const mealInfoList: any = {};
    // console.log('holidayList', holidays);
    if (mealList) {
      mealList.forEach((item) => {
        const thisWeekday = moment(item.date).utcOffset(8).format("dddd");
        const thisWeekdayDate = moment(item.date)
          .utcOffset(8)
          .format("MMMM, DD, YYYY");
        const thisMealType = item.mealType;
        const thisDish = item.dishId;
        const nameKey = `name_${t("langKey")}`;
        if (!mealInfoList[thisWeekday]) {
          mealInfoList[thisWeekday] = {};
        }
        mealInfoList[thisWeekday].holiday = null;
        if (holidays) {
          const thisHoliday = holidays.filter(
            (el: any) => item.date === el.date
          );
          if (thisHoliday.length) {
            mealInfoList[thisWeekday].holiday = thisHoliday[0].name;
          }
        }
        mealInfoList[thisWeekday].date = thisWeekdayDate;
        mealInfoList[thisWeekday].rawDate = item.date;
        mealInfoList[thisWeekday][thisMealType] = {
          _id: item._id,
          name: thisDish[nameKey],
          thumb: thisDish.image_url,
          total_protein: thisDish.total_protein,
          total_fat: thisDish.total_fat,
          total_carb: thisDish.total_carb,
          total_calories: thisDish.total_calories,
          ingredients: thisDish.ingredients,
        };
      });
    }
    setMealInfo(mealInfoList);
  }, [mealList, t("langKey")]);

  return (
    <div className={`meal-table ${hasSnack ? "mod-has-snack" : ""}`}>
      <div className="meal-table-body">
        <div className="meal-table-row hide-mobile">
          <div className="meal-table-weekday">&nbsp;</div>
          <div className="meal-table-meals">
            {goal.showThreeMeals.breakfast.show === true ? (
              <div className="meal-table-mealtype">
                <img
                  alt="breakfast"
                  src="/img/assets/breakfast.png"
                  className="meal-table-mealtype-icon"
                />
                <span className="meal-table-mealtype-label">
                  {goal._id === "65aa458eff987703b13642fc"
                    ? "Meal 1"
                    : t("mealPlan.breakfast").toUpperCase()}
                </span>
              </div>
            ) : (
              ""
            )}
            {goal.showThreeMeals.lunch.show === true ? (
              <div className="meal-table-mealtype">
                <img
                  alt="lunch"
                  src="/img/assets/lunch.png"
                  className="meal-table-mealtype-icon"
                />
                <span className="meal-table-mealtype-label">
                  {goal._id === "65aa458eff987703b13642fc"
                    ? "Meal 2"
                    : t("mealPlan.lunch").toUpperCase()}
                </span>
              </div>
            ) : (
              ""
            )}
            {goal.showThreeMeals.dinner.show === true ? (
              <div className="meal-table-mealtype">
                <img
                  alt="dinner"
                  src="/img/assets/dinner.png"
                  className="meal-table-mealtype-icon"
                />
                <span className="meal-table-mealtype-label">
                  {goal._id === "65aa458eff987703b13642fc"
                    ? "Meal 3"
                    : t("mealPlan.dinner").toUpperCase()}
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="meal-table-mealtype mod-snack">
              <img
                alt="dinner"
                src="/img/assets/snack.png"
                className="meal-table-mealtype-icon mod-snack"
              />
              <span className="meal-table-mealtype-label">
                {t("mealPlan.snack").toUpperCase()}
              </span>
            </div>
          </div>
          <div className="meal-table-infos">
            <div className="meal-table-infos-head">
              {t("mealPlan.daily")}
              <br />
              {t("mealPlan.avgMacroGram")}
            </div>
          </div>
        </div>

        {mealInfo &&
          daysName.map((index) => {
            const x = mealInfo[index];
            if (!mealInfo[index]) return "";

            const caloBreakfast = x.breakfast ? x.breakfast.total_calories : 0;
            const protBreakfast = x.breakfast ? x.breakfast.total_protein : 0;
            const carbBreakfast = x.breakfast ? x.breakfast.total_carb : 0;
            const fatBreakfast = x.breakfast ? x.breakfast.total_fat : 0;

            let caloLunch = x.lunch ? x.lunch.total_calories : 0;
            let protLunch = x.lunch ? x.lunch.total_protein : 0;
            let carbLunch = x.lunch ? x.lunch.total_carb : 0;
            let fatLunch = x.lunch ? x.lunch.total_fat : 0;
            if (hasExtraLunch) {
              caloLunch += x.extra_lunch ? x.extra_lunch.total_calories : 0;
              protLunch += x.extra_lunch ? x.extra_lunch.total_protein : 0;
              carbLunch += x.extra_lunch ? x.extra_lunch.total_carb : 0;
              fatLunch += x.extra_lunch ? x.extra_lunch.total_fat : 0;
            }

            let caloDinner = x.dinner ? x.dinner.total_calories : 0;
            let protDinner = x.dinner ? x.dinner.total_protein : 0;
            let carbDinner = x.dinner ? x.dinner.total_carb : 0;
            let fatDinner = x.dinner ? x.dinner.total_fat : 0;
            if (hasExtraDinner) {
              caloDinner += x.extra_dinner ? x.extra_dinner.total_calories : 0;
              protDinner += x.extra_dinner ? x.extra_dinner.total_protein : 0;
              carbDinner += x.extra_dinner ? x.extra_dinner.total_carb : 0;
              fatDinner += x.extra_dinner ? x.extra_dinner.total_fat : 0;
            }

            const caloSnack = x.snack ? x.snack.total_calories : 0;
            const protSnack = x.snack ? x.snack.total_protein : 0;
            const carbSnack = x.snack ? x.snack.total_carb : 0;
            const fatSnack = x.snack ? x.snack.total_fat : 0;

            let totalCalo = 0;
            totalCalo += caloBreakfast;
            totalCalo += caloLunch;
            totalCalo += caloDinner;
            let totalProt = 0;
            totalProt += protBreakfast;
            totalProt += protLunch;
            totalProt += protDinner;
            let totalCarb = 0;
            totalCarb += carbBreakfast;
            totalCarb += carbLunch;
            totalCarb += carbDinner;
            let totalFat = 0;
            totalFat += fatBreakfast;
            totalFat += fatLunch;
            totalFat += fatDinner;

            if (hasSnack) {
              totalCalo += caloSnack;
              totalProt += protSnack;
              totalCarb += carbSnack;
              totalFat += fatSnack;
            }
            if (hasExtraMeal) {
              totalCalo += x.extra_meal ? x.extra_meal.total_calories : 0;
              totalProt += x.extra_meal ? x.extra_meal.total_protein : 0;
              totalCarb += x.extra_meal ? x.extra_meal.total_carb : 0;
              totalFat += x.extra_meal ? x.extra_meal.total_fat : 0;
            }
            return (
              <div
                key={x._id + index}
                className={`meal-table-row ${x.open ? "is-open" : ""} ${
                  x.holiday ? "is-holiday" : ""
                } ${skipDays.includes(x.rawDate) ? "is-skipped" : ""} ${
                  allMealDatesForCheckout.includes(x.rawDate)
                    ? "is-selected"
                    : "not-selected"
                }`}
              >
                <div className="meal-table-weekday">
                  <div className="meal-table-weekday-name">
                    <div className="meal-table-weekday-weekday">
                      {t(`weekdays.${index}`)}
                    </div>
                    <div className="meal-table-weekday-date">{x.date}</div>
                    {x.holiday ? (
                      <div className="meal-table-weekday-holiday">{`(${x.holiday})`}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    onClick={() => toggleMeal(index)}
                    type="button"
                    className="meal-table-toggle"
                  >
                    {t("mealPlan.toggleMeal")}
                  </button>
                  {x.holiday ||
                  (!allMealDatesForCheckout.includes(x.rawDate) &&
                    !skipDays.includes(x.rawDate)) ||
                  new Date(x.rawDate).getTime() >
                    new Date(
                      allMealDatesForCheckout[
                        allMealDatesForCheckout.length - 1
                      ]
                    ).getTime() ? (
                    ""
                  ) : (
                    <button
                      onClick={() => updateSkipDays(x.rawDate)}
                      type="button"
                      className="meal-table-skip-btn"
                    >
                      {skipDays.includes(x.rawDate) ? "Unskip day" : "Skip day"}
                    </button>
                  )}
                </div>
                <div className="meal-table-meals">
                  {goal.showThreeMeals.breakfast.show === true ? (
                    <div className="meal-table-meal">
                      {x.breakfast &&
                      goal.showThreeMeals.breakfast.show === true ? (
                        <div className="meal-table-meal-content">
                          {x.breakfast.thumb ? (
                            <div
                              style={{
                                backgroundImage: `url(${x.breakfast.thumb})`,
                              }}
                              className="meal-table-meal-img"
                            />
                          ) : (
                            ""
                          )}
                          <div className="meal-table-meal-info">
                            <div className="meal-table-meal-type show-mobile">
                              {goal._id === "65aa458eff987703b13642fc"
                                ? "Meal 1"
                                : t("mealPlan.breakfast").toUpperCase()}
                            </div>
                            <Tooltip
                              title={<h1>{x.breakfast.ingredients}</h1>}
                              arrow
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                            >
                              <h3 className="meal-table-meal-name">
                                {x.breakfast.name}
                              </h3>
                            </Tooltip>
                            <p className="meal-table-meal-calories">
                              {caloBreakfast} {t("mealPlan.calories")}
                              {", "}
                              {protBreakfast} {t("mealPlan.protein")}
                              {", "}
                              {carbBreakfast} {t("mealPlan.carbs")}
                              {", "}
                              {fatBreakfast} {t("mealPlan.fats")}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="meal-table-meal">
                    {x.lunch && goal.showThreeMeals.lunch.show === true ? (
                      <div className="meal-table-meal-content">
                        {x.lunch.thumb ? (
                          <div
                            style={{ backgroundImage: `url(${x.lunch.thumb})` }}
                            className="meal-table-meal-img"
                          />
                        ) : (
                          ""
                        )}
                        <div className="meal-table-meal-info">
                          <div className="meal-table-meal-type show-mobile">
                            {goal._id === "65aa458eff987703b13642fc"
                              ? "Meal 2"
                              : t("mealPlan.lunch").toUpperCase()}
                          </div>
                          <Tooltip
                            title={<h1>{x.lunch.ingredients}</h1>}
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                          >
                            <h3 className="meal-table-meal-name">
                              {x.lunch.name}
                            </h3>
                          </Tooltip>
                          <p className="meal-table-meal-calories">
                            {caloLunch} {t("mealPlan.calories")}
                            {", "}
                            {protLunch} {t("mealPlan.protein")}
                            {", "}
                            {carbLunch} {t("mealPlan.carbs")}
                            {", "}
                            {fatLunch} {t("mealPlan.fats")}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="meal-table-meal">
                    {x.dinner && goal.showThreeMeals.dinner.show === true ? (
                      <div className="meal-table-meal-content">
                        {x.dinner.thumb ? (
                          <div
                            style={{
                              backgroundImage: `url(${x.dinner.thumb})`,
                            }}
                            className="meal-table-meal-img"
                          />
                        ) : (
                          ""
                        )}
                        <div className="meal-table-meal-info">
                          <div className="meal-table-meal-type show-mobile">
                            {goal._id === "65aa458eff987703b13642fc"
                              ? "Meal 3"
                              : t("mealPlan.dinner").toUpperCase()}
                          </div>

                          <Tooltip
                            title={<h1>{x.dinner.ingredients}</h1>}
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                          >
                            <h3 className="meal-table-meal-name">
                              {x.dinner.name}
                            </h3>
                          </Tooltip>
                          <p className="meal-table-meal-calories">
                            {caloDinner} {t("mealPlan.calories")}
                            {", "}
                            {protDinner} {t("mealPlan.protein")}
                            {", "}
                            {carbDinner} {t("mealPlan.carbs")}
                            {", "}
                            {fatDinner} {t("mealPlan.fats")}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="meal-table-meal mod-snack">
                    {hasSnack && x.snack ? (
                      <div className="meal-table-meal-content">
                        {x.snack.thumb ? (
                          <div
                            style={{ backgroundImage: `url(${x.snack.thumb})` }}
                            className="meal-table-meal-img"
                          />
                        ) : (
                          ""
                        )}
                        <div className="meal-table-meal-info">
                          <div className="meal-table-meal-type show-mobile">
                            {t("mealPlan.snack").toUpperCase()}
                          </div>
                          <h3 className="meal-table-meal-name">
                            {x.snack.name}
                          </h3>
                          <p className="meal-table-meal-calories">
                            {caloSnack} {t("mealPlan.calories")}
                            {", "}
                            {protSnack} {t("mealPlan.protein")}
                            {", "}
                            {carbSnack} {t("mealPlan.carbs")}
                            {", "}
                            {fatSnack} {t("mealPlan.fats")}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="meal-table-nosnack">
                        No Snack for this day
                      </div>
                    )}
                  </div>
                </div>
                <div className="meal-table-infos">
                  <div className="meal-table-infos-header show-mobile">
                    <div className="meal-table-infos-header-label">
                      {t("mealPlan.daily")} {t("mealPlan.avgMacroGram")}
                    </div>
                  </div>
                  <div className="meal-table-info">
                    <h3 className="meal-table-info-name">
                      {t("mealPlan.calories").toUpperCase()}
                    </h3>
                    <p className="meal-table-info-data">{totalCalo}</p>
                  </div>
                  <div className="meal-table-info">
                    <h3 className="meal-table-info-name">
                      {t("mealPlan.protein")}
                    </h3>
                    <p className="meal-table-info-data">{totalProt}</p>
                  </div>
                  <div className="meal-table-info">
                    <h3 className="meal-table-info-name">
                      {t("mealPlan.carbs")}
                    </h3>
                    <p className="meal-table-info-data">{totalCarb}</p>
                  </div>
                  <div className="meal-table-info">
                    <h3 className="meal-table-info-name">
                      {t("mealPlan.fats")}
                    </h3>
                    <p className="meal-table-info-data">{totalFat}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MealPlanDetailFooter;
