import { action } from 'typesafe-actions';
import { AuthUser } from '../../../models/AuthUser';

export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';

export const FB_LOGIN = 'auth/FB_LOGIN';
export const FB_LOGIN_SUCCESS = 'auth/FB_LOGIN_SUCCESS';
export const FB_LOGIN_FAIL = 'auth/FB_LOGIN_FAIL';

export const LOGOUT = 'auth/LOGOUT';

export const login = (username: string, password: string) => action(LOGIN, { username, password });

export const loginSuccess = (
	data: {
		token: string;
		authUser: AuthUser;
	},
) => action(LOGIN_SUCCESS, { ...data });

export const loginFail = (
	error: string,
) => action(LOGIN_FAIL, { error });

export const fbLogin = (
	name: string, email: string, id: string, accessToken: string,
) => action(FB_LOGIN, {
	name, email, id, accessToken,
});

export const fbLoginSuccess = (
	data: {
		token: string;
		authUser: AuthUser;
	},
) => action(FB_LOGIN_SUCCESS, { ...data });

export const fbLoginFail = (
	error: string,
) => action(FB_LOGIN_FAIL, { error });

export const logout = () => action(LOGOUT);