import React, { useEffect } from "react";
import "./Vitasoy.scss";
import { Link } from 'react-router-dom';
import { RootState } from "ReduxTypes";
import { connect } from "react-redux";
import { selectToken } from "../../../store/auth/duck/selectors";
import { selectNoshUser } from "../../../store/user/duck/selectors";
import Layout from "../../../layouts/Layout";

const Vitasoy = () => {

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<main className="vita-promo">
				<img className="vita-banner-img" src="/img/vitasoy-promo.jpg" alt="logo" />
				<div className="vita-heading">
					<h1 className="vita-title">PROMOTION: NOSH X Vitasoy Calci-Plus</h1>
				</div>
				<p className="vita-scroll">(Scroll down for English)</p>
				{/* Chinese */}
				<div className="vita-content">
					<h1>
						【NOSH X 維他奶™鈣思寶™】 推廣活動細則
					</h1>
					<h2>買2排維他奶鈣思寶 即減-$250 NOSH訂單及送營養師諮詢 (總值超過 $1000)😍💥</h2>
					<br />
					<div className="vita-text">
						<p>🎁 如何參加及贏取豐富獎品：</p>
						<p>
							<strong>👉 第一步：</strong>
							買任何兩排 6包裝或9包裝維他奶鈣思寶高鈣植物奶。🛍️
						</p>
						<p>
							<strong>👉 第二步：</strong>
							打開 NOSH 專屬優惠網頁&gt;&gt;&gt;<a target="_blank" rel="noopener noreferrer" href="https://noshhk.pro.typeform.com/to/MQOF86fu" className="vita-link"> 瀏覽 CLICK HERE</a>，填妥簡單表格及上傳收據。📋🧾
						</p>
						<p>
							<strong>👉 第三步：</strong>
							耐心等等，密切留意你的 email 收件箱！📧 NOSH 會發送特別優惠電郵，內含 $250 優惠碼！💸
						</p>
						<p>只要用該優惠碼訂購任何 NOSH 膳食計劃, 你就可以免費預約一次專業營養師諮詢，🥗🥙
							我們的專業營養師會陪伴你走向健康之路，提供寶貴建議，助你實現健康目標！
						</p>
					</div>
					<div className="button">
						<Link to="/MealPlans/1">
							<button
								type="button"
							>
								開始訂餐
							</button>
						</Link>
					</div>
					<div className="vita-text">
						<p>千萬不要錯過這個絕佳機會，讓維他奶鈣思寶為你的生活注入活力，同時體驗 NOSH 膳食計劃帶來嘅便利及好處！🤩</p>
						<p>不用等！快來把握限時優惠！分享給你家人和朋友啦！🗣️</p>
						<p>一起迎接更健康嘅生活方式吧！💚</p>
					</div>
					<div className="buttons">
						<Link to="/MealPlans/1">
							<button
								type="button"
							>
								開始訂餐
							</button>
						</Link>
						<a target="_blank" rel="noopener noreferrer" href="https://shorturl.at/klACP">
							<button
								type="button"
							>
								參加活動及上傳收據
							</button>
						</a>
					</div>
				</div>
				<hr />

				{/* English */}
				<div className="vita-content">
					<h1>
						【Vitasoy Calci-Plus x NOSH offer】
					</h1>
					<h2>Buy any two packs of Vitasoy Calci-Plus and get $250 OFF NOSH order & free dietitian consultation (Valued at $1000) 😍💥</h2>
					<br />
					<div className="vita-text">
						<p>🎁 Here's how you can participate and win big:</p>
						<p>
							<strong>👉 Step 1: </strong>
							Purchase any two packs of 6 or 9 Vitasoy Calci-Plus Hi-Calcium Plant Milk (any flavour). 🛍️
						</p>
						<p>
							<strong>👉 Step 2: </strong>
							Gain access to our exclusive promo page &gt;&gt;&gt;<a target="_blank" rel="noopener noreferrer" href="https://noshhk.pro.typeform.com/to/MQOF86fu" className="vita-link"> CLICK HERE</a>, where you can fill out a quick form and upload your receipt. 📋🧾
						</p>
						<p>
							<strong>👉 Step 3: </strong>
							Sit tight and keep an eye on your inbox! 📧 NOSH will send you a special email containing a $250 promo code! 💸
						</p>
						<p>But wait, there's more! 🌟</p>
						<p>With your promo code, you can now unlock a FREE dietitian consultation (Valued at $750-$1000) by ordering any NOSH meal plan! 🥗🥙 Our expert dietitians will guide you on your health journey and provide valuable insights to help you reach your goals!

						</p>
					</div>
					<div className="button">
						<Link to="/MealPlans/1">
							<button
								type="button"
							>
								See NOSH Meal Plans
							</button>
						</Link>
					</div>
					<div className="vita-text">
						<p>Don't miss this fantastic opportunity to fuel your body with Vitasoy's Calci-Plus and experience the convenience and benefits of NOSH's meal plans. 🤩</p>
						<p>Hurry and take advantage of this limited-time offer! Share with your friends & family! 🗣️ </p>
						<p>Let's all embrace a healthier lifestyle together! 💚</p>
					</div>
					<div className="buttons">
						<Link to="/MealPlans/1">
							<button
								type="button"
							>
								Order a Meal Plan now
							</button>
						</Link>
						<a target="_blank" rel="noopener noreferrer" href="https://shorturl.at/klACP">
							<button
								type="button"
							>
								Redeem OFFER & upload Receipt
							</button>
						</a>
					</div>
				</div>
			</main>
		</Layout>
	);
};

const mapStateToProps = (state: RootState) => ({
	token: selectToken(state),
	noshUser: selectNoshUser(state),
});

export default connect(mapStateToProps)(Vitasoy);
