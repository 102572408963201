import { ActionType } from 'typesafe-actions';
import * as UserActions from './actions';
import * as AuthActions from '../../auth/duck/actions';
import { NoshUser } from '../../../models/NoshUser';
import { PaymentInfo } from '../../../models/PaymentInfo';

export interface State{
	noshUser: NoshUser| null;
	paymentInfo: PaymentInfo[] | null;
	error?: string;
}

const initialState: State = {
	noshUser: null,
	paymentInfo: null,
};

type UserActions = ActionType<typeof UserActions>;
type AuthActions = ActionType<typeof AuthActions>;

export default function reducer(
	state: State = initialState, action: UserActions | AuthActions,
): State {
	switch (action.type) {
		case UserActions.FETCH_USER:
		case UserActions.FETCH_PAYMENT_INFO:
			return {
				...state,
				error: undefined,
			};
		case UserActions.FETCH_USER_SUCCESS:
		case UserActions.FETCH_PAYMENT_INFO_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case UserActions.FETCH_USER_FAIL:
		case UserActions.FETCH_PAYMENT_INFO_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case AuthActions.LOGOUT:
			return initialState;
		default:
			return state;
	}
}
