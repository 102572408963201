import React from 'react';
import './Steps.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
	step: number;
	links: Array<string>;
}


export const Steps = ({ step, links }: Props) => {
	const { t } = useTranslation();

	const stepsInfo = [
		{
			id: 1,
			title: t('mealPlan.step1'),
		},
		{
			id: 2,
			title: t('mealPlan.step2'),
		},
		{
			id: 3,
			title: t('mealPlan.step3'),
		},
		{
			id: 4,
			title: t('mealPlan.step4'),
		},
		{
			id: 5,
			title: t('mealPlan.step5'),
		},
	];

	return (
		<div className="steps">
			{stepsInfo.map((stepInfo: any, index: number) => {
				const actualIndex = index + 1;
				return (
					<React.Fragment key={stepInfo.id}>
						{index === 0 ? null : (<hr className={`steps-line ${step >= actualIndex && 'current'}`} />)}
						<Link to={links[index] || ''} className={`steps-step left ${step >= actualIndex && 'current'} ${!links[index] ? 'mod-nolink' : ''}`}>
							<span className="steps-label">{stepInfo.title}</span>
						</Link>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default Steps;
