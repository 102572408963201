/* eslint-disable new-cap */
/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';

import {
	Page, Text, View, Document, StyleSheet, PDFDownloadLink,
	Font,
} from '@react-pdf/renderer';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import GiftCardsInfo from './GiftCardsInfo';
import './GiftCardsFinal.scss';

// Printing PDF - Options
// const options = {
// 	orientation: 'portrait',
// 	unit: 'mm',
// 	format: [297, 300],
// };

// Printing PDF - Create styles

Font.register({
	family: 'BebasNeue',
	fonts: [
		{
			src: '/fonts/BebasNeue-Regular.ttf',
		},
	],
});
const styles = StyleSheet.create({
	page: {
		position: 'relative',
		backgroundColor: '#FFFFFF',
		fontFamily: 'BebasNeue',
	},
	section: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		alignItems: 'flex-start',
		justifyContent: 'center',
		width: '100%',
		maxWidth: '100%',
		minWidth: '100%',
		flexGrow: 0,
		flexShrink: 0,
		marginBottom: 30,
		fontFamily: 'BebasNeue',
	},
	title: {
		width: '100%',
		fontSize: 30,
		lineHeight: 1.5,
		textAlign: 'center',
		marginTop: 60,
		fontFamily: 'BebasNeue',
	},
	label: {
		maxWidth: '25%',
		minWidth: '25%',
		fontSize: 20,
		lineHeight: 1.1,
		textAlign: 'right',
		marginRight: 20,
		flexGrow: 0,
		flexShrink: 0,
		marginLeft: 60,
		fontFamily: 'BebasNeue',
	},
	info: {
		maxWidth: '60%',
		minWidth: '60%',
		fontSize: 20,
		lineHeight: 1.1,
		textAlign: 'left',
		flexGrow: 0,
		flexShrink: 0,
		marginRight: 60,
		fontFamily: 'BebasNeue',
	},
});

function GiftCardsFinal(props: any) {
	const [name, setName] = useState<string>('');

	const [docReady, setDocReady] = useState(false);
	const ref = React.useRef(null);


	// Printing PDF - Create Document Component
	const MyDocument = () => (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.title}>
						Gift Details
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.label}>
						Send to:
					</Text>
					<Text style={styles.info}>
						{props?.finalData?.customerName || ''}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.label}>
						Message:
					</Text>
					<Text style={styles.info}>
						{props?.finalData?.message || ''}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.label}>
						Amount:
					</Text>
					<Text style={styles.info}>
						$
						{props?.finalData?.giftTotal || ''}
					</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.label}>
						Gift Code:
					</Text>
					<Text style={styles.info}>
						{props?.finalData?.giftCode}
					</Text>
				</View>
			</Page>
		</Document>
	);


	useEffect(() => {
		setTimeout(() => {
			setDocReady(true);
		}, 500);
	}, []);

	const history = useHistory();
	const handleDownloadPdf = () => {
		const filename = 'giftcard.pdf';

		html2canvas(document.querySelector('#content') as HTMLInputElement).then((canvas) => {
			// let pdf = new jsPDF('p', 'mm', 'a4');
			const imgData = canvas.toDataURL('text');
			// const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);
			// const ratio = canvas.height / canvas.width;
			// const width = pdf.internal.pageSize.getWidth();
			// let height = pdf.internal.pageSize.getHeight();
			// height = ratio * width;
			pdf.addImage(imgData, 'JPEG', canvas.width / 4, canvas.height / 4, canvas.width / 2, canvas.height / 2, 'a', 'SLOW');
			pdf.save(filename);
		});
	};

	const useQuery = () => new URLSearchParams(useLocation().search);
	const query = useQuery();

	// Back to top
	useEffect(() => {
		window.scroll(0, 0);
		const thisName = query.get('name');
		if (thisName) {
			setName(thisName);
		}
	}, []);
	return (
		<>
			<div className="gift-form-top">
				<div className="container-fluid">
					<br />
					<ul className="list-unstyled multi-steps">
						<li onClick={() => history.push('/gift-cards/gift-cards-selector/')} />
						<li onClick={() => history.push('/parent/billingInfo')} />
						<li className="is-active" />
					</ul>
				</div>
				<div className="container">
					<div className="success-message">
						<h1 className="title">It's on its way !</h1>
						<p>
							<span>{name}</span>
							{' '}
							just got some NOSH credits to spend on their next meal plan thanks to you
						</p>
						{/* <a
							href=""
							onClick={(event) => {
								event.preventDefault();
								handleDownloadPdf();
							}}
						>
							Download PDF Template
						</a> */}
						{docReady ? (
							<button
								className="download-gift-pdf"
								type="button"
							>
								<PDFDownloadLink document={<MyDocument />} fileName="delivery-label.pdf">
									{({
										loading,
									}) => (loading ? 'Loading document...' : 'Download PDF Template')}
								</PDFDownloadLink>
							</button>
						) : null}
						<div />
						<div ref={ref} className="screen-msg" id="content">
							<h2>Gift Details</h2>
							<p className="recipient">
								<span className="screen-msg-label">Send to:</span>
								<span>{props?.finalData?.customerName || ''}</span>
							</p>
							<p className="message">
								<span className="screen-msg-label">Message:</span>
								<span className="msg">
									{props?.finalData?.message || ''}
								</span>
							</p>
							<p className="amount">
								<span className="screen-msg-label">Amount:</span>
								<span>
									$
									{props?.finalData?.giftTotal || ''}
								</span>
							</p>
							<br />
							<p className="gift-code">
								Gift Code:
								<span className="msg">
									{' '}
									{props?.finalData?.giftCode || ''}
								</span>
							</p>
						</div>
						<p className="note">In the mood to show more love? Give another NOSH gift that keeps on giving!</p>
						<button
							className="button gift"
							onClick={(event) => {
								event.preventDefault();
								props.onHandleSubmitData();
							}}
						>
							<Link to="/gift-cards/gift-cards-selector/">Gift More</Link>
						</button>
					</div>
				</div>
			</div>
			<GiftCardsInfo />
		</>
	);
}

export default GiftCardsFinal;
