import { all } from 'redux-saga/effects';
import { watchFbLogin, watchLogin } from './duck/saga';
import AuthReducer from './duck/reducer';

function* AuthSaga() {
	yield all([
		watchLogin(),
		watchFbLogin(),
	]);
}

export {
	AuthSaga,
	AuthReducer,
};
