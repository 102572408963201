import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import Carousel, { Dots } from '@brainhubeu/react-carousel';
import Select from 'react-select';
// import '@brainhubeu/react-carousel/lib/style.css';
import './SliderSelector.scss';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { Goal } from '../../models';

interface Props {
	goal: Goal;
	handlePackage: any;
	selectedPackage: any;
}

interface Options {
	values: string;
	label: string;
}

export const SliderSelector = ({ goal, handlePackage, selectedPackage }: Props) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(0);
	const [selectedOption, setSelectedOption] = useState<any | null>(null);
	const [openDropdown, setOpenDropdown] = useState(false);
	// const dropdown = createRef<any>();
	const [swiper, setSwiper] = useState<any | null>(null);

	const { name, package_image, allowedDays } = goal;
	const description = `<li>${(goal?.description || '').replace('\n', '</li><li>')}</li>`;
	console.log(goal.name);
	var duration: any;
	// To hide days under 20 for nosh life
	if ( goal.name == 'Keto Life' || goal.name == 'Lean Life' || goal.name == 'Slim Life' ) {
		duration = [20, 30, 40];
	} else if ( goal._id == '651fdb745247f3495648cd3a') {
		duration = [ 5, 'select'];
	} else if ( goal._id == '65aa458eff987703b13642fc') {
		duration = [ 2, 3, 5, 'select'];
	} else {
		duration = [3, 5, 10, 'select'];
	}
	const durationLabel = [t('mealPlan.packageLabel1') + 234 + t('mealPlan.packageLabel2'), t('mealPlan.packageLabel1') + 223 + t('mealPlan.packageLabel2'), t('mealPlan.packageLabel1') + 216 + t('mealPlan.packageLabel2'), 'select'];
	const labelDays = t('days');
	const options = [
		{ values: '2', label: `2 ${labelDays}` },
		{ values: '3', label: `3 ${labelDays}` },
		{ values: '4', label: `4 ${labelDays}` },
		{ values: '5', label: `5 ${labelDays}` },
		{ values: '10', label: `10 ${labelDays}` },
		{ values: '15', label: `15 ${labelDays}` },
		{ values: '20', label: `20 ${labelDays}` },
		{ values: '30', label: `30 ${labelDays}` },
		{ values: '40', label: `40 ${labelDays}` },
		{ values: '50', label: `50 ${labelDays}` },
		{ values: '60', label: `60 ${labelDays}` },
	];

	console.log('allowedDays', allowedDays);


	const onSelect = (e: any) => {
		setSelectedOption(e);
		setValue(e.values);
		setOpenDropdown(false);
		swiper.allowTouchMove = true;
		handlePackage('select', e.values);
	};

	const onClick = () => {
		setOpenDropdown(true);
		// console.log(swiper);
		swiper.allowTouchMove = false;
	};

	const selectPackage = (x: any) => {
		setOpenDropdown(false);
		handlePackage(x, value);
		console.log('selectPackage', x);
	};

	const clickPackage = (x: any) => {
		console.log('clickPackage', x);
		// swiper.slideTo(x);
		const selected = duration[x || 0];
		selectPackage(selected);
	};

	useEffect(() => {
		const thisSwiper = new Swiper('.slider-container .swiper-container', {
			pagination: {
				el: '.slider-container .swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '.slider-container .swiper-button-next',
				prevEl: '.slider-container .swiper-button-prev',
			},
			slidesPerView: 1,
			initialSlide: 2,
			spaceBetween: 30,
			watchOverflow: true,
			// centerInsufficientSlides: true,
			// centeredSlides: true,
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 30,
				},
				1200: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
			},
			// on: {
			// 	slideChange() {
			// 		// console.log('slide changed', thisSwiper.activeIndex);
			// 		const x = duration[thisSwiper?.activeIndex || 0];
			// 		selectPackage(x);
			// 	},
			// },
		});
		setSwiper(thisSwiper);
	}, []);

	// console.log(`###***${goal}`);
	return (
		<>
			<div className="slider-container hide-mobile">
				{/* <div className="swiper-pagination" /> */}
				<div className="swiper-body">
					<div className="swiper-container">
						<div className="swiper-wrapper">
							{duration.map((x: number | string, index: number) => (
								<div key={x} className={`package-item swiper-slide ${selectedPackage === x ? 'is-selected' : ''}`}>
									<button onClick={() => clickPackage(index)} type="button" className="package-selector">button</button>

									<div className="package-item-thumb">
										{(goal.best_sellers && goal.best_sellers[String(x)])
											? <div className="package-item-best-seller" />
											: ''}
										{x === 'select' ? (
											<div className="package-thumb">
												<div className="package-thumb-src" style={{ backgroundImage: `url(${(goal.package_image && goal.package_image[String(0)]) ? goal.package_image[String(0)] : '/img/bg_wood_nosh.jpg'})` }} />
											</div>
										) : (
											<div className="package-thumb">
												<div className="package-thumb-src" style={{ backgroundImage: `url(${(goal.package_image && goal.package_image[String(x)]) ? goal.package_image[String(x)] : '/img/bg_wood_nosh.jpg'})` }} />
											</div>
										)}
									</div>
									{x === 'select' ? (
										<div className="duration-container mod-select">
											<button onClick={onClick} type="button" className="select__trigger">SELECT DAYS</button>
											<Select
												placeholder={t('mealPlan.selectDays')}
												menuIsOpen={openDropdown}
												onInputChange={() => setOpenDropdown(false)}
												className="select"
												value={selectedOption}
												onChange={onSelect}
												options={options.filter((option: any) => allowedDays?.includes(parseInt(option.values, 10)))}
												classNamePrefix="select"
												components={{
													DropdownIndicator: () => null,
													IndicatorSeparator: () => null,
												}}
												isSearchable={false}
											/>
										</div>
									) : (
										<div className="duration-container">
											{durationLabel[index]}
										</div>
									)}
								</div>
							))}

						</div>
					</div>
				</div>
				<div className="swiper-control">
					<button type="button" className="swiper-button-prev">Previous Slide</button>
					<button type="button" className="swiper-button-next">Next Slide</button>
				</div>
			</div>
			<div className="slider-container show-mobile">
				<div className="slider-static-container">
					{duration.map((x: number | string, index: number) => (
						<div key={x} className="slider-static-item">
							<div className={`package-item swiper-slide ${selectedPackage === x ? 'is-selected' : ''}`}>
								<button onClick={() => clickPackage(index)} type="button" className="package-selector">button</button>

								<div className="package-item-thumb">
									{(goal.best_sellers && goal.best_sellers[String(x)])
										? <div className="package-item-best-seller" />
										: ''}
									{x === 'select' ? (
										<div className="package-thumb">
											<div className="package-thumb-src" style={{ backgroundImage: `url(${(goal.package_image && goal.package_image[String(0)]) ? goal.package_image[String(0)] : '/img/bg_wood_nosh.jpg'})` }} />
										</div>
									) : (
										<div className="package-thumb">
											<div className="package-thumb-src" style={{ backgroundImage: `url(${(goal.package_image && goal.package_image[String(x)]) ? goal.package_image[String(x)] : '/img/bg_wood_nosh.jpg'})` }} />
										</div>
									)}
								</div>
								{x === 'select' ? (
									<div className="duration-container mod-select">
										<button onClick={onClick} type="button" className="select__trigger">SELECT DAYS</button>
										<Select
											placeholder={t('mealPlan.selectDays')}
											menuIsOpen={openDropdown}
											onInputChange={() => setOpenDropdown(false)}
											className="select"
											value={selectedOption}
											onChange={onSelect}
											options={options.filter((option: any) => allowedDays?.includes(parseInt(option.values, 10)))}
											classNamePrefix="select"
											components={{
												DropdownIndicator: () => null,
												IndicatorSeparator: () => null,
											}}
											isSearchable={false}
										/>
									</div>
								) : (
									<div className="duration-container">
										{durationLabel[index]}
									</div>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default SliderSelector;
