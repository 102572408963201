import { action } from 'typesafe-actions';
import { NoshUser } from '../../../models/NoshUser';

export const FETCH_USER = 'user/FETCH_USER';
export const FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'user/FETCH_USER_FAIL';

export const FETCH_PAYMENT_INFO = 'user/FETCH_PAYMENT_INFO';
export const FETCH_PAYMENT_INFO_SUCCESS = 'user/FETCH_PAYMENT_INFO_SUCCESS';
export const FETCH_PAYMENT_INFO_FAIL = 'user/FETCH_PAYMENT_INFO_FAIL';

export const fetchUser = () => action(FETCH_USER);
export const fetchUserSuccess = (
	noshUser: NoshUser,
) => action(FETCH_USER_SUCCESS, { noshUser });
export const fetchUserFail = (
	error: string,
) => action(FETCH_USER_FAIL, { error });

export const fetchPaymentInfo = () => action(FETCH_PAYMENT_INFO);
export const fetchPaymentInfoSuccess = (
	paymentInfo: [],
) => action(FETCH_PAYMENT_INFO_SUCCESS, { paymentInfo });
export const fetchPaymentInfoFail = (
	error: string,
) => action(FETCH_PAYMENT_INFO_FAIL, { error });
