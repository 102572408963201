import { put, takeLatest, call } from 'redux-saga/effects';
import * as BrandsActions from './action';
import { fetchCuisines } from '../../../api';

export function* handleFetchBrands() {
	try {
		const brands = yield call(fetchCuisines);
		yield put(BrandsActions.fetchBrandsSuccess(brands));
	} catch (error) {
		yield put(BrandsActions.fetchBrandsFail(error.message));
	}
}

export function* watchFetchBrands() {
	yield takeLatest(BrandsActions.FETCH_BRANDS, handleFetchBrands);
}
