import { takeLatest, put, call } from 'redux-saga/effects';
import * as SettingActions from './actions';
import * as SettingService from '../../../api/SettingService';
import i18n from "../../../i18n/i18n";

export function* handleFetchBanner() {
	try {
		const response = yield call(SettingService.getBanners);
		const { data: { banners } } = response;
		console.log('handleFetchBanner', response);
		yield put(SettingActions.fetchBannerSuccess(banners));
	} catch (error) {
		const { response: { data: { error: { message } } } } = error;
		yield put(SettingActions.fetchBannerFail(message[i18n.getLang()] || 'Error occurred fetching banner'));
	}
}

export function* watchFetchBanner() {
	yield takeLatest(SettingActions.FETCH_BANNER, handleFetchBanner);
}
