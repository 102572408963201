import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './MealPlans.scss';
import { RootState } from 'MyTypes';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { selectBanner } from '../../store/settings/duck/selector';
import * as SettingActions from '../../store/settings/duck/actions';
import { Banner } from '../../models/Banner';
import Layout from '../../layouts/Layout';
// import { fetchCutOffTime } from '../../api';
// import NewsletterWidget from '../../components/NewsletterWidget/NewsletterWidget';
import CutOff from '../../components/CutOff/CutOff';
import { selectToken } from '../../store/auth/duck/selectors';
import HomeAmbassadors from './components/HomeAmbassadors/HomeAmbassadors';
import HomeMealPlans from './components/HomeMealPlans/HomeMealPlans';
import HomeOurMeals from './components/HomeOurMeals/HomeOurMeals';
import PartnersLogos from './components/PartnersLogos/PartnersLogos';

type Props = {
	banner: Banner | null;
	banners: Array<Banner> | null;
	fetchBanner: () => void;
	token: string | null;
};

const MealPlans = ({ banners, fetchBanner, token }: Props) => {
	const history = useHistory();

	// 20200526 - NOT IN USE
	// const [showNewsletterWidget, setShowNewsletterWidget] = useState<boolean>(false);

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Show newsletter signup widget after certain amount of time
	// 20200526 - NOT IN USE
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setShowNewsletterWidget(true);
	// 	}, 1500);
	// }, []);

	const { t } = useTranslation();

	useEffect(() => {
		fetchBanner();
	}, [fetchBanner]);

	useEffect(() => {
		// console.log('banners', banners);
		if (!banners) return;
		if (banners.length < 2) return;
		const swiper = new Swiper('.meal-plan-banner-bgs', {
			pagination: {
				el: '.meal-plan-banner-pagination',
				type: 'bullets',
				clickable: true,
			},
			navigation: {
				nextEl: '.meal-plan-banner .swiper-button-next',
				prevEl: '.meal-plan-banner .swiper-button-prev',
			},
			slidesPerView: 1,
			loop: true,
			watchOverflow: true,
			centerInsufficientSlides: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
		});
	}, [banners]);

	const onStartNow = (x: any) => {
		console.log(x);
		if (x.destination && x.destination != undefined) {
			history.push(x.destination);
		} else {
			history.push('/MealPlans/1');
		}
	};

	return (
		<Layout>
			<main>
				<h1 className="a11y-hidden">Weight Loss Meal Plan</h1>
				<div
					className="meal-plan-banner"
				>
					<div
						className="meal-plan-banner-bgs"
					>
						<div className="swiper-wrapper">
							{banners && banners.map((x: Banner, index: number) => (
								<div
									key={`${x.url}${index + 1}`}
									className="meal-plan-banner-bg swiper-slide"
									style={{
										backgroundImage: x ? `url(${x.url})` : '',
									}}
									onClick={() => { onStartNow(x); }}
								/>
							))}
						</div>
					</div>
					<div className="meal-plan-banner-inner">
						<div className="content">
							<h2 className="section-title">{t('mealPlan.title')}</h2>
							<h3 className="section-subtitle">{t('mealPlan.desc')}</h3>
							{/* <button type="button" onClick={onStartNow}>{t('mealPlan.startNow')} </button> */}
						</div>
					</div>
					<div
						className="meal-plan-banner-pagination"
					/>
					<div className="swiper-control">
						<button type="button" className="swiper-button-prev">{t('mealPlan.prevSlide')}</button>
						<button type="button" className="swiper-button-next">{t('mealPlan.nextSlide')}</button>
					</div>
				</div>
				<section className="meal-plan-features">
					<div className="content">
						<h2 className="section-title">{t('mealPlan.title2')}</h2>
					</div>
					<div className="features">
						<div className="feature">
							<img alt="Order-before" src="/img/assets/Order-before.png" />
							<p>{t('mealPlan.feature1')}</p>
						</div>
						<div className="feature">
							<img alt="Chef-made" src="/img/assets/Chef-made.png" />
							<p>{t('mealPlan.feature2')}</p>
						</div>
						<div className="feature">
							<img alt="Delivered-between" src="/img/assets/Delivered-between.png" />
							<p>{t('mealPlan.feature3')}</p>
						</div>
						<div className="feature">
							<img alt="Ready-to-eat" src="/img/assets/Ready-to-eat.png" />
							<p>{t('mealPlan.feature4')}</p>
						</div>
					</div>
					<div className="content">
						<p className="subtitle">
							{t('mealPlan.desc2')}
						</p>
					</div>
				</section>
				<HomeOurMeals />
				<HomeMealPlans />
				<HomeAmbassadors />
				<PartnersLogos
					page="frontpage"
				/>
				<section className="meal-plan-what-sets-us-apart">
					<div className="content">
						<h2 className="section-title">{t('mealPlan.title3')}</h2>
						<p>
							{t('mealPlan.desc3')}
						</p>
						{/* <span>
							<img alt="plant" src="/img/assets/plant.png" />
							{t('mealPlan.bioPackaging')}
						</span> */}
					</div>
				</section>
				<section className="meal-plan-order-cut-off">
					<div className="content">
						<CutOff />
					</div>
				</section>
				<section className="meal-plan-app">
					<div className="container">
						<div className="content">
							<h2 className="section-title">
								{t('mealPlan.title4')}
							</h2>
							<p>
								{t('mealPlan.desc4')}
							</p>
							<div className="appstore">
								<a target="_blank" rel="noopener noreferrer" href="http://onelink.to/h8gq45"><img alt="appstore" src="/img/assets/appstore.png" /></a>
								<a target="_blank" rel="noopener noreferrer" href="http://onelink.to/h8gq45"><img alt="googleplay" src="/img/assets/googleplay.png" /></a>
							</div>
						</div>
						<img alt="phone2" src="/img/assets/phone2.png" />
					</div>
				</section>
			</main>
			{/* <NewsletterWidget
				show={showNewsletterWidget}
				onClose={() => setShowNewsletterWidget(false)}
				isUserLoggedIn={!!token}
			/> */}
		</Layout>
	);
};

const mapStateToProps = (state: RootState) => ({
	banners: selectBanner(state),
	token: selectToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	fetchBanner: () => dispatch(SettingActions.fetchBanner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealPlans);
