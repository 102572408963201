import axios from './axios';


const redeemGiftCard = (data: object) => axios.post(
	'v1/gift_card/redeem', data,
);

export default {
	redeemGiftCard,
};
