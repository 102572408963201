import { all } from 'redux-saga/effects';
import { watchFetchBanner } from './duck/saga';
import SettingReducer from './duck/reducer';

function* SettingSaga() {
	yield all([
		watchFetchBanner(),
	]);
}

export {
	SettingSaga,
	SettingReducer,
};
