/* eslint-disable */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.scss';
import Layout from '../../layouts/Layout';

const PrivacyPolicy = () => {
	const { t } = useTranslation();

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout>
			<div className="privacy-policy-container">
				<h1>{t('privacyPolicy.title')}</h1>
				<div className="private-conditions-content">
					<div className="private-content-desc">
						<strong>TZCJ Company Limited (trading as NOSH)</strong>

						is committed to protecting and respecting your privacy.

						<br />
						This policy (together with any other documents referred to in it) sets out the basis on which
						any personal data we collect from you, or that you provide to us, will be processed by us.
						Please read the following carefully to understand our views and practices regarding your
						personal data and how we will treat it.
						<br />
					</div>
					<div className="private-content-title">1. Information collection</div>
					<div className="private-content-desc">
						<strong>1.1</strong>

						In order to provide a better service to you, we may collect and process the following data about
						you:
						<br />
						<div className="content-list">
							<strong>(a)</strong>
							information that you provide by filling in forms on our website www.nosh.hk (the “Site”).
							This includes information provided at the time of registering to use our Site, subscribing
							to our service, posting material or requesting further services. We may also ask you for
							information at other times, for example in connection with a promotion or when you report a
							problem with our Site;
							<br />
							<strong>(b)</strong>
							if you contact us, we may keep a record of that correspondence;
							<br />
							<strong>(c)</strong>
							we may also ask you to complete surveys that we use for research purposes, although you do
							not have to respond to them;
							<br />
							<strong>(d)</strong>
							details of transactions you carry out through our Site and of the fulfilment of your orders;
							<br />
							<strong>(e)</strong>
							details of your visits to our Site and the resources that you access.
							<br />
						</div>
						<strong>1.2</strong>

						We only retain personal data for so long as it is necessary. Data may be archived as long as the
						purpose for which the data was used still exists.
					</div>
					<div className="private-content-title">2. Uses made of the information</div>
					<div className="private-content-desc">
						<strong>2.1</strong>

						The purposes for which information may be used by us in and outside the Hong Kong
						<br />
						Special Administrative Region include:
						<div className="content-list">
							<strong>(a)</strong>

							ensuring that content from our Site is presented in the most effective manner for you and
							for your computer;
							<br />
							<strong>(b)</strong>

							providing you with alerts, newsletter, education materials or information that you requested
							or signed up to;
							<br />
							<strong>(c)</strong>

							carrying out our obligations arising from any contracts entered into between you and us;
							<br />
							<strong>(d)</strong>

							allowing you to participate in interactive features of our service, when you choose to do
							so;
							<br />
							<strong>(e)</strong>

							designing and conducting surveys/questionnaires for client profiling/segmentation,
							statistical analysis, improving and furthering the provision our products and services;
							<br />
							<strong>(f)</strong>

							complying with laws and regulations applicable to us or any of our affiliates in or outside
							the Hong Kong Special Administrative Region;
							<br />
							<strong>(g)</strong>

							legal proceedings, including collecting overdue amounts and seeking professional advices;
							<br />
							<strong>(h)</strong>

							researching, designing and launching services or products including seminars/events/forums;
							<br />
							<strong>(i)</strong>

							promoting and marketing services and products subject to your exercise of the opt-out right
							(please see further details in Clause 2.2 below); or
							<br />
							<strong> (j)</strong>

							purposes directly related or incidental to the above.
							<br />
						</div>
						<strong>2.2</strong>

						We intend to use your data in direct marketing and we require your consent (which includes an
						indication of no objection) for that purpose. In this connection, please note that:
						<div className="content-list">
							<strong>(a) </strong>

							your name, contact details (including address, contact number, email address), products and
							services information, transaction pattern and behaviour, background and demographic data
							held by us from time to time may be used by us in direct marketing;
							<br />
							<strong>(b) </strong>

							the following classes of services, products and subjects may be marketed in direct
							marketing:
							<br />
							<div className="content-list">
								<strong>(i) </strong>

								services and products related to our Site and/or our affiliates (including marketing
								affiliates programs we are a part of);
								<br />
								<strong>(ii)</strong>

								reward, loyalty or privileges programmes, promotional offers and related services; and
								<br />
								<strong>(iii)</strong>

								invitations to events such as seminars/webinars/tele-seminars, conferences, live
								programs or events.
								<br />
							</div>
							<strong>(c)</strong>

							We may conduct direct marketing via fax, email, direct mail, telephone and other means of
							communication or send e-newsletters to you. You may choose not to receive promotional
							materials, by simply telling us (see below for contact details), and we will cease to do so,
							without charge.
							<br />
						</div>
					</div>
					<div className="private-content-title">3. Disclosure of your information</div>
					<div className="private-content-desc">
						We will keep the personal data we hold confidential but may provide information to:
						<div className="content-list">
							<strong>(a)</strong>

							personnel, agents, advisers, auditors, contractors, financial institutions, and service
							providers in connection with our operations or services;
							<br />
							<strong>(b)</strong>

							our overseas offices, affiliates, business partners and counterparts (if any);
							<br />
							<strong>(c)</strong>

							persons under a duty of confidentiality to us;
							<br />
							<strong>(d)</strong>

							persons to whom we are required to make disclosure under applicable laws and regulations in
							or outside the Hong Kong Special Administrative Region; or
							<br />
							<strong>(e)</strong>

							actual or proposed transferees or participants of our services in or outside the Hong Kong
							Special Administrative Region.
							<br />
						</div>
					</div>
					<div className="private-content-title">4. Cookies</div>
					<div className="private-content-desc">
						<strong>4.1</strong>

						Our website uses cookies to distinguish you from other users of our website. This helps us to
						provide you with a good experience when you browse our website and also allows us to improve our
						Site. By continuing to browse the Site, you are agreeing to our use of cookies.
						<br />
						<strong>4.2</strong>

						A cookie is a small file of letters and numbers that we store on your browser or the hard drive
						of your computer if you agree. Cookies contain information that is transferred to your
						computer's hard drive.
						<br />
						<strong>4.3</strong>

						We use the following cookies:
						<div className="content-list">
							<strong>(a)</strong>

							Strictly necessary cookies. These are cookies that are required for the operation of our
							website. They include, for example, cookies that enable you to log into secure areas of our
							website, use a shopping cart or make use of e- billing services.
							<br />
							<strong>(b)</strong>

							Analytical/performance cookies. They allow us to recognise and count the number of visitors
							and to see how visitors move around our website when they are using it. This helps us to
							improve the way our website works, for example, by ensuring that users are finding what they
							are looking for easily.
							<br />
							<strong>(c)</strong>

							Functionality cookies. These are used to recognise you when you return to our website. This
							enables us to personalise our content for you, greet you by name and remember your
							preferences (for example, your choice of language or region).
							<br />
							<strong>(d)</strong>

							Targeting cookies. These cookies record your visit to our website, the pages you have
							visited and the links you have followed. We will use this information to make our website
							and the advertising displayed on it more relevant to your interests. We may also share this
							information with third parties for this purpose.
							<br />
						</div>
						<strong>4.4</strong>

						Please note that third parties (including, for example, advertising networks and providers of
						external services like web traffic analysis services) may also use cookies, over which we have
						no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
						<br />
						<strong>4.5</strong>

						We may use the following third party web analytic services on the website. The service providers
						that administer these services use technologies such as cookies, web server logs and web beacons
						to help us analyse how visitors use the website. The information collected through these means
						(including IP address) is disclosed to these service providers, who use the information to
						evaluate use of the website. These analytic services may use the data collected to contextualise
						and personalise the marketing materials of their own advertising network.
						Google Analytics
						<br />
						<strong>4.6</strong>

						Google Analytics is a web analysis service provided by Google Inc. ("Google"). Google's ability
						to use and share information collected by Google Analytics is in accordance with their policies:
						<a
							href="http://www.google.com/policies/privacy/partners/"
							className={"link-content"}
							target="_blank"
						>
							http://www.google.com/policies/privacy/partners/
						</a>
						<br />
						<strong> 4.7</strong>

						You can prevent Google's collection and processing of data by using the Google Ads Settings page
						or downloading and installing their browser plug-in (
						<a
							href="https://tools.google.com/dlpage/gaoptout"
							target="_blank"
						>
							https://tools.google.com/dlpage/gaoptout
						</a>
						).
						<br />
						<strong> 4.8</strong>

						You block cookies by activating the setting on your browser that allows you to refuse the
						setting of all or some cookies. However, if you use your browser settings to block all cookies
						(including essential cookies) you may not be able to access all or parts of our Site.
						<br />
					</div>
					<div className="private-content-title">5. Security</div>
					<div className="private-content-desc">
						<strong> 5.1</strong>

						All information you provide to us is stored on our secure servers. Any payment transactions will
						be encrypted using SSL technology. Where we have given you (or where you have chosen) a password
						which enables you to access certain parts of our Site, you are responsible for keeping this
						password confidential. We ask you not to share a password with anyone.
						<br />
						<strong>5.2</strong>

						Unfortunately, the transmission of information via the internet is not completely secure.
						Although we will do our best to protect your personal data, we cannot guarantee the security of
						your data transmitted to our Site; any transmission is at your own risk. Once we have received
						your information, we will use strict procedures and security features to try to prevent
						unauthorised access.
						<br />
					</div>
					<div className="private-content-title">6. Changes to our Privacy Policy</div>
					<div className="private-content-desc">
						Any changes we may make to our Privacy Policy in the future will be posted on this page.

						<br />
					</div>
					<div className="private-content-title">7. Data transfers</div>
					<div className="private-content-desc">
						We may hold your data on servers in Hong Kong and any other territories as we see fit from time
						to time. We may also transfer your data to our overseas offices or to any people listed at
						Clause 3 above, who may be located in or outside of the Hong Kong Special Administrative Region.
						<br />
					</div>
					<div className="private-content-title">8. Your consent and rights</div>
					<div className="private-content-desc">
						<strong>8.1</strong>

						By using our service, making an application or visiting our website, you consent to the
						collection and use of your information and other activities as outlined in this policy.
						<br />
						<strong>8.2</strong>

						Under the Hong Kong Personal Data (Privacy) Ordinance (the
						<strong>"Ordinance"</strong>
						), individuals have the right:
						<br />
						<div className="content-list">
							<strong>(a)</strong>

							to check whether we hold personal data about you and to access such data;
							<br />
							<strong>(b)</strong>

							to require us to correct as soon as reasonably practicable any data relating to you that is
							inaccurate;
							<br />
							<strong>(c)</strong>

							to ascertain our policies and practices in relation to personal data and the kind of
							personal data held by us; and
							<br />
							<strong>(d)</strong>

							to object to the use of your personal data for marketing purposes and we shall not use your
							personal data for marketing purposes after you communicate your objection to us.
							<br />
						</div>
						<strong>8.3</strong>

						You may exercise your opt-out right by notifying us if you wish to object to the use of your
						personal data for direct marketing purposes. Please send requests for such objections, access to
						data, correction of data, information regarding policies and practices and kinds of data held,
						questions or complaints to: <a target="_blank" rel="noopener noreferrer" href="mailto:hello@nosh.hk">hello@nosh.hk</a>
						<br />
						<strong>8.4</strong>

						In accordance with the private of the Ordinance, we have the right to and may charge a
						reasonable fee for processing any data access request.
						<br />
						<strong>8.5</strong>

						In the event that a User wishes to access or amend his or her personal information and data, the
						Company may request him or her to provide personal details in order to verify and confirm his or
						her identity. A HKID card number or passport number or business registration certificate number
						cannot be amended unless such data is proved to be inaccurate. The Company is required to
						respond to a User’s request within fourteen (14) days of his or her request and will endeavour
						to do so wherever possible.
						<br />
					</div>
					<div className="private-content-title">9. Governing law and jurisdiction</div>
					<div className="private-content-desc">
						Nothing in this Privacy Policy shall limit the rights of the data subject under the Ordinance.
						This Privacy Policy shall be governed by the laws of the Hong Kong Special Administrative
						Region. You agree to submit to the exclusive jurisdiction of the Hong Kong courts.
						<br />
					</div>
					<div className="private-content-title">10. Removal of NOSH Account</div>
					<div className="private-content-desc">
						<strong>10.1</strong>

						What happens if I delete my NOSH account?
						<div className="content-list">
							<strong>(a)</strong>
							When you delete your account, you won't be able to reactivate your account.
							<br />

							<strong>(b)</strong>
							Your profile, NOSH credits balance, recent order history etc., will be permanently removed.
							<br />
						</div>
						<br />

						<strong>10.2</strong>
						How to delete my NOSH account?
						<br />
						Please submit a request by email at <a
							href="mailto:hello@nosh.hk"
							className={"link-content"}
							target="_blank"
						>hello@nosh.hk</a>. Also, to verify that you are the owner of this account by changing the account section to requesting NOSH account deletion. We will send a confirmation email to you after reviewing your request. If you have any questions, feel free to drop us a line at <a
							href="mailto:hello@nosh.hk"
							className={"link-content"}
							target="_blank"
						>hello@nosh.hk</a>.
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PrivacyPolicy;
