import axios from './axios';

export const getOrders = () => axios.get('v1/orders');

export const placeOrder = (data: object) => axios.post(
	'/v1/orders/_place', data,
);

export const createPaymentIntent = (data: any) => axios.post(
	'/v1/orders/_create_intent', data,
);

export const createApplePayOrder = (data: any) => axios.post(
	'/v1/orders/_place_apple', data,
);
