import React, {
	useEffect, useState, createRef, useRef,
} from 'react';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import { useTranslation } from 'react-i18next';
import Layout from '../../layouts/Layout';
import { fetchCuisines, fetchCuisineById } from '../../api';
import './Step2.scss';
import { Steps, SliderSelector } from '../../components';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import { Goal } from '../../models';
import PlanDetailsPopup from './components/PlanDetailsPopup/PlanDetailsPopup';

interface Props {
	brandName: string;
}

const useQuery = () => new URLSearchParams(useLocation().search);

const Step2 = ({ brandName }: Props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [goals, setGoals] = useState<Array<Goal> | null>(null);
	const [goal, setGoal] = useState<Goal | null>(null);
	const [days, setDays] = useState<number>(10);
	const [selectedPackage, setSelectedPackge] = useState<number | string>(10);
	const [enableNext, setEnableNext] = useState<boolean>(false);
	const choosePackage = createRef<any>();
	const query = useQuery();

	// Popup Plan Detail
	const [showPlanDetailsPopup, setShowPlanDetailsPopup] = useState<boolean>(false);
	const [popupGoal, setPopupGoal] = useState<Goal | null>(null);

	const closePlanDetailsPopupConfirm = () => {
		setPopupGoal(null);
	};
	const openPlanDetailsPopup = () => setShowPlanDetailsPopup(true);

	const onClickOpenGoalPopup = (x: Goal) => {
		setPopupGoal(x);
	};

	useEffect(() => {
		if (popupGoal) {
			openPlanDetailsPopup();
		} else {
			setShowPlanDetailsPopup(false);
		}
	}, [popupGoal]);

	// Back to top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		// BrandID translate: Brand Name to Brand Id
		fetchCuisines()
			.then(((res) => {
				const cuisines = res;
				const targetCuisine = cuisines.filter((el: any) => ((el.name).replace(/[^a-zA-Z0-9-_]/g, '') === brandName));
				// console.log('fetchCuisines', brandName, cuisines);
				if (targetCuisine.length < 1) {
					window.alert('Brand not found');
					history.push('/MealPlans/1');
				} else {
					const targetCuisineId = targetCuisine[0]._id;
					fetchCuisineById(targetCuisineId)
						.then(((res) => {
							const filteredGoal = res.data.result.filter( (g:any) => g._id != "5eb2a44780f80f42d7806fd0");
							setGoals(filteredGoal);
							const swiper = new Swiper('.goal-container .swiper-container', {
								pagination: {
									el: '.goal-container .swiper-pagination',
									type: 'bullets',
									clickable: true,
								},
								navigation: {
									nextEl: '.goal-container .swiper-button-next',
									prevEl: '.goal-container .swiper-button-prev',
								},
								slidesPerView: 1,
								spaceBetween: 30,
								watchOverflow: true,
								centerInsufficientSlides: true,
								breakpoints: {
									768: {
										slidesPerView: 2,
										spaceBetween: 30,
									},
									1200: {
										slidesPerView: 3,
										spaceBetween: 30,
									},
								},
							});
						}));
				}
			}));
	}, [brandName]);

	// useEffect(() => {
	// 	if (goal) {
	// 		window.scrollTo({
	// 			top: choosePackage.current.offsetTop - 30,
	// 			left: 0,
	// 			behavior: 'smooth',
	// 		});
	// 	}
	// }, [goal]);

	const handleClick = (x: Goal) => {
		// console.log(x);
		// setGoal(x);
		history.push(`/MealPlans/package/${brandName}/${x.name.replace(/[^a-zA-Z0-9-_]/g, '')}`);
	};

	const handlePackage = (chosenDays: any, customDays: number) => {
		setSelectedPackge(chosenDays);
		if (chosenDays === 'select') {
			setDays(customDays);
		} else {
			setDays(chosenDays);
		}
	};

	useEffect(() => {
		setEnableNext(false);
		if (days) {
			setEnableNext(true);
			// executeScroll();
		}
	}, [days]);


	useEffect(() => {
		if (!goals) return;
		const selectedGoal = query.get('goal');
		if (selectedGoal) {
			const tarGoal = goals.filter((goal: any) => goal._id === selectedGoal);
			setGoal(tarGoal[0]);
			const about = query.get('about');
			if (about) {
				onClickOpenGoalPopup(tarGoal[0]);
			}
		}
	}, [goals]);

	return (
		<Layout>
			<PlanDetailsPopup
				visible={showPlanDetailsPopup}
				onClosePopup={closePlanDetailsPopupConfirm}
				goal={popupGoal}
			/>
			<main className={`steps-container step2-goal lang-${t('langKey')}`}>
				<Steps step={2} links={['/MealPlans/1', '', '']} />
				{/* <h1>{t('mealPlan.step2Title')}</h1> */}
				<h1 className="step2-goal-title">{t('mealPlan.step2Desc')}</h1>
				{!goals ? <LoadingScreen /> : '' }
				<div className="goal-container hide-mobile">
					<div className="swiper-pagination" />
					<div className="swiper-body">
						<div className="swiper-container">
							<div className="swiper-wrapper">
								{goals && goals.map((x: Goal) => (
									<div className="goal-item swiper-slide">
										<button key={x._id} type="button" onClick={() => handleClick(x)} className="goal">
											<div className="goal-thumb">
												<div className="goal-thumb-src" style={{ backgroundImage: `url(${x.imageURL})` }} />
											</div>
											{/* <img className="meal-img" src={x.imageURL} alt="western" /> */}
										</button>
										<div className="plan-info-helper">
											{t('mealPlan.moreAboutThe')}
											<button type="button" onClick={() => onClickOpenGoalPopup(x)} className="plan-info-helper-link">{x && x.name}</button>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="swiper-control">
						<button type="button" className="swiper-button-prev">{t('mealPlan.prevSlide')}</button>
						<button type="button" className="swiper-button-next">{t('mealPlan.nextSlide')}</button>
					</div>
				</div>
				<div className="goal-container show-mobile">
					<div className="goal-static-container">
						{goals && goals.map((x: Goal) => (
							<div className="goal-item swiper-slide">
								<button key={x._id} type="button" onClick={() => handleClick(x)} className="goal">
									<div className="goal-thumb">
										<div className="goal-thumb-src" style={{ backgroundImage: `url(${x.imageURL})` }} />
									</div>
								</button>
								<div className="plan-info-helper">
									{t('mealPlan.moreAboutThe')}
									<button type="button" onClick={() => onClickOpenGoalPopup(x)} className="plan-info-helper-link">{x && x.name}</button>
								</div>
							</div>
						))}
					</div>
				</div>
			</main>
		</Layout>
	);
};


const mapStateToProps = (state: RootState, props: any) => {
	const { match: { params: { brand } } } = props;
	return ({
		brandName: brand,
	});
};

export default connect(mapStateToProps)(Step2);
