import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import MealPlans from '../pages/MealPlans/MealPlans';
import Menu from '../pages/MealPlans/Menu';
import Step1 from '../pages/MealPlans/Step1';
import Step2 from '../pages/MealPlans/Step2';
import Step3 from '../pages/MealPlans/Step3';
import Preview from '../pages/MealPlans/Preview';
import Checkout from '../pages/MealPlans/Checkout';
import CheckoutSuccess from '../pages/MealPlans/CheckoutSuccess';

import CorporateMeals from '../pages/CorporateMeals/CorporateMeals';
import Blog from '../pages/Blog/Blog';
import OurSuppliers from '../pages/OurSuppliers/OurSuppliers';
import MyAccount from '../pages/Account/MyAccount';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import Faq from '../pages/Faq/Faq';
import GiftCards from '../pages/GiftCards/GiftCards';
import GiftCardsRedeem from '../pages/GiftCards/components/GiftCardRedeem';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import ProtectedRoute from './ProtectedRoute';
import NoLogRoute from './NoLogRoute';
import GAPageTracker from '../components/GAPageTracker/GAPageTracker';
import GiftCardsSelector from '../pages/GiftCards/components/GiftCardsSelector';
import PromoSignup from '../pages/Promotions/Promo_250/PromoSignup';
import ThankYou from '../pages/Promotions/Promo_250/ThankYou';
import Vitasoy from '../pages/Promotions/Vitasoy/Vitasoy';
import NewBrand from '../pages/Promotions/newBrand/newbrand';
import WeightMgmt from '../pages/Promotions/weightManagement/weightMgmt';
import Promo from '../pages/Promotions/promo/promo';

export default () => (
	<Router>
		<Switch>
			<Route exact path="/:locale(zh_HK|en)?" component={MealPlans} />
			<NoLogRoute exact redirectPath="/:locale(zh_HK|en)?" path="/:locale(zh_HK|en)?/promo" component={PromoSignup} />
			<Route exact path="/:locale(zh_HK|en)?/promo/thankyou" component={ThankYou} />
			{/* <Route exact path="/:locale(zh_HK|en)?/promo" component={Promo} /> */}
			<Route exact path="/:locale(zh_HK|en)?/landing-NoshxVitaSoy" component={Vitasoy} />
			<Route exact path="/:locale(zh_HK|en)?/landing-lean-and-green" component={NewBrand} />
			<Route exact path="/:locale(zh_HK|en)?/weight-management" component={WeightMgmt} />
			<Route exact path="/:locale(zh_HK|en)?/wm" component={WeightMgmt} />
			<Route exact path="/:locale(zh_HK|en)?/MealPlans/1" component={Step1} />
			<Route exact path="/:locale(zh_HK|en)?/MealPlans/:brand" component={Step2} />
			<Route exact path="/:locale(zh_HK|en)?/MealPlans/package/:brand/:goal" component={Step3} />
			<Route exact path="/:locale(zh_HK|en)?/MealPlans/details/:brand/:goal/:days" component={Preview} />
			<Route exact path="/:locale(zh_HK|en)?/our-menu/" component={Menu} />
			<ProtectedRoute exact redirectPath="/MealPlans/1" path="/:locale(zh_HK|en)?/Checkout" component={Checkout} />
			<Route exact path="/:locale(zh_HK|en)?/Checkout/Success" component={CheckoutSuccess} />
			<Route exact path="/:locale(zh_HK|en)?/corporate-meals/">
				<Redirect to="/catering/" />
			</Route>
			<Route exact path="/:locale(zh_HK|en)?/catering/" component={CorporateMeals} />
			<Route exact path="/:locale(zh_HK|en)?/Blog" component={Blog} />
			<Route exact path="/:locale(zh_HK|en)?/suppliers/" component={OurSuppliers} />
			<Route exact path="/:locale(zh_HK|en)?/gift-cards/" component={GiftCards} />
			<Route exact path="/:locale(zh_HK|en)?/gift-cards/gift-cards-selector/" component={GiftCardsSelector} />
			<Route exact path="/:locale(zh_HK|en)?/Redeem" component={GiftCardsRedeem} />
			<ProtectedRoute exact path="/:locale(zh_HK|en)?/AccountMembers" component={MyAccount} />
			<Route exact path="/:locale(zh_HK|en)?/FAQ/" component={Faq} />
			<Route exact path="/:locale(zh_HK|en)?/TermsAndConditions" component={TermsAndConditions} />
			<Route exact path="/:locale(zh_HK|en)?/privacypolicy" component={PrivacyPolicy} />
			<Route exact path="/:locale(zh_HK|en)?/PrivatePolicy">
				<Redirect to="/privacypolicy" />
			</Route>
			<Route exact path="/:locale(zh_HK|en)/" component={MealPlans} />
			<Route path="/:locale(zh_HK|en)?/">
				<Redirect to="/our-menu/" />
			</Route>

		</Switch>
		<GAPageTracker />
	</Router>
);
