import { all } from 'redux-saga/effects';
import CartReducer from './duck/reducer';
import { watchFetchCart } from './duck/saga';

function* CartSaga() {
	yield all([
	    watchFetchCart(),
	]);
}

export {
	CartSaga,
	CartReducer,
};
