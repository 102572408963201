import { action } from 'typesafe-actions';
import { Banner } from '../../../models/Banner';

export const FETCH_BANNER = 'settings/FETCH_BANNER';
export const FETCH_BANNER_SUCCESS = 'settings/FETCH_BANNER_SUCCESS';
export const FETCH_BANNER_FAIL = 'settings/FETCH_BANNER_FAIL';

export const fetchBanner = () => action(FETCH_BANNER);
export const fetchBannerSuccess = (
	banners: Array<Banner>,
) => action(FETCH_BANNER_SUCCESS, { banners });
export const fetchBannerFail = (
	error: string,
) => action(FETCH_BANNER_FAIL, { error });
