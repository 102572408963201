import axios from './axios';

const getGiftCard = () => axios.get('v1/gift_card/');

const purchaseGiftCard = (data: object) => axios.post(
	'v1/gift_card/', data,
);

export default {
	getGiftCard,
	purchaseGiftCard,
};
