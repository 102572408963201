import { ActionType } from 'typesafe-actions';
import * as AuthActions from './actions';
import { AuthUser } from '../../../models/AuthUser';

export interface State {
	token: string | null;
	authUser: AuthUser | null;
	error?: string;
}

const initialState: State = {
	token: null,
	authUser: null,
};

type AuthActions = ActionType<typeof AuthActions>;

export default function reducer(state: State = initialState, action: AuthActions): State {
	switch (action.type) {
		case AuthActions.LOGIN:
		case AuthActions.FB_LOGIN:
			return {
				...state,
				error: undefined,
			};
		case AuthActions.LOGIN_SUCCESS:
		case AuthActions.FB_LOGIN_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case AuthActions.LOGIN_FAIL:
		case AuthActions.FB_LOGIN_FAIL:
			return {
				...state,
				error: action.payload.error,
			};
		case AuthActions.LOGOUT:
			return initialState;
		default:
			return state;
	}
}
