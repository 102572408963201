/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
	ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { Link, useHistory, useParams } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import './Header.scss';
import 'react-phone-input-2/lib/style.css';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import moment from 'moment';
import { setUserForGoogleTracking } from '../../helpers/gaHelpers/gaHelpers';
import PopupModal from './PopupModal';
import { NOSH_AUTH_DATA_KEY } from '../../config/Keys';
import { setToken } from '../../api/axios';
import * as AccessService from '../../api/AccessService';
import { AuthUser } from '../../models/AuthUser';
import { UserCart } from '../../models/CartItem';
import CartDropdownItem from './components/CartDropdownItem';
import Submenu from './components/Submenu';
import CutOff from '../CutOff/CutOff';
import i18n from '../../i18n/i18n';
import UserService from '../../api/UserService';
import { NoshUser } from '../../models/NoshUser';
import { selectNoshUser } from '../../store/user/duck/selectors';
import { fetchCutOffTime, submitAddOns } from '../../api';

import AppConfig from '../../config/AppConfig';	// For Checking env

interface HeaderProps {
	noshUser: NoshUser | null;
}

interface Props extends HeaderProps {
	authError?: string;
	authUser: AuthUser | null;
	userCart: UserCart | null;

	login: (username: string, password: string) => void;
	loginSuccess: (token: string, user: AuthUser) => void;
	onClearCart: () => void;
	onClearCartOne: (index: number) => void;
}

const calculateTotalPrice = (cart: UserCart) => {
	let totalPrice = 0;
	try {
		cart.items.forEach((item) => {
			totalPrice += Math.ceil(item._price * item.quantity);
		});
	} catch (err) {
		console.log(err);
	}

	return totalPrice;
};

const Header = ({
	authError, login, loginSuccess, authUser, userCart,
	onClearCart, onClearCartOne, noshUser,
}: Props) => {
	const { t } = useTranslation();
	const params = useParams<any>();
	const [loginForm, setLoginForm] = useState<{ username: string; password: string; }>({ username: '', password: '' });
	const [showBurgerMenu, setShowBurgerMenu] = useState(false);
	const [showCart, setShowCart] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [cutOffTime, setCutOffTime] = useState<string>('');
	const [hasExpiredItem, setHasExpiredItem] = useState<boolean>(false);


	const onModalHide = () => setShowModal(false);

	const [cookies, setCookie, removeCookie] = useCookies();
	const history = useHistory();

	const wrapperRef = useRef<any>(null);

	const onCartClick = () => {
		if (authUser) {
			setShowCart(!showCart);
		} else {
			setShowModal(true);
		}
	};

	const removeAddOns = () => {
		submitAddOns({})
			.then(((res: any) => {
				// reloadCart();
				// setCheckoutState('');
			}))
			.catch((err: any) => {
				let errMSg = '';
				if (err.response) {
					if (err.response.data) {
						if (err.response.data.error) {
							errMSg = err.response.data.error.message;
						}
					}
				}
				window.alert(`There is an error removing add ons: ${errMSg}`);
			});
	};

	// dismiss cart popup when clicking outside of popup area
	useEffect(() => {
		function handleClickOutside(event: any) {
			const ref = wrapperRef.current;
			if (ref && !ref.contains(event.target)) {
				setTimeout(() => {
					if (showCart) setShowCart(false);
				}, 150);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchstart', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchstart', handleClickOutside);
		};
	}, [wrapperRef, showCart]);

	useEffect(() => {
		if (cookies[NOSH_AUTH_DATA_KEY]) {
			const { token, user: _user } = cookies[NOSH_AUTH_DATA_KEY];
			setToken(token);
			loginSuccess(token, _user);
			if (_user.email) {
				setUserForGoogleTracking(_user.email);
			}
		}
	}, [loginSuccess]);

	useEffect(() => {
		if (authError) window.alert(authError);
	}, [authError]);

	useEffect(() => {
		if (authUser) {
			setShowModal(false);
		}
	}, [authUser]);

	useEffect(() => {
		fetchCutOffTime().then(((res) => {
			// console.log(res);
			// console.log('cutoff');
			// console.log(res.data.cutoff);
			if (res.data.cutoff) {
				setCutOffTime(res.data.cutoff);
			}
		}));
		// setHasExpiredItem(true);
	}, []);

	useEffect(() => {
		setHasExpiredItem(false);
		// console.log('userCart', userCart);
		if (userCart && cutOffTime) {
			const tmr = moment().add(1, 'days').toISOString();
			const expiredItems = userCart.items.filter(
				(item) => moment(tmr).isAfter(item.startDate),
			);
			if (expiredItems.length > 0) {
				setHasExpiredItem(true);
			}
			const itemsCount = userCart.items.length;
			if (itemsCount < 1 || expiredItems.length > 0 && expiredItems.length >= itemsCount) {
				// console.log('all empty cart', userCart);
				removeAddOns();
			}
			// if (cutOffTime) {
			// 	// console.log('NOW:',);
			// 	// console.log(cutOffTime);
			// 	// console.log(expiredItems[0].startDate);
			// }
		}
	}, [userCart, cutOffTime]);

	const handleClick = () => {
		setShowBurgerMenu(!showBurgerMenu);
	};

	const showLoginPopup = () => {
		// setModal(!openModal);
		setShowBurgerMenu(false);
		setShowModal(true);
	};

	const onSigninFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
		const { target: { id, value } } = e;
		setLoginForm({
			...loginForm,
			[id.toLowerCase().replace('-input', '')]: value,
		});
	};

	const onLogin = () => {
		const { username, password } = loginForm;
		if (username.length === 0 || password.length === 0) {
			window.alert(t('error.errValidUserAndPass'));
			return;
		}
		login(username, password);
	};

	const onLogout = () => {
		removeCookie(NOSH_AUTH_DATA_KEY, { path: '/' });
		setToken('');
		window.location.reload();
	};

	const langPath: any = {
		en: 'en',
		zht: 'zh_HK',
	};

	const toLang = (lang: string) => {
		const pathName = `${window.location.pathname}`;
		const cleanPathName = pathName.replace('/en', '').replace('/zh_HK', '');
		setTimeout(() => {
			window.location.href = `/${lang}${cleanPathName}`;
		}, 250);
	};

	const onSwitchLangZht = () => {
		setCookie('lang', 'zht');
		toLang(langPath.zht);
		// i18n.switchLanguage('zht');
	};

	const onSwitchLangEn = () => {
		setCookie('lang', 'en');
		toLang(langPath.en);
		// i18n.switchLanguage('en');
	};

	const onAccountClick = () => history.push('/AccountMembers');

	const onSignUp = (data: any) => {
		const {
			username, name, gender, email, password, phone, subscribeNewsletter,
			// newsletter,
		} = data;

		AccessService.signup(
			username,
			name,
			gender,
			email,
			password,
			Number(phone),
		).then((response: AxiosResponse) => {
			if (response.status === 200) {
				if (subscribeNewsletter) {
					const { data: { token } } = response;
					setToken(token);
					// Timeout so BE can properly save the new user
					setTimeout(() => {
						UserService.subscribeToNewsletter(name);
					}, 500);
				}
				login(email, password);
				window.gtag('event', 'gtm.formSubmit', {
					event_category: 'sign-up',
					event_label: 'sign-up-button',
				});
			} else {
				window.alert(t('error.errSignUp'));
			}
		}).then(() => console.log('test')).catch((err) => {
			const { response: { data: { error: { message } } } } = err;
			if (message[i18n.getLang()]) {
				window.alert(message[i18n.getLang()]);
			} else {
				window.alert(message);
			}
		});
	};

	const onRemove = (index: number) => {
		onClearCartOne(index);
	};

	const onCheckout = () => {
		history.push('/Checkout');
	};

	return (
		<header>
			<Menu isOpen={showBurgerMenu}>
				{/* <Link to="/">{t('layout.mealPlans')}</Link> */}
				{/* Change router tag from Link to a tag to refresh the page so that the title will change according to page changes. */}
				<a href={`${t('langPath')}/our-menu`}>{t('layout.ourMenu')}</a>
				<a href={`${t('langPath')}/catering`}>{t('layout.cateringJunks')}</a>
				<Submenu title={t('layout.faq')}>
					<a href={`${t('langPath')}/suppliers`}>{t('layout.ourSuppliers')}</a>
					<a href={`${t('langPath')}/FAQ`}>{t('layout.deliveryFaq')}</a>
					{/* <Link to="/Blog">{t('layout.blog')}</Link> */}
				</Submenu>
				<a href="/Blog">{t('layout.blog')}</a>
				{/* <a
					target="_blank"
					rel="noopener noreferrer"
					href="https://corporate.nosh.hk/"
				>
					{t('layout.corporateLunch')}
				</a> */}
				<a className="gift-card-link" href={`${t('langPath')}/gift-cards`}>{t('layout.giftCards')}</a>
				<a href="https://basics.nosh.hk/" target="_blank">NOSH Basics</a>
				{
					!noshUser
						? <p className="login" onClick={showLoginPopup} onKeyDown={showLoginPopup}>{t('layout.login')}</p>
						: (
							<div className={`logged-in-user ${noshUser?.isVIP ? 'mod-vip' : ''}`}>
								<div className="logged-in-user-name">
									<div className="logged-in-user-vip-logo" />
									{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
									<p
										className="login"
										onClick={onAccountClick}
										dangerouslySetInnerHTML={{ __html: `${noshUser?.isVIP ? (`<span class="login-welcome-msg">${t('welcome')}</span>`) : t('hi')},<br /><b>${noshUser?.memberName}</b>` }}
									/>
								</div>
								<button type="button" onClick={onLogout}><p>{t('layout.signOut')}</p></button>
							</div>
						)
				}
				<a className="mobile-order-now" href={`${t('langPath')}/MealPlans/1`}>{t('mealPlan.orderNow')}</a>
				<div
					className="mobile-lang"
				>
					<div
						className="lang-button-container"
					>
						<button
							type="button"
							onClick={onSwitchLangZht}
						>
							{
								params?.locale === 'zh_HK'
									? (
										<b>
											繁
										</b>
									)
									: (
										<p>
											繁
										</p>
									)
							}

						</button>
						<p className="mob-lang-seperator">/</p>
						<button
							type="button"
							onClick={onSwitchLangEn}
						>
							{
								params?.locale === 'en'
									? (
										<b>
											ENG
										</b>
									)
									: (
										<p>
											ENG
										</p>
									)
							}
						</button>
					</div>
				</div>
			</Menu>
			<div className="navigation">
				<a className="logo" href={`${t('langPath')}/`}><img alt="logo" src="/img/assets/logo.png" /></a>
				<nav>
					{/* <Link to="/">{t('layout.mealPlans')}</Link> */}
					<a href={`${t('langPath')}/our-menu/`}>{t('layout.ourMenu')}</a>
					<a href={`${t('langPath')}/catering/`}>{t('layout.cateringJunks')}</a>
					<Submenu title={t('layout.faq')}>
						<a href={`${t('langPath')}/suppliers`}>{t('layout.ourSuppliers')}</a>
						<a href={`${t('langPath')}/FAQ/`}>{t('layout.deliveryFaq')}</a>
					</Submenu>
					<a href={`${t('langPath')}/Blog`}>{t('layout.blog')}</a>
					{/* <a
						target="_blank"
						rel="noopener noreferrer"
						href="https://corporate.nosh.hk/"
					>
						{t('layout.corporateLunch')}
					</a> */}
					<a className="gift-card-link" href={`${t('langPath')}/gift-cards`}>{t('layout.giftCards')}</a>
					{/* <a href="https://noshdirect.com/" target="_blank" rel="noreferrer">NOSH Direct</a> */}
					<a href="https://basics.nosh.hk/" rel="noreferrer">NOSH Basics</a>
				</nav>
			</div>
			<div className="options">
				<div className="btn-order-now-holder">
					<a className="btn-order-now" href={`${t('langPath')}/MealPlans/1`}>{t('mealPlan.orderNow')}</a>
				</div>
				{
					!noshUser
						? <p className="login" onClick={showLoginPopup} onKeyDown={showLoginPopup}>{t('layout.login')}</p>
						: (
							<div className={`logged-in-user ${noshUser?.isVIP ? 'mod-vip' : ''}`}>
								<div className="logged-in-user-name">
									<div className="logged-in-user-vip-logo" />
									{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
									<p
										className="login"
										onClick={onAccountClick}
										dangerouslySetInnerHTML={{ __html: `${noshUser?.isVIP ? (`<span class="login-welcome-msg">${t('welcome')}</span>`) : t('hi')}, <b>${noshUser?.memberName}</b>` }}
									/>
								</div>
								<button type="button" onClick={onLogout}><p>{t('layout.signOut')}</p></button>
							</div>
						)
				}
				<div
					className="lang-button-container"
				>
					<button
						type="button"
						onClick={onSwitchLangZht}
					>
						{
							params?.locale === 'zh_HK'
								? (
									<b>
										繁
									</b>
								)
								: (
									<p>
										繁
									</p>
								)
						}

					</button>
					<p>/</p>
					<button
						type="button"
						onClick={onSwitchLangEn}
					>
						{
							params?.locale === 'en'
								? (
									<b>
										ENG
									</b>
								)
								: (
									<p>
										ENG
									</p>
								)
						}
					</button>
				</div>
				{authUser ? (
					<div
						className={`cart-btn ${showCart ? 'show-cart' : ''}`}
					>

						{/* eslint-disable-next-line max-len */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
						<div
							onClick={onCartClick}
							className="cart-trigger"
						>
							<FontAwesomeIcon icon={faShoppingCart} />
							<p>
								{userCart && userCart?.items?.length > 0
									? `${userCart?.items?.length} 
								${userCart?.items?.length > 1
										? t('layout.items')
										: t('layout.item')}`
									: ''}
								<br />
								{userCart && userCart.items.length > 0
									? `$${calculateTotalPrice(userCart)}`
									: null}
							</p>

						</div>
					</div>
				)
					: ''}

			</div>
			<PopupModal
				visible={showModal}
				onModalClose={onModalHide}
				onLogin={onLogin}
				onSignUp={onSignUp}
				onSignInFormChanged={onSigninFormChanged}
			/>
			<div className="navigation-mobile">
				<input onClick={handleClick} className="burger-menu" type="button" />
				<a className="logo" href={`${t('langPath')}/`}><img alt="logo" src="/img/assets/logo.png" /></a>
				<div className="navigation-mobile-right">
					<div
						className="itemsInCart"
					>
						{
							userCart && userCart.items.length > 0
								? <p>{userCart.items.length}</p>
								: null
						}
						<input onClick={onCartClick} className="cart" type="button" />
					</div>
				</div>
			</div>
			{authUser ? (
				<div
					className={`cart-btn ${showCart ? 'show-cart' : ''}`}
				>
					{userCart && showCart
						? (
							<div
								ref={wrapperRef}
								className="cart-dropdown"
							>
								<p>{t('layout.shoppingBag')}</p>
								{
									userCart && userCart.items.length > 0
										? (
											<div className="dropdown-contents">
												{
													userCart.items.map((value, index) => {
														// console.log('cart value', value);
														const tmr = moment().add(1, 'days').toISOString();
														// console.log('startDate', value.startDate);
														// console.log('cutOffTime', cutOffTime, moment(value.startDate).isAfter(cutOffTime));
														const isExpired = moment(tmr).isAfter(value.startDate);
														return (
															<CartDropdownItem
																key={value._id}
																onRemove={() => onRemove(index + 1)}
																cartItem={value}
																isExpired={isExpired}
															/>
														);
													})
												}
											</div>
										)
										: <p className="description">No items in cart</p>
								}

								{/* <button */}
								{/*	className="cart-link cart-clear" */}
								{/*	type="button" */}
								{/*	onClick={() => onClearCart()} */}
								{/* > */}
								{/*	{t('layout.clearCart')} */}
								{/* </button> */}

								<div>
									<p>{`${t('checkout.total')}: $${calculateTotalPrice(userCart)}`}</p>
									<button
										type="button"
										className={`checkout-button ${hasExpiredItem ? 'is-disabled' : ''}`}
										onClick={onCheckout}
									>
										{t('checkout.continueToCheckout')}
									</button>
								</div>

							</div>
						)
						: ''}
				</div>
			)
				: ''}
			<div className="mobile-cutoff">
				<CutOff />
			</div>
		</header>
	);
};

const mapStateToProps = (state: RootState) => ({
	noshUser: selectNoshUser(state),
});

export default connect(mapStateToProps)(Header);
